

<template>
    <div>
        <!-- -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/main">
                        <i class="fas fa-home"></i>Inicio
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/usuario_cr">Tabla usuario</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">usuario</li>
            </ol>
        </nav>
        <!-- -->
        <fielset>
            <legend style="text-align:center;"><strong>Formulario de registro</strong></legend>
            <div class="form-group" id="picture" align="center">

            </div>
        </fielset>
        <br>
        <div class="row col-12">
            <div class="col-md-4" style="float: left;">
                <div card="" class="card portlet light profile-sidebar-portlet bordered">
                    <div class="card-body">
                        <div class="text-center">
                            <img id="img_2" src="http://localhost:8080/contab7/public/images/avatar.png"
                                class="img-responsive" alt="" style="width:100%">
                            <div class="text-center">
                                <h4 class="profile-usertitle-name"> - </h4>
                                <div class="profile-usertitle-job">.</div>
                            </div>
                            <div class="text-center">
                                <input id="tbar_subirfoto" type="button" value="subir foto" class="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8" style="float: left;">
                <div class="card mb-3">
                    <div class="card-header bg-info">
                        Datos de usuario
                    </div>
                    <div class="card-body">
                        <!---------------------------------------------------->
                        <form id="form00" name="form00" method="POST">
                            <input type="hidden" name="_token" value="Ox1cyft8FaDNQ9p7deycnNH9h9RHjcaTVGaQ2B80">
                            <!-- -->
                            <input id="idd" name="idd" type="hidden" value="0">
                            <input id="ope" name="ope" type="hidden" value="n">
                            <!-- ---------------------------------------------- -->

                            <div id="divres"></div>

                            <!--------------------------->
                            <div class="form-group row">
                                <label for="id_u" class="col-sm-3 col-form-label">Cod.</label>
                                <div class="col-sm-9">
                                    <input name="id_u" id="id_u" type="text" class="form-control" value="" readonly="">
                                </div>
                            </div>
                            <!-----------2---------------->
                            <!-------------7-------------->
                            <div class="form-group row">
                                <label for="nro_doc" class="col-sm-3 col-form-label">C.I.<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input name="nro_doc" id="nro_doc" type="text" value="" class="form-control"
                                        data-valtipo="NB" data-valreq="true">
                                </div>
                            </div>
                            <!-------------8-------------->
                            <!--------------------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label"> Nombre:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input name="nomape" id="nomape" type="text" class="form-control" value=""
                                        data-valtipo="NB" data-valreq="true">
                                </div>
                            </div>

                            <!------------6--------------->
                            <!--<div class="form-group row">
                                    <label for="fecha_naci" class="col-sm-3 col-form-label">Fecha de nacimiento:</label>
                                    <div class="col-sm-9">
                                        <input name="fecha_naci" id="fecha_naci"  class="form-control"
                                        type="text" value="" placeholder="dd/mm/yy">
                                    </div>
                                </div>-->

                            <input name="foto_path1" id="foto_path1" type="hidden" value="" readonly="">
                            <!-------------9--------------->
                            <!--<div class="form-group row">
                                    <label for="email" class="col-sm-3 col-form-label">Email:</label>
                                    <div class="col-sm-9">
                                        <input name="email" id="email" type="text" value="" class="form-control"
                                        >
                                    </div>
                                </div>-->
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="tel" class="col-sm-3 col-form-label">Telf.:</label>
                                <div class="col-sm-9">
                                    <input name="tel" id="tel" type="text" value="" class="form-control"
                                        data-valtipo="NB" data-valreq="true">
                                </div>
                            </div>

                            <div class="top_title2">
                                <h4 class=""><span>Caja</span></h4>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="username" class="col-sm-3 col-form-label">Rol:<span
                                        class="text-danger"></span></label>
                                <div class="col-sm-9">
                                    <select name="id_rol" id="id_rol" class="form-control">
                                        <option value="3">Administrador</option>
                                        <option value="2">Cajero Auxiliar</option>
                                        <option value="6">Cajero Extra 1</option>
                                        <option value="1">Modulo consulta</option>
                                        <option value="4">Vendedor</option>
                                    </select> </div>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="pass" class="col-sm-3 col-form-label">Caja:<span
                                        class="text-danger"></span></label>
                                <div class="col-sm-9">
                                    <!---->
                                    <div class="form-label-group input-group">
                                        <select name="id_ca" id="id_ca" class="form-control">
                                            <option value="12">caja 1</option>
                                            <option value="4">Caja 1 - amlacen2</option>
                                            <option value="9">caja 2</option>
                                            <option value="11">caja 3</option>
                                            <option value="2">Caja Principal</option>
                                            <option value="7">caja principal ddd</option>
                                            <option value="3">Caja Secundaria</option>
                                            <option value="5">primara caja - almacen1</option>
                                            <option value="6">sdfsdf</option>
                                            <option value="1">SIN CAJA</option>
                                        </select>
                                    </div>
                                    <!-- Error Alert -->

                                    <!---->
                                </div>
                            </div>
                            <!--------------------------->

                            <div class="top_title2">
                                <h4 class=""><span>Acceso</span></h4>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="username" class="col-sm-3 col-form-label">Username:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input name="username" id="username" type="text" value="" class="form-control"
                                        data-valtipo="NB" data-valreq="true">
                                </div>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="pass" class="col-sm-3 col-form-label">Password:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <!---->
                                    <div class="form-label-group input-group">
                                        <input name="pass" id="pass" type="password" value="" class="form-control"
                                            data-valtipo="LN" data-valreq="true">
                                        <div class="input-group-append">
                                            <span class="input-group-text">
                                                <i id="eye" class="fa fa-eye-slash" onclick="showHidePwd();"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <!---->
                                </div>
                            </div>
                            <!--------------------------->
                            <div class="form-group row">
                                <label for="estado_sn" class="col-sm-3 col-form-label">Habilitado:</label>
                                <div class="col-sm-9">
                                    <input type="checkbox" class="" id="estado_sn" name="estado_sn">
                                </div>

                            </div>

                            <!------------------------------------------------------------->
                            <div class="form-group">
                                <div class="text-center">
                                    <button id="btn_save" type="button" class="btn btn-info">Guardar Datos</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
            <!-- -->
            <div class="row">
                <div class="col-md-12">
                    <NuAlert ref="nuaRef" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import NuAlert from '@/components/nubox/NuAlert.vue';
export default {
  components: {
    NuAlert,
  },
  props: {},
  setup(props, context){
    const nuaRef = ref(null);//puntero al compoente
    /*const form = ref({

    })*/

    onMounted(()=>{

    })

    function btn_alert1(){
      nuaRef.value.success('mensaje 1', { title: 'OK' });
    }
   
    function close() {
        //--evento hacia afuera
        context.emit('close');
    }

    return{
      nuaRef,
      btn_alert1,
      close
     
    }
  }
}
</script>

<style >
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  
  }

</style>