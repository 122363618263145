<template>
  <div>
    <Modal :showModal="isModalVisible" 
           @closeModal="handleCloseModal">
        <template v-slot:title>Buscar cuenta</template>
        <form
            autocomplete="off"
            @submit.prevent.stop="handleConfirm"
            class=" rounded-lg overflow-hidden"
        >
            <table  align="center" style="width: 100%">
            <tbody>
                <tr>
                <td width="34%" className="celta class_rigth captionPie"></td>
                <td width="29%" className="captionPie">
                    <input  type="text" class="form-control" 
                            placeholder=""
                            v-model="form00.key"
                            />
                </td>
                <td width="37%"  className="captionPie" align="center">
                    <button @click="buscar_click()">Buscar</button>
                </td>
                </tr>

                <tr>
                <td colSpan="3" className="infocafe" height="25">
                   <NuLoading2 :loading="isloading" text="Cargando..." />
                </td>
                </tr>
            </tbody>
            </table>
        </form>

        <div class="row"> 
            <!--{/* ---TABLA ---- */}-->
            <div style="border:solid 1px #69C">
              <div className="co-xs-8 col-xs-offset-2 well" >
                <div  className="table-wrapper-scroll-y my-custom-scrollbar" 
                      style=" height: 350px; overflow: auto ">
                  <table id="tabla01" className='table table-scroll table-striped table-hover' width='100%' align='center'>

                    <thead>
                      <tr>
                        <th>Cod.</th>
                        <th>Nombre</th>
                      </tr>
                    </thead>

                    <tbody>
                  
                      <tr v-for="item in form00.items" :key="item"  >
                        <td>{{ item.id_cuenta }}</td>
                        <td>{{ item.nombre_cuenta }}</td>
                        <td>
                          <button type="button" class="btn btn-info" @click="okModal(item)" >OK</button>
                        </td>
                      </tr>
                  
                    </tbody>
                  </table>
                </div>
              </div>
            </div>    
        </div> 
            
    
        <div>
           
            <NuAlert  ref="nuaRef"/>
            

        </div>
        
       
    </Modal>
  </div>
</template>
<!-------------------------------------------------------------------------------->
<script>
import { ref,reactive, computed } from 'vue'
import { onMounted } from 'vue';
//import { computed } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import NuLoading2 from '@/components/nubox/NuLoading2.vue'; //~@ referencia a src
import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 
import Modal from "@/components/Common/Modal.vue";
import NuAlert from '@/components/nubox/NuAlert.vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    NuAlert,
    NuLoading2,
    Modal,
  },
  props: {},
  setup(props, context){
    const route = useRoute()
    const isloading = ref(false);
    const isModalVisible = ref(false);
    const nuaRef = ref(null);//puntero al compoente
    const indexTable = ref(null);//puntero la fila de la tabla

    const form00 = reactive({
      key: '',

      items: [
          { id_cuenta: '100', nombre_cuenta: 'sumar_en'},
      ]
    });
    //---esto es para el combo
    // Select tag items
    
    onMounted(()=>{
        console.log( "onMounted" );
        limpiarTabla();
    })

    //--limpia los datos
    function limpiarTabla(){
      form00.items = [];
    }
   
    //----------------------------------------
    //---------------------------------------------------------------------
    function show(index) {
      //--evento hacia afuera
      console.log("show in planModal___");
      indexTable.value= index;
      isModalVisible.value = true;
    }
    //-------------------------------------------
    const okModal=(item)=>{
        isModalVisible.value = false;
        context.emit("okModal",indexTable.value, item);
    }
    //-------------------------------------------
    const handleCloseModal = (n) => {
      console.log("evento close");
      isModalVisible.value = false;
      //context.emit("closeModal");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };
    //----------------------------------------------
    const buscar_click=()=>{
      let key = form00.key;
      buscarData(key);
    }
    //----------------------------------------------
    function buscarData(key){
        console.log("loadData");
        isloading.value =true; //muestra cargando
        
        const param={key: key}
        
        axios.post( process.env.VUE_APP_API_URL + "box_buscar_cuenta_find", param)
        .then(res=>{
            isloading.value =false; //quita cargando
            //console.log(res.data);
            if(res.data.length >0){
                //Object.assign(form00.items, res.data.data);
                form00.items = res.data;
                //form00.idd= id;
                //form00.ope= 'e';
            }else{
                nuaRef.value.error( "No existen resultados" , { title: '' });
            }
            
        })
        .catch(error => {
            isloading.value = false;//quita cargando
        //	this.errorMessage = error.message;
            alert("Hubo un error");
            console.error("There was an error!", error);
        });
    }
    return{
      form00,
      nuaRef,
      isModalVisible,
      limpiarTabla,
      show,
      okModal,
      handleCloseModal,
      buscarData,
      isloading,
      buscar_click,

     
    }
  }
}
</script>