import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "body-content" }
const _hoisted_2 = {
  key: 0,
  class: "mi-icon",
  style: {"width":"64px"}
}
const _hoisted_3 = {
  class: "text-gray-800 mb-3",
  style: {"margin-left":"1rem"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "bg-30 flex" }
const _hoisted_6 = { class: "dialog-footer" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      showModal: _ctx.isModalVisible,
      onCloseModal: _ctx.handleClose,
      style: {"width":"330px"}
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleKeydown && _ctx.handleKeydown(...args))),
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)), ["prevent","stop"])),
          class: "rounded-lg overflow-hidden"
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.icon!='')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createElementVNode("i", {
                    class: _normalizeClass([_ctx.icon, "fa-3x"]),
                    style: {"padding-left":"10px"}
                  }, null, 2)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
              (_ctx.men!='')
                ? (_openBlock(), _createElementBlock("br", _hoisted_4))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.men), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                ref: "runButton",
                dusk: "confirm-action-button",
                disabled: _ctx.working,
                type: "submit",
                class: _normalizeClass(["btn btn-primary btn-sm px-3 mr-3 pl-1", {
                            'btn-primary': _ctx.action=='primary',
                            'btn-danger': _ctx.action=='danger',
                        }]),
                style: {"margin-right":"5px"}
              }, _toDisplayString(_ctx.button.yes) + " ", 11, _hoisted_7),
              _createTextVNode("   "),
              _createElementVNode("button", {
                dusk: "cancel-action-button",
                type: "button",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)), ["prevent"])),
                class: "btn btn-default btn-sm text-80 font-normal h-9 px-3 mr-3 pl-1"
              }, _toDisplayString(_ctx.button.no), 1)
            ])
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["showModal", "onCloseModal"])
  ]))
}