<template>
   <div>
        
        <div class="content">

          
          <div class="row justify-content-center">
            <div class="col-md-8">

              {{ JSON.stringify( state.form0 ) }}

              <div class="table-responsive">
                <table class="table table-bordered table-hover " style="background-color: #fff; border: 1px solid #d2d2d2 " >
                  <tbody>
                    <tr>
                      <td colSpan="4" class="div_azulAma" style="background-color: #dddedf " > 
                        <h4>Configuracion global</h4>
                      </td>
                    </tr>

                    <tr>
                      <td class=" text-right" width="30%"><strong>Nombre:</strong></td>
                      <td width="30%">
                        <input
                          name="e_nom"
                          type="text"
                          v-model = "state.form0.e_nom"
                          size="25" />
                      </td>
                      
                      <td>
                        Logo:
                      </td>
                    </tr>


                    <tr>
                      <td class=" text-right"><strong>Direccion:</strong></td>
                      <td>
                        <input
                          name="e_dir"
                          type="text"
                          class=""
                          v-model = "state.form0.e_dir"
                          size="25" />
                      </td>
                      <td class=" text-center"  rowSpan={4} >
                        <div style=" width: 120px; display: inline-block " >
                          <ImageUploadSingle  
                            @okImage= "okImage"
                            @cancelImage= "cancelImage"
                            :image_API_URL="IMAGE_API_URL"
                            :image_PATH_FILES = "IMAGE_API_AFILES"
                            :fileName="state.form0.e_logo"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class=" text-right"><strong>Teléfono:</strong></td>
                      <td>
                        
                        <input
                          name="e_tel"
                          type="text"
                          class=""
                          v-model = "state.form0.e_tel"
                        />
                      </td>
                      
                    </tr>

                  

                    <tr>
                      <td class=" text-right"><b>Email:</b></td>
                      <td>
                        <input
                          name="e_email"
                          type="text"
                          class=""
                          v-model = "state.form0.e_email"
                        />
                      </td>
                      
                    </tr>

                    <tr>
                      <td class=" text-right"><b>Ciudad:</b></td>
                      <td>
                        <input
                          name="e_ciudad"
                          type="text"
                          class=""
                          v-model = "state.form0.e_ciudad"
                        />
                      </td>
                      
                    </tr>
                    <tr>
                    
                    <td colSpan="4" class="div_azulAma" style=" background-color: #dddedf "> 
                        <h4>Reporte</h4>
                      </td>
                    </tr>

                    <tr>
                      <td class=" text-right"><b>Reporte logo:</b></td>
                      <td>
                        <div style=" width: 180px ; display: inline-block ">
                          <ImageUploadSingle  
                            @okImage= "okImage1"
                            @cancelImage= "cancelImage1"
                            :image_API_URL="IMAGE_API_URL"
                            :image_PATH_FILES = "IMAGE_API_AFILES"
                            :fileName= "state.form0.rep_logo"
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td class=" text-right"><b>Rep leyenda:</b></td>
                      <td>
                        <textarea
                          name="rep_leyenda"
                          type="text"
                          class="form-control"
                          rows="2"
                          v-model = "state.form0.rep_leyenda"
                        ></textarea>
                      </td>
                      <td class=" text-right">
                        <div class="notecafe info"></div>
                      </td>
                    </tr>


                    <tr>
                    <td colSpan="4" class="div_azulAma" style="background.color: #dddedf "> 
                        <h4>Facturación</h4>
                      </td>
                    </tr>

                    <tr>
                      <td class=" text-right"><strong>Tipo:</strong></td>
                      <td>
                      </td>
                      <td class=" text-right">
                        <div class="notecafe info">.</div>
                      </td>
                    </tr>


                    <tr>
                      <td class="" colSpan="3" style="text-align: center">
                      <button @click="save" class="btn btn-success">
                          <i class="fa fa-floppy-o"></i>&nbsp;
                          Guardar Datos
                      </button>
                      
                      <button class="btn btn-default" >Cancelar</button>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="3">
                        <div id="divres"></div>

                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
                
            </div>
          </div>

          <!-- -------------------------------------------------->

        </div>

      </div>
</template>

<script lang="ts">
//import auth from '../config/auth.js';

import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';

import axios from '@/shared/jwtInterceptor';  //axios interceptado
import ImageUploadSingle from "@/components/nubox/ImageUploadSingle.vue";
import Modal from "@/components/Common/Modal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 

const initialForm={
  idd:0,
  ope:'n',
  e_nom:0,
  e_dir:'',
  e_logo:'',
  e_tel:'',
  e_email : "",
  e_ciudad:"",
  rep_logo:'',
  rep_leyenda: '',
  obs:'',
}

export default defineComponent({
  components: {
    Modal,
    ImageUploadSingle,
  },
  props: {
    title: {  type: String,  required: false, },
  },
  setup(_,context:any) {
    
    const isModalVisible = ref(false);
    const IMAGE_API_URL = ref(process.env.VUE_APP_API_URL + "image_uploadPhoto");
    const IMAGE_API_AFILES = ref(process.env.VUE_APP_AFILES + "/afiles/");

    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
      medicos:[],
      horas:[],
      //selected_estado:0,
      estados:[ { value:0, label: "No Confirmado",color:"#008ffb"  },
                { value:1, label: "Confirmado", color:"#ce9320" },
                { value:2, label: "Hora Cancelada", color:"#ff4560" },
                { value:3, label: "Atendido", color:"#00e396" },
              ],
    });


    /*const state = reactive({
      modalInsertar:false,
     
    });*/

    onMounted(() => {
      cargar_globalx();
    });

    function limpiar(){
      state.form0 = initialForm; 
    }
    
    /************************************************ */
    const okImage = (filename:string ) =>{    
      console.log(filename);
      state.form0.e_logo = filename; 
    }
    const cancelImage = () =>{
      state.form0.e_logo = ''; 
    }
    //-----------------------------------------------
    const okImage1 = (filename:string ) =>{    
      state.form0.rep_logo = filename; 
    }
    const cancelImage1 = () =>{
      state.form0.rep_logo = ''; 
    }
    //-----------------------------------------------
    const cargar_globalx = () => {
      let param = { 
        
      }
      
      axios.post(process.env.VUE_APP_API_URL  + "globalx_get_global", param)
      .then(res => {
        //console.log(res.data);

          if(res.data !== null ){ 
              //NotificationManager.success("Correcto");
              //console.log(res.data);
              Object.assign(state.form0, res.data);
              state.form0.idd= 1;
              state.form0.ope= 'e';
              //console.log(this.state.medicos);
          }else{
              limpiar();
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    };

    //------------------------------------------------
    const save =() =>{
      
        //let param =  {};
        let param =  state.form0;
        param.idd =1;
        param.ope ='e';
        //console.log(param);

        axios.post(process.env.VUE_APP_API_URL  + "globalx_store", param)
        .then(res => {
            if(res.data.exito === 1){ 
                toast.success(res.data.mensaje);
            }else{
                toast.error(res.data.mensaje);
            }

        }).catch(error => {
            alert("---- Existio un error ---");
            console.log(error);
            
        });
      
    }


    return {
      isModalVisible,
      state,
      IMAGE_API_URL,
      IMAGE_API_AFILES,
      limpiar,
      cargar_globalx,
      save,
      okImage,
      cancelImage,
      okImage1,
      cancelImage1,
      
    };
  },
})
</script>
