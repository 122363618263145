import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-362fd739"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cell__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['cell', ..._ctx.allClass]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
    style: _normalizeStyle(`background-color:${_ctx.bg_color}`)
  }, [
    _createElementVNode("div", {
      class: "cell__day",
      style: _normalizeStyle(`color:${_ctx.color}; `)
    }, _toDisplayString(_ctx.number1()), 5),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 6))
}