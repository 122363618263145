<template>
  <div>
      
        <div class="content5">
          <h1> Bienvenido al sistema</h1>
          <p>Nidera</p>
          {{ state.selected_item }}
          <hr style="margin: 50px 0px; border: 1px solid rgb(227, 227, 227);">

          <div style="width: 300px;">
            <AutoComplete v-model="state.selected_item" 
                  :suggestions="state.items" 
                  @selected="onSelectedAuto" />
          </div>
        </div>
      
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { ref, reactive, computed, onMounted } from "vue";
import { defineComponent, PropType } from "vue";
import axios from "@/shared/jwtInterceptor"; //axios interceptado

import AutoComplete from "@/components/nubox/AutoComplete.vue";

const initialForm = {
  idd: 0,
  ope: "n",
}

export default {
  name: 'Home',
  components: {
    AutoComplete,
  },
  props: {},
  setup(props: any, context: any) {

    const state = reactive({
      modalInsertar: false,
      form0: initialForm,
      roles: [],
      cajas: [],
      selected_item:0,
      items: [
        { value: 0, label: "Sucre"},
        { value: 1, label: "Tarija"},
        { value: 2, label: "Potosi"},
        { value: 3, label: "Oruro"},
        { value: 4, label: "La Paz"},
        { value: 5, label: "Santa Cruz"},
        { value: 6, label: "Beni"},
        { value: 7, label: "Pando"},
        { value: 8, label: "El alto"},
      ],
    });

    const onSelectedAuto=()=>{

    }

    return {
      state,
      onSelectedAuto
    };
  }
}
</script>
