<template>
  <div class="badge">
    <span :class="['badge__title', `badge__title--${type}`]">{{ `${dateString} ${name} ${type}` }}</span>
  </div>
</template>


<script lang="ts"  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue';
import moment from 'moment'

export default defineComponent({
  components: {
    
  },
  props: {
    id: {  type: Number,  required: true, },
    type: {type: String, default: ''},
    name: {type: String, default: ''},
    date: {type: Object, default: ''},
  },
  setup(props, {emit}){
    //const working = ref(false);
    
    //------------------------------------------------------------------------
    const dateString = computed(() => {
      let dt = new Date();
      let sol = moment(dt).format('H:MM')
      return sol;
      //return `${dt.format('H:MM')}`;
    });
      
    //----------------------------evento
  
    //----------------------------------  
    //--------------------------

    return{
      dateString,
         
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
.badge{
  font-size: 14px;
  margin: 0 0 3px 0;
  width: 100%;
  position: relative;
  line-height: 16px;
}
.badge__title{
  border-radius: 5px;
  padding: 0 3px;
  display: block;
  width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.badge__title:hover{
  display: inline;
}

.badge__title--info {
      background-color: #eef5f1;
      color: #97bb9f;
}
.badge__title--error {
      background-color: #fceced;
      color: #c1868c;
}
.badge__title--warning {
      background-color: #faead0;
      color: #d2ae81;
    }
</style>