import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "../store/index";
import axios from 'axios';

import HomeView from '../views/HomeView.vue'

import NotFound from '../pages/error/NotFound.vue'
//----
import Login from '../pages/login/Login.vue'
import Home from '../pages/Home.vue'
import Dashboard from '../pages/Dashboard.vue'
import About from '../pages/About.vue'
import Main from '../pages/Main.vue'

import Perfil from '../pages/Perfil.vue'
import Globalx from '../pages/Globalx.vue'

import Usuario_crud from '../pages/mantenimiento/usuario/Usuario_crud.vue'
import Usuario_nuevo from '../pages/mantenimiento/usuario/Usuario_nuevo.vue'

import Tramite_crud from '../pages/mantenimiento/tramite/Tramite_crud.vue'
import Tramite_nuevo from '../pages/mantenimiento/tramite/Tramite_nuevo.vue'

import Inmueble_crud from '../pages/mantenimiento/inmueble/Inmueble_crud.vue'
import Inmueble_nuevo from '../pages/mantenimiento/inmueble/Inmueble_nuevo.vue'

import Cliente_crud2 from '../pages/mantenimiento/cliente/Cliente_crud2.vue'
import Cliente_nuevo from '../pages/mantenimiento/cliente/Cliente_nuevo.vue'

import Reque_crud from '../pages/mantenimiento/requerimiento/Reque_crud.vue' 
import Reque_nuevo from '../pages/mantenimiento/requerimiento/Reque_nuevo.vue' 

import Venta_crud from '../pages/venta/Venta_crud.vue' 
import Venta_nuevo from '../pages/venta/Venta_nuevo.vue' 
import VentaPos from '../pages/venta/VentaPos.vue'


import Agenda from '../pages/Agenda.vue'

import Plan_crud from '../pages/mantenimiento/plan_cuentas/Plan_crud.vue'
import Plan_nuevo from '../pages/mantenimiento/plan_cuentas/Plan_nuevo.vue'

import Asiento_crud from '../pages/mantenimiento/asientos/Asiento_crud.vue'
import Asiento_nuevo from '../pages/mantenimiento/asientos/AsientoNuevo.vue'

import Rep_libro_diario from '../pages/reportes/Rep_libro_diario.vue'
import Rep_mayores from '../pages/reportes/Rep_mayores.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Login,
  },
  
  //{ path: '/', name:'login', meta: { layout:"VoidLayout",requiredAuth: false  }, component: Login },
  //{ path: '/', name:'login', component: Login },
  { path: '/login', name:'login', meta: { layout:"VoidLayout",requiredAuth: false  }, component: Login },

  { path: '/about',   name: 'about',   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')  },  
  { path: '/perfil', name: 'perfil', meta: { layout:"Pluto", requiredAuth: true }, component: Perfil },
  { path: '/globalx', name: 'globalx', meta: { layout:"Pluto", requiredAuth: true }, component: Globalx },

  { path: '/home', name: 'Home', meta: { layout:"Pluto" ,requiredAuth: true }, component: Home },
  { path: '/dashboard', name: 'Dashboard', meta: { layout:"Pluto" ,requiredAuth: true }, component: Dashboard },
  { path: '/about', name: 'About', meta: { layout:"NoSidebar",requiredAuth: true }, component:About},
  { path: '/main', name: 'main', meta: { layout:"PlutoNoSideBar",requiredAuth: true }, component:Main},

  { path: '/usuarios', name: 'usuarios', meta: { layout:"Pluto", requiredAuth: true }, component: Usuario_crud },
  { path: '/usuario_nuevo/:idd/:ope', name: 'usuario_nuevo', meta: { layout:"Pluto", requiredAuth: true }, component: Usuario_nuevo },

  { path: '/tramites', name: 'tramites', meta: { layout:"Pluto", requiredAuth: true }, component: Tramite_crud },
  { path: '/tramite_nuevo/:idd/:ope', name: 'tramite_nuevo', meta: { layout:"Pluto", requiredAuth: true }, component: Tramite_nuevo },

  { path: '/inmuebles', name: 'inmuebles', meta: { layout:"Pluto", requiredAuth: true }, component: Inmueble_crud },
  { path: '/inmueble_nuevo/:idd/:ope', name: 'inmueble_nuevo', meta: { layout:"Pluto", requiredAuth: true }, component: Inmueble_nuevo },

  { path: '/clientes', name: 'clientes', meta: { layout:"Pluto", requiredAuth: true }, component: Cliente_crud2 },
  { path: '/cliente_nuevo/:id/:ope', name: 'cliente_nuevo', meta: { layout:"Pluto", requiredAuth: true }, component: Cliente_nuevo },

  { path: '/requerimientos', name: 'requerimientos', meta: { layout:"Pluto", requiredAuth: true }, component: Reque_crud },
  { path: '/requerimiento_nuevo/:idd/:ope', name: 'requerimiento_nuevo', meta: { layout:"Pluto", requiredAuth: true }, component: Reque_nuevo },

  //-------------ok---
  { path: '/ventas', name: 'ventas', meta: { layout:"Pluto", requiredAuth: true }, component: Venta_crud },
  { path: '/venta_nuevo/:idd/:ope', name: 'venta_nuevo', meta: { layout:"Pluto", requiredAuth: true }, component: Venta_nuevo },
  { path: '/venta_pos', name: 'venta_nuevo', meta: { layout:"Pluto", requiredAuth: true }, component: VentaPos },

  { path: '/agenda', name: 'agenda', meta: { layout:"Pluto", requiredAuth: true }, component: Agenda },

  { path: '/plan_cr', name: 'plan_cr', meta: { layout:"AdminLte", requiredAuth: true }, component: Plan_crud },
  { path: '/plan_nuevo/:id/:ope', name: 'plan_nuevo', meta: { layout:"AdminLte", requiredAuth: true }, component: Plan_nuevo },

  { path: '/asientos', name: 'asientos', meta: { layout:"AdminLte", requiredAuth: true }, component: Asiento_crud },
  { path: '/asiento_nuevo/:idd/:ope', name: 'asiento_nuevo', meta: { layout:"AdminLte", requiredAuth: true }, component: Asiento_nuevo },

  { path: '/libro_diario', name: 'libro_diario', meta: { layout:"AdminLte", requiredAuth: true }, component: Rep_libro_diario },
  { path: '/rep_mayores', name: 'rep_mayores', meta: { layout:"AdminLte", requiredAuth: true }, component: Rep_mayores },

  /*******especiales************ */
  { path: "/:pathMatch(.*)", name: 'not-found', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;

//-----------------------------------------------------------------
router.beforeEach(async (to, from, next) => {
  //console.log(store.getters["auth/getAuthData"].token);
  if (!store.getters["auth/getAuthData"].token) {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (access_token) {
      const data = {
        access_token: access_token,
        refresh_token: refresh_token,
      };
      store.commit("auth/saveTokenData", data);
    }
  }
  //---si token es activo
  let auth = store.getters["auth/isTokenActive"];
  console.log("auth:"+auth);
  console.log("to.meta.requiredAuth:"+to.meta.requiredAuth);
  
  /* esto he añadido, jimmy */
  if(!auth && !to.meta.requiredAuth){
    //return next({ path: "/login" });
    return next();
  }
  
  //console.log("paso 2");
  if (!auth) {
    const authData = store.getters["auth/getAuthData"];
    //console.log(authData.token);

    if (authData.token) {
      console.log("--->");
      const payload = {
        access_token: authData.token,
        refresh_token: authData.refresh_token,
      };
      //"http://localhost:3000/auth/refreshtoken",
      //const refreshResponse = await axios.post(process.env.VUE_APP_API_URL + "refresh", payload);
      
      //store.commit("auth/saveTokenData", refreshResponse.data);
      //auth = true;
      return next({path: "/login"});  //   /login
    }else{
      //console.log("else");
      //----jimmy
      //return next({path: "/"});  //   /login
      return next({path: "/login"});  //   /login
    }
  }

  /*if (to.fullPath == "/") {
    return next();
  } else if (auth && !to.meta.requiredAuth) {
    return next({ path: "/dashboard" });
  } else if (!auth && to.meta.requiredAuth) {
    return next({ path: "/login" });
  }*/

  return next();
});
