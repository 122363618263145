<template>
    <div >
        <!-- loading -->
        <div class="dataTables_length">
          <!--<label>Mostrar -->
						<select :value="per_page" @change="onChangeNumRows($event)" v-bind:class="clasex">
							<option value="10" selected="selected">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select> 
          <!--registros</label>-->
					</div>
        <!-- -->
    </div>
</template>

<script>

//import { ref,reactive } from 'vue'
//import { onMounted } from 'vue'
//import { computed} from 'vue'

export default {
  components: {
  },
  props:{
      per_page:{type: Number, default: 10},
      clasex:{ type: String, default: null}, //background-color
  },
  //setup(props, context) {
  setup(props,context) {

    //--- Metodos ------------ 
    //----cal@se retorna afuera
    function onChangeNumRows(new_page) {

        //--de lo contrario emitimos el evento
        context.emit('onChangeNumRows',new_page);
    }
    return {
        onChangeNumRows,
    }
  }
  
}
</script>
<style scoped>

.loading-circle {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-left-color: #ababab;
  animation: circleanimation .45s linear infinite
}

@keyframes circleanimation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

    
</style>