<template>
  <div>
    <Modal :showModal="isModalVisible" 
           @closeModal="handleCloseModal">
        <template v-slot:title>Plan de cuenta</template>
        <form class="form-horizontal" role="form">
            <div class="row"> 
                <label for="username" class="col-sm-3 col-form-label ">Código</label> 
                <div class="col-sm"> 
                    <input type="text" 
                        class="form-control form-control" 
                        placeholder=""
                        v-model="form00.id_cuenta"
                        > 
                </div> 
            </div> 
            

            <div class="row"> 
                <label for="username" class="col-sm-3  col-form-label ">nombre_cuenta</label> 
                <div class="col-sm"> 
                    <input type="text" 
                        class="form-control form-control" 
                        placeholder=""
                        v-model="form00.nombre_cuenta"
                        > 
                </div> 
            </div> 

            <div class="row"> 
                <label for="incheck" class="col-sm-3 col-form-label ">recibe_asientos</label> 
                <div class="col-sm"> 
                    

                    <input
                        type="checkbox"
                        id="incheck"
                        name="recibe_asientos"
                        
                        :checked="form00.recibe_asientos? 1:0"
                        v-on:change="handleChangeFormCheck"  
                    />
                    
                </div> 
            </div> 

            <div class="row"> 
                <label for="username" class="col-sm-3  col-form-label ">sumar_en</label> 
                <div class="col-sm"> 
                    <input type="text" 
                        class="form-control form-control" 
                        placeholder=""
                        v-model="form00.sumar_en"
                        > 
                </div> 
            </div> 

            <div class="row"> 
                <label for="username" class="col-sm-3  col-form-label ">nivel_detalle</label> 
                <div class="col-sm"> 
                    <input type="text" 
                        class="form-control form-control" 
                        placeholder=""
                        v-model="form00.nivel_detalle"
                        > 
                </div> 
            </div> 

            <div class="row"> 
                <label for="username" class="col-sm-3  col-form-label ">grupo_cuenta</label> 
                <div class="col-sm"> 
                    
                    <NuDropDown
                        name="id_grupoc" 
                        :options = "options"
                        :selectValue = "form00.id_grupoc"
                        @onChange ="handleChangeDrop"
                    />
                </div> 
            </div> 

     
          <div class="row"> 
            <div class="form-group">
                <div class="text-end">
                    <button type="button" class="btn btn-primary" @click="save()">Aceptar</button>
                    &nbsp;
                    <button type="button" class="btn btn-default" @click="handleCloseModal()">Cancelar</button>
                </div>
            </div>
          </div>
    
        <div>
            <NuLoading :loading="isloading" text="Cargando..." />
            
        </div>
        
        </form>
    </Modal>
  </div>
</template>
<!-------------------------------------------------------------------------------->
<script>
import { ref,reactive, computed, onMounted } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import NuAlert from "@/components/nubox/NuAlert.vue";
import Modal from "@/components/Common/Modal.vue";
import NuLoading from '@/components/nubox/NuLoading.vue'; //~@ referencia a src
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 

const formReset = {
        idd:0,
        ope:'n',
        id_cuenta: "",
        nombre_cuenta: "ACTIVO",
        recibe_asientos: 0,
        sumar_en: "",
        nivel_detalle: 1,
        id_grupoc: 1
    };

export default {
  components: {
    NuAlert,
    NuLoading,    
    Modal,
    NuDropDown,
  },
  props: {},
  setup(props, context) {
    const nuaRef = ref(null); //puntero al compoente
    
    const form00 = reactive(formReset);

    const selectedOption= ref(null);
    const options = ref([
        {value: 1, label:'Activo'},
        {value: 2, label:'Pasivo'},
        {value: 3, label:'Capital'},
        {value: 4, label:'Ingresos'},
        {value: 5, label:'Egresos'},
    ])
    

    const isloading = ref(false);
    const isModalVisible = ref(false);

    onMounted(() => {});

    function btn_alert1() {
      nuaRef.value.success("mensaje 1", { title: "OK" });
    }
    //---------------------------------------------------------------------
    function show() {
      //--evento hacia afuera
      console.log("show in planModal___");
      
      isModalVisible.value = true;
      
      Object.assign(form00, formReset);
      //console.log(formReset);
      //form00 = { ...formReset };
      form00.idd= 0;
      form00.ope= 'n';
    }
    //----------------------------------------------
    function editar(idd) {
      //--evento hacia afuera
      console.log("editar in planModal___");
      isModalVisible.value = true;

      buscarData(idd);
    }
    function eliminar(id){
        resetForm();
        form00.idd= id;
        form00.ope= 'x';
        save();
    }

    //----cambiamos el valor de true a 1, y false a 0
    function handleChangeFormCheck(e){
        console.log(e.target.checked);
        e.target.checked= !e.target.checked;
        form00.recibe_asientos= e.target.checked? 0:1;
    }

    //--- item.value, item.label
    const handleChangeDrop=(item)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        form00.id_grupoc = item.value;
    }

    const handleCloseModal = (n) => {
      isModalVisible.value = false;
      console.log("evento close");
      context.emit("closeModal");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };
    function resetForm() {
      Object.assign(form00, formReset);
    }
    //----------------------------------------------
    function buscarData(id){
        console.log("loadData");
        isloading.value =true; //muestra cargando
        
        const param={id_cuenta: id}
        axios.post( process.env.VUE_APP_API_URL + "plan_buscar", param)
        .then(res=>{
            isloading.value =false; //quita cargando
            console.log(res.data.data);
            if(res.data.exito==1){
                Object.assign(form00, res.data.data);
                form00.idd= id;
                form00.ope= 'e';
            }else{
                toast(res.data.men, { autoClose: 1000, }); // ToastOptions
            }
            
        })
        .catch(error => {
            isloading.value = false;//quita cargando
        //	this.errorMessage = error.message;
            alert("Hubo un error");
            console.error("There was an error!", error);
        });
    }
    //----------------------------------------------
    function save(){
        console.log("saveData");
        isloading.value =true; //muestra cargando
        //----------------
        
        //const param= form00;
        const param= {};
        Object.assign(param, form00);

        axios.post( process.env.VUE_APP_API_URL + "plan_store", param)
        .then(res=>{
            isloading.value =false; //quita cargando
            
            console.log(res.data);
            if(res.data.exito==1){
                toast.success('Guardado con exito');
                isModalVisible.value = false;
                context.emit("okModal");
            }else{
                toast.error(res.data.men, { autoClose: 1000, }); // ToastOptions
            }
            //form00.value = res.data;
            
        })
        .catch(error => {
            isloading.value = false;//quita cargando
            alert("Hubo un error");
            console.error("There was an error!", error);
        });
    }

    return {
      nuaRef,
      formReset,
      form00,
      isModalVisible,
      btn_alert1,
      show,
      handleCloseModal,
      options,
      handleChangeDrop,
      handleChangeFormCheck,
      resetForm,
      buscarData,
      editar,
      eliminar,
      save,
    };
  },
};
</script>

<style >
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
</style>