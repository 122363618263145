<template>
  <div>
    <!-- -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/main">
            <i class="fas fa-home"></i>Inicio
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/ventas">Tabla venta</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">usuario</li>
      </ol>
    </nav>
    <!-- -->
    {{ JSON.stringify(state.form0) }}
    <!-- -->
    <div>
      <legend style="text-align: center">
        <strong>Formulario de venta</strong>
      </legend>
    </div>
    <br />
    <div class="row">
      <div
        class="col-md-12"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div class="card mb-3 col-md-7">
          <div class="card-header bg-info">Datos</div>
          <div class="card-body">
            <!---------------------------------------------------->
            <form id="form0">
              <!-- ---------------------------------------------- -->

              <!--------------------------->

              <form method="POST">
                <div class="form-group row">
                    
                  <label for="idi" class="col-sm-3 col-form-label">Cod.</label>
                  <div class="col-sm-3">
                    <input
                      name="id_v"
                      type="text"
                      class="form-control"
                      v-model="state.form0.id_v"
                    />
                  </div>
                
              
              
                  <label for="nro_doc" class="col-sm-3 col-form-label"
                    >Fecha:<span class="text-danger">*</span></label
                  >
                  <div class="col-sm-3">
                    <input
                      name="fecha_reg"
                      type="date"
                      class="form-control"
                      v-model="state.form0.fecha_reg"
                    />
                  </div>
                </div>
                  

               <!-----------2---------------->
              <div class="form-group row">
                  <label for="nro_doc" class="col-sm-3 col-form-label">Cliente<span
                          class="text-danger">*</span></label>
                  <div class="col-sm-8">
                    <div class="input-group">
                        <input 
                            name='nro_doc' 
                            type='text' 
                            class='form-control' 
                            v-model="state.form0.cliente" 
                        />
                        <button class="input-group-text" 
                                  @click="btn_search_click">
                                  <i class="fa fa-search"></i>
                        </button>
                    
                        <button class="input-group-text" 
                                  @click="btn_add_click">
                                  <i class="fa fa-plus"></i>
                        </button>
                    </div>
                  </div>
                  
              </div>
              <!-- -----------8------------ -->


                <div class="form-group row">
                  <label htmlFor="idi" class="col-lg-3 control-label"
                    ><b>idi:</b></label
                  >
                  <div class="col-lg-9">
                    <NuInput
                      className="text-right form-control"
                      type="text_int"
                      name="idi"
                      value="{this.state.form.idi}"
                      onChange="{this.handleChangeForm}"
                      classOK=""
                      classError="is-invalid"
                      valtipo="int"
                    />
                  </div>
                </div>
                <div class="top_title2">
                  <h4 class="">Bloque 5</h4>
                </div>

                <div class="form-group row">
                  <label htmlFor="detallet" class="col-lg-3 control-label"
                    ><b>detallet:</b></label
                  >
                  <div class="col-lg-9">
                    <input
                      name="detallet"
                      type="text"
                      class="form-control"
                      v-model="state.form0.detallet"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label htmlFor="costo" class="col-lg-3 control-label"
                    ><b>costo:</b></label
                  >
                  <div class="col-lg-9">
                    <NuInput
                      className="text-right form-control"
                      type="text_double"
                      name="costo"
                      value="{this.state.form.costo}"
                      onChange="{this.handleChangeForm}"
                      classOK=""
                      classError="is-invalid"
                      valtipo="float"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label htmlFor="total" class="col-lg-3 control-label"
                    ><b>total:</b></label
                  >
                  <div class="col-lg-9">
                    <NuInput
                      className="text-right form-control"
                      type="text_double"
                      name="total"
                      value="{this.state.form.total}"
                      onChange="{this.handleChangeForm}"
                      classOK=""
                      classError="is-invalid"
                      valtipo="float"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label htmlFor="saldo" class="col-lg-3 control-label"
                    ><b>saldo:</b></label
                  >
                  <div class="col-lg-9">
                    <NuInput
                      className="text-right form-control"
                      type="text_double"
                      name="saldo"
                      value="{this.state.form.saldo}"
                      onChange="{this.handleChangeForm}"
                      classOK=""
                      classError="is-invalid"
                      valtipo="float"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label htmlFor="id_u" class="col-lg-3 control-label"
                    ><b>id_u:</b></label
                  >
                  <div class="col-lg-9">
                    <NuDropDown
                      name="id_u"
                      :options="state.estados"
                      :selectValue="state.form0.id_u"
                      @onChange="handleChangeDrop"
                    />
                  </div>
                </div>
                <div class="top_title2">
                  <h4 class="">Bloque 10</h4>
                </div>

                <div class="form-group row">
                  <label htmlFor="usuario" class="col-lg-3 control-label"
                    ><b>usuario:</b></label
                  >
                  <div class="col-lg-9">
                    <input
                      name="usuario"
                      type="text"
                      class="form-control"
                      v-model="state.form0.usuario"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label htmlFor="estado" class="col-lg-3 control-label"
                    ><b>estado:</b></label
                  >
                  <div class="col-lg-9">
                    <NuInput
                      className="text-right form-control"
                      type="text_int"
                      name="estado"
                      value="{this.state.form.estado}"
                      onChange="{this.handleChangeForm}"
                      classOK=""
                      classError="is-invalid"
                      valtipo="int"
                    />
                  </div>
                </div>
              </form>

              <!------------------------------------------------------------->
              <div class="form-group">
                <div className="text-center">
                  <Button @click="aceptar_click" className="btn btn-primary">
                    <i className="fa fa-floppy-o"></i>&nbsp; Guardar
                  </Button>
                  &nbsp;
                  <router-link to="/Ventas" className="btn btn-default"
                    >Volver</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- -->
      <div class="row">
        <div class="col-md-12">
          <NuAlert ref="nuaRef" />
        </div>
      </div>
    </div>
  </div>

  <BoxBuscarCliente ref="boxClienteRef" @okModal="handleOkBox" />

  <ClienteModal2 
    ref="clienteModalRef"
    @okModal="handleOkBoxCli"
  >
  </ClienteModal2>
  <VentaModal ref="ventaModalRef" @okModal="handleOkModalVen"> </VentaModal>

  <NuConfirm
    ref="confirmRef"
    title="Confirmación"
    message="¿Esta ud seguro de guardar?"
    icon="fa fa-question-circle"
    @confirm="handleConfirm"
  >
  </NuConfirm>
</template>


<script lang="ts">
import { ref, reactive, computed, onMounted } from "vue";
import { defineComponent, PropType } from "vue";
import axios from "@/shared/jwtInterceptor"; //axios interceptado

import NuDropDown from "@/components/nubox/NuDropDown.vue";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";
import NuConfirm from "@/components/nubox/NuConfirm.vue"; //~@
import NuAlert from "@/components/nubox/NuAlert.vue";
import ImageUploadSingle from "@/components/nubox/ImageUploadSingle.vue";
import BoxBuscarCliente from '@/pages/_box/BoxBuscarCliente.vue'; //~@ 
import ClienteModal2 from '@/pages/mantenimiento/cliente/ClienteModal2.vue';

const initialForm = {
  idd: 0,
  ope: "n",

  id_v: 0,
  fecha_reg: "",
  id_cliente: 0,
  cliente: "",
  idi: 0,
  detallet: "",
  costo: 0,
  total: 0,
  saldo: 0,
  id_u: 0,
  usuario: "",
  estado: 0,
};

export default {
  components: {
    NuConfirm,
    NuAlert,
    ImageUploadSingle,
    NuDropDown,
    BoxBuscarCliente,
    ClienteModal2,
  },
  props: {},
  setup(props: any, context: any) {
    const nuaRef = ref(null); //puntero al compoente
    const route = useRoute(); //variables de ruta
    const confirmRef = ref<InstanceType<typeof NuConfirm>>(); //puntero al componente
    const boxClienteRef = ref<InstanceType<typeof BoxBuscarCliente>>(); //puntero al componente
    const clienteModalRef = ref<InstanceType<typeof ClienteModal2>>();

    const IMAGE_API_URL = ref(
      process.env.VUE_APP_API_URL + "image_uploadPhoto"
    );
    const IMAGE_API_AFILES = ref(process.env.VUE_APP_AFILES + "/afiles/");

    const state = reactive({
      modalInsertar: false,
      form0: initialForm,
      roles: [],
      cajas: [],
      //selected_estado:0,
      estados: [
        { value: 1, label: "Recibido", color: "#008ffb" },
        { value: 2, label: "Derivado", color: "#ce9320" },
        { value: 3, label: "Observado", color: "#ce9320" },
        { value: 4, label: "Finalizado", color: "#ce9320" },
      ],
    });

    //------------------------------------------------------------------
    onMounted(() => {
      //console.log(route.params.idd );
      //---cargamos el combo
      cargar_cbo_rol();

      //--fecha de inicio
      const date = new Date();
      const fecha_now = date.toLocaleDateString("en-CA");
      state.form0.fecha_reg = fecha_now;

      let idd: number = Number(route.params.idd);
      let ope = route.params.ope;

      //state.form0.idd = idd;
      //state.form0.ope = ope;

      if (ope == "n") {
        //this.limpiar();
      } else {
        editar(idd);
      }
    });
    //-----------------------------
    //-----------------
    const cargar_cbo_rol = async () => {
      let param = {};

      await axios
        .post(process.env.VUE_APP_API_URL + "cbo_rol", param)
        .then((res) => {
          //if(res.data.length>0){
          state.roles = res.data;
          //}
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    };
    //--------------------------
    const handleCheckboxChange = (e: Event) => {
      let ret = e.target as HTMLInputElement;
      let sol = ret.checked ? 1 : 0;
      state.form0.estado = sol;
    };
    //--------------------------
    //----------------------------------------------
    function editar(idd: number) {
      console.log("editar:" + idd);

      let param = {
        id: idd,
      };
      console.log(param);

      axios
        .post(process.env.VUE_APP_API_URL + "ventacrud_buscar", param)
        .then((res) => {
          console.log(res.data);
          let response = res.data;

          if (res.data !== "") {
            Object.assign(state.form0, res.data);
            state.form0.idd = idd;
            state.form0.ope = "e";
          } else {
            toast.error("No existe el registro", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    }
    //---------------------------------------------
    const aceptar_click = () => {
      console.log("aceptar_click");
      confirmRef.value?.show(0, ".");
    };

    //----------------------esta es la funcion que llama la confirmacion
    const handleConfirm = (id: number) => {
      console.log("handleConfirm: " + id);

      save();
    };
    //-------------------------------------------
    //--- item.value, item.label
    const handleChangeDrop = (item: any) => {
      //requerimos la prop name="?" en cada control
      console.log(item.value + " - " + item.label);
      state.form0.id_v = item.value;
    };
    /*---------  ----------------*/
    const btn_search_click = (e: Event) => {
      e.preventDefault();
      boxClienteRef.value?.show();
    };
    //-------------------------------------
    const btn_add_click = (e: Event) => {
      e.preventDefault();
      clienteModalRef.value?.show();
    };
    //--------------------------
    function handleOkBox(index: number, item: any) {
      console.log(item);
      //---buscamos el row al cual tenemos que cambiar
      console.log(item.razon_social);

      state.form0.id_v = item.id_v;
      state.form0.cliente = item.razon_social;
      /*let result = form00.miTabla.filter((row) => {
       row.id_cuenta === item.id_cuenta;
    });*/
    }
    //---------------------------
    function handleOkBoxCli(item: any) {
      state.form0.id_cliente = item.id_liente;
      state.form0.cliente = item.razon_social;
    }
    //==========================================================================
    const save = () => {
      let param = state.form0;
      console.log(param);

      axios
        .post(process.env.VUE_APP_API_URL + "ventacrud_store", param)
        .then((res) => {
          if (res.data.exito === 1) {
            toast.success(res.data.men);
            console.log("res.data");
            console.log(res.data.data);
            //---ahora actualizamos el context API
          } else {
            toast.error(res.data.men);
          }
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    };
    return {
      nuaRef,
      state,
      editar,
      close,
      aceptar_click,
      save,
      handleConfirm,
      confirmRef,

      IMAGE_API_URL,
      IMAGE_API_AFILES,
      cargar_cbo_rol,
      handleChangeDrop,
      handleCheckboxChange,
      boxClienteRef,
      btn_add_click,
      btn_search_click,
      handleOkBox,
      clienteModalRef,
      handleOkBoxCli,
    };
  },
};
</script>
<style scoped>
</style>