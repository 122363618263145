<template>
  <div :class="['cell', ...allClass]" @click="click" :style="`background-color:${bg_color}`">
    <div class="cell__day" :style="`color:${color}; `" >{{ number1() }}</div>
    <div class="cell__body">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts"  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue';
import moment from 'moment'

//import cUtilFecha from "@/helpers/cUtilFecha";

export default defineComponent({
  components: {
    
  },
  props: {
    day: {  type: Object,  required: true, },
    active: {type: Boolean, default: false},
    date: {type: Object},
    color: {type: String, default: '#ccc'},
    bg_color:{type: String, default: '#fff'},
  },
  setup(props, {emit}){
    const working = ref(false);

    //------------------------------------------------------------------------
    const allClass = computed(() => {
      return [
        { 'cell--weekends': isWeekends() && isMonth && !isBefore },
        { 'cell--now': isNow },
        { 'cell--month': isMonth() && !isBefore },
        { 'cell--active': props.active },
      ];
    });
      
    const now =()=>{
      return moment().clone();
    }
    const isWeekends=()=>{
      return [6, 0].includes( props.day.day());
    }
    const isNow=()=> {
      return props.day.isSame( now, 'day');
    }
    const isMonth=()=> {
      return props.day.isSame( props.date, 'month');
    }
    const isBefore=()=> {
      return props.day.isBefore(now, 'day');
    }
    const number1=()=> {
      //console.log(props.day);
      return props.day.format('D');
    }
    //----------------------------evento
    const click=()=> {
      emit('select', props.day);
    }

    //console.log(props.modalActive);
    /*const handleKeydown=(e: KeyboardEvent)=>{
      if (['Escape', 'Enter'].indexOf(e.key) !== -1) {
                return
      }
      e.stopPropagation()
    }*/
    //----------------------------------  
    //--------------------------

    return{
      allClass,
      now,
      isWeekends,
      isNow,
      isMonth,
      isBefore,
      number1,
      click,      
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
.cell{
  height: 90px;
  width: 100%;
  background-color: #f5f5f5;
  color: #b7b4b4;
  border-radius: 5px;
  border: 1px solid #fff;
  transition: all 0.1s ease-in;
  z-index: 1;
}
.cell--month{
  color: #434141;
  background-color: #fff;
}
.cell--weekends{
  
}
.cell--weekends .cell__day {
      color: #7676f4;
}
.cell--active {
    border: 2px solid #9f99e8 !important;
    background-color: #fff6da;
}
.cell--now {
  background-color: #fff;
    border: 1px solid #d8e2e7; /*jim */
}
.cell__day{
  text-align: right;
  margin-right: 4px;
  color: #08e200;
}
.cell__body {
  padding: 0 5px;
    width: 100px;
    position: relative;
}
.cell:hover {
    z-index: 2;
}

</style>