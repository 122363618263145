<template>
    <div :style="newStyle">
      <span v-if="column.dataType=='text'" style="`text-align: ${column.align}`">{{ itemRow[column.field] }}</span>
      <span v-if="column.dataType=='number'">{{ itemRow[column.field] }}</span>
      <span v-if="column.dataType=='custom'">
        <!--{{
          column.renderCell(itemRow, column, onClicButton1)
        }}-->
       </span>
       <!--{{ itemRow["id_cliente"] }}-->
    </div>
</template>

<script lang="ts">

import { ref,reactive } from 'vue'
import { computed } from '@vue/runtime-core';
//import { onMounted } from 'vue'
//import { computed} from 'vue'

export default {
  components: {
  },
  props:{
    itemRow:{type: Object, default: {}},
    column:{ type: Object, default: {}}, 
    //onClicButton1:{type:Function, default: null},
  },
  //setup(props, context) {
  setup(props : any,context: any) {
    const direction = ref('');

    //--- Metodos ------------ 
    const newStyle =  computed(()=>{
      
      //return ret = column? '': column.align;
      var sol='';
      sol = `text-align:${props.column.align}`;
     
      return sol;
    });
    //----cal@se retorna afuera
  
    return {
        
        direction,
        newStyle,
    }
  }
  
}
</script>
<style scoped>
   
</style>