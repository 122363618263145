<template>
  <div>
    <!-- -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/main">
            <i class="fas fa-home"></i>Inicio
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/inmuebles">Tabla inmuebles</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">usuario</li>
      </ol>
    </nav>
    <!-- -->
    {{ JSON.stringify(state.form0) }}
    <!-- -->
    <div>
      <legend style="text-align: center">
        <strong>Formulario de requerimiento</strong>
      </legend>
    </div>
    <br />

    <div class="row">
      <div  class="col-md-12"  style="display: flex; align-items: center; justify-content: center">
        <div class="card">
          <div class="card-header">
            <h4>Formulario</h4>
          </div>

          <div class="card-body">
            <form>
              
              <div class="form-group row">
                <label htmlFor="id_req" class="col-lg-4 control-label">Cod.</label >
                <div class="col-lg-8">
                  <input
                    name="id_req"
                    type="text"
                    class="form-control"
                    v-model="state.form0.id_req"
                    readonly
                    disabled
                  />
                </div>
              </div>
              <!-----------2---------------->
              <div class="form-group row">
                  <label for="nro_doc" class="col-sm-4 col-form-label">Cliente<span
                          class="text-danger">*</span></label>
                  <div class="col-sm-8">
                    <div class="input-group">
                        <input 
                            name='nro_doc' 
                            type='text' 
                            class='form-control' 
                            v-model="state.form0.cliente" 
                        />
                        <button class="input-group-text" 
                                  @click="btn_search_click">
                                  <i class="fa fa-search"></i>
                        </button>
                    
                        <button class="input-group-text" 
                                  @click="btn_add_click">
                                  <i class="fa fa-plus"></i>
                        </button>
                    </div>
                  </div>
                  
              </div>
              <!-- -----------8------------ -->
              <div class="form-group row">
                <label htmlFor="nombre_cliente"  class="col-lg-4 control-label" >Nombre cliente:</label>
                <div class="col-lg-8">
                  <input
                    name="nombre_cliente"
                    type="text"
                    class="form-control"
                    v-model="state.form0.nombre_cliente"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label htmlFor="descrip" class="col-lg-4 control-label">Requiere:</label>
                <div class="col-lg-8">
                  <textarea
                    name="descrip"
                    type="text"
                    class="form-control"
                    rows="2"
                    v-model = "state.form0.descrip"
                  ></textarea>
                 
                </div>
              </div>

              <div class="form-group row">
                <label htmlFor="zona" class="col-lg-4 control-label">Zona</label>
                <div class="col-lg-8">
                  <input
                    name="zona"
                    type="text"
                    class="form-control"
                    v-model="state.form0.zona"
                  />
                </div>
              </div>

              <div class="top_title2">
                <h4 class="">Bloque 5</h4>
              </div>

              <div class="form-group row">
                <label htmlFor="precio_intervalo"  class="col-lg-4 control-label" >Precio estimado:</label>
                <div class="col-lg-8">
                  <input
                    name="precio_intervalo"
                    type="text"
                    class="form-control"
                    v-model="state.form0.precio_intervalo"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label htmlFor="contado_sn" class="col-md-4 control-label">¿Al contado?</label
                >
                <div class="col-md-8">
                  <input
                        type="checkbox"
                        name="contado_sn"
                        :checked="state.form0.contado_sn? 1:0"
                        v-on:change="handleCheckboxChange"  
                    />
                </div>
              </div>

              <div class="form-group row">
                <label  htmlFor="financiamiento"   class="col-lg-4 control-label">Financiamiento</label>
                <div class="col-lg-8">
                  <input
                    name="financiamiento"
                    type="text"
                    class="form-control"
                    v-model="state.form0.financiamiento"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label htmlFor="estado" class="col-lg-4 control-label"
                  >Estado:</label
                >
                <div class="col-lg-8">
                  <NuDropDown
                    name="total"
                    :options="state.estados"
                    :selectValue="state.form0.estado"
                    @onChange="handleChangeDrop"
                  />
                </div>
              </div>

            </form>
          </div>
          <div class="card-footer text-center">
            <button id="btn_ok" type="button" class="btn btn-success">
              Aceptar
            </button>
            <button class="btn btn-default">Volver   </button>
          </div>
        </div>
      </div>
    </div>

    <!-- -->
    <div class="row">
      <div class="col-md12">
        <NuAlert ref="nuaRef" />
      </div>
    </div>
  </div>

  <BoxBuscarCliente ref="boxClienteRef"
            @okModal="handleOkBox"
    />

  <ClienteModal2 
    ref="clienteModalRef"
    @okModal="handleOkBoxCli"
  >
  </ClienteModal2>

  <NuConfirm
    ref="confirmRef"
    title="Confirmación"
    message="¿Esta ud seguro de guardar?"
    icon="fa fa-question-circle"
    @confirm="handleConfirm">
  </NuConfirm>
</template>

<script lang="ts">
import { ref, reactive, computed, onMounted } from "vue";
import { defineComponent, PropType } from "vue";
import axios from "@/shared/jwtInterceptor"; //axios interceptado

import NuDropDown from "@/components/nubox/NuDropDown.vue";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";
import NuConfirm from "@/components/nubox/NuConfirm.vue"; //~@
import NuAlert from "@/components/nubox/NuAlert.vue";
import ImageUploadSingle from "@/components/nubox/ImageUploadSingle.vue";
import NuListRadio from "@/components/nubox/NuListRadio.vue";
import BoxBuscarCliente from '@/pages/_box/BoxBuscarCliente.vue'; //~@ 
import ClienteModal2 from '@/pages/mantenimiento/cliente/ClienteModal2.vue';

//---
const initialForm = {
  idd: 0,
  ope: "n",
  id_req: 0,
  fecha_reg: "",
  id_cliente: 0,
  cliente: "",
  descrip: "",
  zona: "",
  precio_intervalo: "",
  contado_sn: 0,
  financiamiento: "",
  estado: 0,
};

export default {
  components: {
    NuConfirm,
    NuAlert,
    ImageUploadSingle,
    NuDropDown,
    NuListRadio,
    BoxBuscarCliente,
    ClienteModal2,
  },
  props: {},
  setup(props: any, context: any) {
    const nuaRef = ref(null); //puntero al compoente
    const route = useRoute(); //variables de ruta
    const confirmRef = ref<InstanceType<typeof NuConfirm>>();//puntero al componente
    const boxClienteRef = ref<InstanceType<typeof BoxBuscarCliente>>();//puntero al componente
    const clienteModalRef = ref<InstanceType<typeof ClienteModal2>>();

    const IMAGE_API_URL = ref(
      process.env.VUE_APP_API_URL + "image_uploadPhoto"
    );
    const IMAGE_API_AFILES = ref(process.env.VUE_APP_AFILES + "/afiles/");

    const state = reactive({
      modalInsertar: false,
      form0: initialForm,
      roles: [],
      cajas: [],
      //selected_estado:0,
      estados: [
        { value: 0, label: "Inactivo", iconColor: "#5b7282" },
        { value: 1, label: "Activo", iconColor: "#ff9800" },
      ],
    });

    //----------------------------------
    onMounted(() => {
      //console.log(route.params.idd );
      //---cargamos el combo
      cargar_cbo_rol();

      //--fecha de inicio
      const date = new Date();
      const fecha_now = date.toLocaleDateString("en-CA");
      state.form0.fecha_reg = fecha_now;

      let idd: number = Number(route.params.idd);
      let ope = route.params.ope;

      if (ope == "n") {
        limpiar();
      } else {
        editar(idd);
      }
    });
    //----------------------------------
    const limpiar = () => {
      state.form0 = initialForm;
    };
    const handleChangeRadio = () => {};
    //-----------------
    const cargar_cbo_rol = async () => {
      let param = {};

      await axios
        .post(process.env.VUE_APP_API_URL + "cbo_rol", param)
        .then((res) => {
          //if(res.data.length>0){
          state.roles = res.data;
          //}
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    };

    //--------------------------
    const handleCheckboxChange = (e: Event) => {
      let ret = e.target as HTMLInputElement;
      let sol = ret.checked ? 1 : 0;
      state.form0.estado = sol;
    };
    //----cambiamos el valor de true a 1, y false a 0
    /*function handleChangeFormCheck(e){
        console.log(e.target.checked);
        e.target.checked= !e.target.checked;
        form00.recibe_asientos= e.target.checked? 0:1;
    }*/
    //--------------------------
    function editar(idd: number) {
      console.log("editar:" + idd);

      let param = {
        idd: idd,
        ope: "f",
      };
      console.log(param);

      axios
        .post(process.env.VUE_APP_API_URL + "requerimientocrud_store", param)
        .then((res) => {
          console.log(res.data);
          let response = res.data;

          if (res.data !== "") {
            //toast.success("Encontrado");
            Object.assign(state.form0, res.data);
            state.form0.idd = idd;
            state.form0.ope = "e";

            //----
          } else {
            toast.error("No existe el registro", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    }
    //---------------------------------------------
    const aceptar_click = () => {
      console.log("aceptar_click");
      confirmRef.value?.show(0, ".");
    };

    //--esta es la funcion que llama la confirmacion
    const handleConfirm = (id: number) => {
      console.log("handleConfirm: " + id);

      save();
    };

    //-------------------------------------------
    //--- item.value, item.label
    const handleChangeDrop = (item: any) => {
      //requerimos la prop name="?" en cada control
      console.log(item.value + " - " + item.label);
      state.form0.estado = item.value;
    };
    /*---------  ----------------*/
    const btn_search_click=(e: Event)=>{
      console.log("---1---");
      e.preventDefault();
      boxClienteRef.value?.show();
    }
    //-------------------------------------
    const btn_add_click=(e:Event)=>{
      e.preventDefault();
      clienteModalRef.value?.show();
    }
    function handleOkBox(index:number, item:any){
        console.log(item);
        //---buscamos el row al cual tenemos que cambiar
        console.log(item.razon_social);
        
        state.form0.id_cliente = item.id_cliente;
        state.form0.cliente = item.razon_social;
        /*let result = form00.miTabla.filter((row) => {
           row.id_cuenta === item.id_cuenta;
        });*/
    }
    function handleOkBoxCli(item:any){
      state.form0.id_cliente = item.id_cliente;
      state.form0.cliente = item.razon_social;
    }
    //==========================================================================
    const save = () => {
      let param = state.form0;
      console.log(param);

      axios
        .post(process.env.VUE_APP_API_URL + "requerimientocrud_store", param)
        .then((res) => {
          if (res.data.exito === 1) {
            toast.success(res.data.men);
            console.log("res.data");
            console.log(res.data.data);
            //---ahora actualizamos el context API
          } else {
            toast.error(res.data.men);
          }
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    };

    return {
      nuaRef,
      state,
      editar,
      close,
      aceptar_click,
      save,
      handleConfirm,
      confirmRef,

      IMAGE_API_URL,
      IMAGE_API_AFILES,
      cargar_cbo_rol,
      handleChangeDrop,
      handleCheckboxChange,
      handleChangeRadio,
      btn_search_click,
      btn_add_click,
      boxClienteRef,
      clienteModalRef,
      handleOkBox,
      handleOkBoxCli,
    };
  },
};
</script>
<style >
</style>
