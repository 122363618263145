<template>
  <aside class="main-sidebar">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar" style="height: auto;">
      <!-- Sidebar user panel -->
      
      <!-- search form -->
      
      <!-- /.search form -->
      <!-- sidebar menu: : style can be found in sidebar.less -->
      <ul class="sidebar-menu">
        <li class="header">MENU</li>
 

        <li>
          <router-link to="/plan_cr" >
                <i class="far fa-file"></i> <span>Plan de cuentas</span>
          </router-link>
        </li>

        <li>
          <router-link to="/asientos" >
                <i class="far fa-file"></i> <span>Asientos</span>
          </router-link>
        </li>
        
        <li class="treeview" @click="numShow = !numShow ">
          <a href="#">
            <i class="fas fa-scroll"></i>
            <span> Reportes {{ numShow }}</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right fa-xs"></i>
            </span>
          </a>
          <ul  v-show="numShow" class="treeview-menu">
            <li>
              <router-link to="/libro_diario" >
                <i class="far fa-circle"></i> Libro diario
              </router-link>
            </li>
            <li>
              <router-link to="/rep_mayores" >
                <i class="far fa-circle"></i> Mayores
              </router-link>
            </li>
            <li>
              <button @click.prevent="showPdf()">
                Balance sumas y saldos
              </button>
            </li>

          </ul>
        </li>
        
     
        
        
        
      
    
        
       

        <li class="header">LABELS</li>
        <li>
          <router-link to="/main">
            <i class="fas fa-user-cog text-red"></i>
            <span> Mantenimiento</span>
          </router-link>
        </li>

        <!--<li>
          <router-link to="/fileupload">
            <i class="fa fa-upload"></i>
            <span> File Upload</span>
          </router-link>
        </li>-->
        
        
      </ul>
    </section>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  import { ref } from 'vue';
  import { onMounted } from "vue";
  

  export default {
    setup(){
      const store = useStore(); //para vuex
      
      const user = ref({ name:''});
      const numShow = ref(true);
      
      //const user = computed(() => store.getters['auth/getUser']);

      // Pasamos por parámetro una función que realiza la lógica deseada
      onMounted(() => {
        //console.log("onMounted");
        user.value = store.getters['auth/getUser'];
        //console.log(user.value);
      }); 

      const liShow=(n)=>{
        numShow.value =n;
      }
      const ruta_file = computed(()=>{ return VUE_APP_AFILES + user.value.foto_path1 });
      
      const showPdf=()=>{
        //let fileURL = process.env.VUE_APP_API_URL + "plan_printPDF/"+ input;
     
        let fileURL = process.env.VUE_APP_API_URL + "rep_sumas_y_saldos_pdf";
        let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
        window.open(fileURL,'',windowFeatures);
      }

      return{  
        user,
        ruta_file,
        liShow,
        numShow,
        showPdf,
      }
    }/*fin setup*/
  }

</script>
<style scope>
  .sidebar-menu li > a > .pull-right-container > .fa-angle-left {
    width: 8px;
  }
  .label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}
</style>