<template>
  <div>
    <Modal :showModal="isModalVisible" @closeModal="closeModal">
      <template v-slot:title>Cliente</template>
      <!-- body-->
      <form  method='POST'>

        <div class="row">
          <div class="col-md-8">
            <div class='form-group row'>  
              <label htmlFor='id_cita' class='col-md-4 control-label'><b>Cod. Cli</b></label>
              <div class='col-md-4'>
                  <input 
                    name='id_cita' 
                    type='text' 
                    class='form-control' 
                    v-model=" state.form0.id_cliente" 
                    readOnly />
              </div>
            </div>
          </div>
        </div>

        <div class="row  pt-2">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label">Nombre</label>
              <div class="input-group">

                <input 
                    name='razon_social' 
                    type='text' 
                    class='form-control' 
                    v-model="state.form0.razon_social" 
                     />

                <div class="btn-group">
                  
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row  pt-2">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label">Dirección: </label>
                <input 
                  name='dir_fiscal' 
                  type='text' 
                  class="form-control" 
                  v-model = "state.form0.dir_fiscal"
                    />
              
            </div>
          </div>
         
        </div>

        

        <div class="row  pt-2">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Telf.: </label> &nbsp;&nbsp;
                <input 
                    name='tel' 
                    type='text' 
                    class='form-control' 
                    v-model="state.form0.tel" 
                />
              
            </div>
          </div>
          
        </div>

        <div class="row  pt-2">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Obs</label>
              <textarea 
                  name="obs" 
                  class="form-control" 
                  rows="2"
                  v-model="state.form0.obs" 
              ></textarea>
            </div>
          </div>
          
        </div>

        <div class="row  pt-2">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Estado</label>
              <NuListRadio
                options={this.state.estados}
                name="id_estadoc"
                value={this.state.form0.id_estadoc}
                onChange={this.handleChangeForm0}
                isHorizontal = {true}
              />
            </div>
          </div>
        </div>

        <div>
        
        </div>
        </form>
      <!-- -->
      
      <template v-slot:footer>
        <button  class="btn btn-primary" @click="formSave">Guardar</button>
        <button  class="btn btn-default" @click="closeModal">Cancel</button>
      </template>

      
    </Modal>
  </div>
</template>

<script lang="ts"  >
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import Modal from "@/components/Common/Modal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 


const initialForm={
  idd:0,
  ope:'n',
  id_cliente:0,
  razon_social:'',
  dir_fiscal:'',
  contacto:'',
  nro_doc : "",
  tel:"",
  id_tdepto:0,
  ciudad: 0,
  obs:'',
}

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    title: {  type: String,  required: false, },
  },
  setup(_,context:any) {
    
    const isModalVisible = ref(false);
    

    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
      medicos:[],
      horas:[],
      //selected_estado:0,
      estados:[ { value:0, label: "No Confirmado",color:"#008ffb"  },
                { value:1, label: "Confirmado", color:"#ce9320" },
                { value:2, label: "Hora Cancelada", color:"#ff4560" },
                { value:3, label: "Atendido", color:"#00e396" },
              ],
    });


    /*const state = reactive({
      modalInsertar:false,
     
    });*/

    onMounted(() => {});

    /*const closeModal=()=>{

    }*/

    function limpiar(){
      state.form0 = initialForm; 
    }

    const closeModal = () => {
      console.log("evento close");
      isModalVisible.value = false;
      //context.emit("closeModal");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };

    //---------------------------------------------------------------------
    function show() {
      //--evento hacia afuera
      console.log("show in planModal___");
      isModalVisible.value = true;
    }

    //----------------------------------------------
    function editar(idd: number) {
      console.log("editar:" + idd);
      let param = { 
        idd: idd,
        ope:'f'
      }
      console.log(param);
      
      axios.post(process.env.VUE_APP_API_URL  + "clientecrud_store", param).then(res => {
        console.log(res.data);
        let response = res.data;
          
          if(res.data!==null){ 
              //NotificationManager.success("Correcto");
              Object.assign(state.form0, res.data);
              state.form0.idd= idd;
              state.form0.ope= 'e';
              
              //----
              show();
              
          }else{
            toast.error("No existe el registro", {
              position: toast.POSITION.TOP_RIGHT
            });
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    }

    function eliminar(idd: number){
      console.log("eliminar");
      let param = { 
        idd: idd,
        ope:'x'
      }
      console.log(param);
      axios.post(process.env.VUE_APP_API_URL +"clientecrud_store", param).then(res => {
        console.log(res.data);

          if(res.data.exito===1){ 
              //NotificationManager.success("Correcto");
              limpiar();
              closeModal();
              toast.success("Eliminado con exito");
              context.emit("okModal"); //callback
              
          }else{
              toast.error("Error al eliminar registro", {
                position: toast.POSITION.TOP_RIGHT
              });
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    }
    //-------------------------------------------
    const formSave=()=>{
        
        //context.emit("okModal",indexTable.value, item);
        let param = state.form0;
        console.log(param);

        axios.post( process.env.VUE_APP_API_URL +"clientecrud_store", param).then(res => {
            let response = res.data;
            console.log("clientecrud_store");
            console.log(res.data);

            if(response.exito===1){     
              toast.success( response.men);
              //---llamamos a la funcion externa
              closeModal();
              //this.props.okModal(); //callback

              //isModalVisible.value = false;
            }else{
                
              toast.error( response.men);
            }

        }).catch(error => {
            //if(error.response.status && error.response.status===400)
            alert("Bad Request - ");
            console.log(error);
            
        });
    }
    //-------------------------------------------
    
    return {
      isModalVisible,
      state,
      limpiar,
      closeModal,
      show,
      editar,
      eliminar,
      formSave,
      
      
    };
  },
})
</script>
<style >

</style>