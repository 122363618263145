<template>
  <div>
    <br/>
    <div class="container">
      <h1>404 not found</h1>
      <h2>Parece que estás en la página equivocada</h2>
    </div>

  </div>
</template>

<script lang="ts">
//import auth from '../config/auth.js';

import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';

const initialForm={
 idd:0,
 ope:'n',
 e_nom:0,

}

export default defineComponent({
 components: {
   
 },
 props: {
   title: {  type: String,  required: false, },
 },
 setup(_,context:any) {
   
   const isModalVisible = ref(false);
   

   const state = reactive({
     modalInsertar:false,
     form0: initialForm,
   
   });


   /*const state = reactive({
     modalInsertar:false,
    
   });*/



   return {
     isModalVisible,
     state,
     
     
   };
 },
})
</script>
