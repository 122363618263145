
<!-- pie de pagina NosideBar-->
<template>
  <nav id="sidebar" :class="cla" >
    <div class="sidebar_blog_1">
      <div class="sidebar-header">
        <div class="logo_section">
          <a href="index.html"><img class="logo_icon img-responsive" src="images/logo/logo_icon.png" alt="#"></a>
        </div>
      </div>
      <div class="sidebar_user_info">
        <div class="icon_setting"></div>
        <div class="user_profle_side">
          <div class="user_img"><img class="img-responsive" src="~@/assets/img/user_img.jpg" alt="#"></div>
          <div class="user_info">
            <h6>John David</h6>
            <p><span class="online_animation"></span> Online</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar_blog_2">
      <h4>General</h4>
      <ul class="list-unstyled components">

        <li class="active">
          <router-link to="/dashboard">
            <i class="fa fa-dashboard yellow_color"></i> <span>Dashboard</span>
          </router-link>
        </li>        
        

        <li class="active">
          <router-link to="/inmuebles">
            <i class="fa fa-object-group blue2_color"></i> <span>Inmuebles</span>
          </router-link>
        </li> 
        
        <li class="active">
          <router-link to="/clientes">
            <i class="fa fa-object-group blue2_color"></i> <span>Clientes</span>
          </router-link>
        </li> 

        <li class="active">
          <router-link to="/requerimientos">
            <i class="fa fa-object-group blue2_color"></i> <span>Requerimientos</span>
          </router-link>
        </li> 



        

        <ItemP
          text="Tramites"
          :collapsed= "true"
        >
          <ul class="list-unstyled collapse show" id="apps" style="">
              <li><router-link to="/tramites"> <span>Lista de tramites</span></router-link></li>
              <li><router-link to="/tramite_nuevo/0/n"> <span>Agregar tramite</span></router-link></li>
              
          </ul>
        </ItemP>
        


        <li>
          <router-link to="/agenda">
          <i class="fa fa-calendar orange_color"></i> <span>Agenda</span>
          </router-link>
        </li>

    

        
       
        <ItemP
          text="Venta"
          :collapsed= "true"
          icon="fa fa-paper-plane red_color "
        >
          <ul class="list-unstyled collapse show" id="apps" style="">
              <li><router-link to="/ventas"> <span>Lista de ventas</span></router-link></li>
              <li><router-link to="/venta_pos"> <span>Venta Pos</span></router-link></li>
          </ul>
        </ItemP>

        <li>  
          <router-link to="/main">
            <i class="fa fa-clock orange_color"></i> <span>Panel</span>
          </router-link>
        </li>

       
        
        <li style="border-bottom: solid #ff5722 1px;">
          
        </li>
        
        <ItemP
          text="Usuarios"
          :collapsed= "true"
          icon="fa fa-user blue2_color "
        >
          <ul class="list-unstyled collapse show"  style="">
              <li><router-link to="/usuarios"> <span>Lista de usuarios</span></router-link></li>
              <li><router-link to="/usuario_nuevo/0/n"> <span>Agregar usuario</span></router-link></li>
          </ul>

        </ItemP>


        <ItemP
          text="Configuracion"
          :collapsed= "true"
          icon="fa fa-cog yellow_color "
        >
          <ul class="list-unstyled collapse show"  style="">
              <li><router-link to="/globalx"> <span>Empresa</span></router-link></li>
              <li><router-link to="/tipo_clientes"> <span>Tipo de cliente</span></router-link></li>
          </ul>

        </ItemP>



        <ItemP
          text="Mi cuenta"
          :collapsed= "true"
          icon="fa fa-user blue2_color "
        >
          <ul class="list-unstyled collapse show"  style="">
              <li><router-link to="/perfil"> <span>Mi perfil</span></router-link></li>
              <li><router-link to="/clientes"> <span>Cambiar contraseña</span></router-link></li>
          </ul>

        </ItemP>

      </ul>
    </div>
    <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
      <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
    </div>
    <div class="ps__rail-y" style="top: 0px; right: 0px;">
      <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
    </div>
    <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
      <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
    </div>
    <div class="ps__rail-y" style="top: 0px; right: 0px;">
      <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
    </div>
  </nav>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue'

import { onMounted } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import imageUrl from '@/assets/layout_img/user_img.jpg' // => or relative path
import ItemP from '@/components/Pluto/ItemP.vue'


export default {
  props:{
        show:{ type: Boolean, default: false},
  },
  components:{
        ItemP,
  },
  setup(props,context) {
    //const store = useStore(); //para vuex
    //const user = ref({ name:''});
    //const ruta_file = ref('');
    
    const loading = ref(false);

    const cla = computed(()=>{
        return ( props.show==true ? 'ps' : 'ps active');
    });
    return {
      loading,
      imageUrl,
      cla,
    }
  }

}
</script>
<style scoped>

</style>