<template>
	<div class="container-fluid">
		
		<div class="row">
			<div class="col-md-12">
				<h3> Lista de tramites</h3>
			</div>
		</div>
		<!-- form busqueda -->
		<div class="row d-flex justify-content-center">
			<div class=" card_ex" style="padding: 10px; width: 380px; ">
				<form v-on:submit="formSubmit">
					<table  style="width:100%">
						<tbody>

							<tr>
								<td>Código:</td>
								<td colspan="2">
									<input name="param1" type="text" class="form-control value=" v-model="state.param1"></td>
								<td></td>
							</tr>
							<tr>
								<td>Detalle:</td>
								<td colspan="2">
									<input name="param2" type="text" class="form-control value=" v-model="state.param2"></td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td>
									<button name="btn_buscar" 
                          type="submit" 
                          class="btn btn-default">
										<i class="fa fa-binoculars" aria-hidden="true"></i> Buscar
									</button>
								</td>
							</tr>
							<tr>
								<td colspan="3">
									<div id="divres1"></div>
								</td>
							</tr>
						</tbody>
					</table>

				</form>
			</div>
		</div>
		<!-- -->
		<!--<button class="btn btn-success"  @click="showModalc()" >
			<i class="fa fa-plus"></i> Nuevo 
		</button>-->
    <router-link :to="`/tramite_nuevo/0/n`" class="btn btn-success">
			<i class="fa fa-plus"></i> Nuevo
		</router-link>

    
    <NuDataTable
      :serverOptions="serverOptions"
      :columns="columns"
      :items="items"
      
      classes="tabla_grey table-hover"
      class_container="table-round"
      :pagination="true"
      :pageLengthOptions="[5, 10, 20, 50]"
      
      @onChangeServerOptions = "changeServerOptions"

      

      showPrint="false"
      showExportCsv="false"
      showExportExcel="false"
      :loading="isLoadingData"
      >
      <!-- -->
      <template #accion="miProps">
        <strong>
            <!--<button class='btn btn-warning btn-sm' @click="() => onClicButton1(miProps.item)">
                <i class='fa fa-edit'></i>
            </button>-->
            <router-link :to="`/tramite_nuevo/${miProps.item.id_tramite}/e`" class="btn btn-warning btn-sm">
              <i class="fa fa-edit"></i>
            </router-link>
            &nbsp; 
            <button class='btn btn-danger btn-sm' @click="onClicButton2(miProps.item)">
                <i class='fa fa-trash'></i>
            </button>
        </strong>
      </template>
      <!-- -->
    </NuDataTable>
    
    
	
		<!--<pre>{{tabla_param}}</pre>-->
		<!--<pre>-{.{ isModalVisible }.}</pre>-->


  <NuConfirm
      ref="confirmRef"
      title="Confirmación"
      message="¿Esta ud seguro de eliminar?"
      @close="handleClose"
      @confirm="handleConfirm">
  </NuConfirm>
  
	</div>
</template>

<script  lang="ts">
//import auth from '../config/auth.js';

import { ref,reactive, computed, onMounted } from 'vue'

import axios from '@/shared/jwtInterceptor';  //axios interceptado
import NuDataTable from '@/components/nubox/grid/NuDataTable.vue';
import NuLoading from '@/components/nubox/NuLoading.vue'; //~@ referencia a src
import { toast } from 'vue3-toastify'; 
import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 

interface ISort {
  field: string
  dir: string
}

 	 	 	 	 	 	 	
interface ITramite{
  id_tramite: number,
  fecha_ini : any,
  detallet : string,
  costo : number,
  total: number,
  saldo : number,
  id_u_agente: number,
  agente: string,
}

export default {
  components: {
    NuDataTable,
    NuLoading,
    NuConfirm,    
  },
  setup() {
		const isLoadingData = ref(false); //para el grid

		const isloading = ref(false);
		const isModalVisible = ref(false);
    
    const confirmRef = ref<InstanceType<typeof NuConfirm>>();//puntero al componente

    //=================== DATATABLE =========================
    //const items = ref([  {"id_tramite": 1, "fecha_ini": "", "detallet": "", "costo":0, "total": 0, "saldo": 0  }, ]);
    const items = ref<Array<ITramite>>([]);
    const columns=ref([
      { field: "id_tramite", text: "Cod.Tra",  dataType: 'text', align: "left",sorting: true,},
      { field: "fecha_ini", text: "Fecha ini",  dataType: 'text', align: "left",sorting: true,},
      { field: "detallet", text: 'Detalle', dataType: 'text', align: "left", sorting: true, },
      { field: "costo", text: 'costo', dataType: 'text', align: "left", sorting: true, },
      { field: "total", text: 'total', dataType: 'text', align: "left", sorting: true, },
      { field: "saldo", text: 'saldo', dataType: 'text', align: "left", sorting: true, },
      { field: "agente", text: 'agente', dataType: 'text', align: "left", sorting: true, },
      { field: "accion", text: 'Accion', dataType: 'custom', align: "center",sorting: false,   }, 
    ]);
    const serverOptions =reactive({
      pageSettings: {current_page: 1, total: 1, per_page: 10, from: 1,to: 1,},  /*per_page= items_per_page */
      sort: {field: "", dir: "desc",  },
      
    });
    //===========================================================

    //----objeto reactivo para la parte superior, grupo de variables
    const state =reactive({
      param1: '',
      param2 : '',
    });
    // make users variable reactive with the ref() function, una sola variable reactiva
	
  
	//---para pagination
	//const pageOfItems= ref([]);

	var total = computed(() => { return 9;});
	
  //---------------------------------------------------------------
	onMounted(()=>{
		console.log("onMounted");
		//console.log("isModalVisible:"+ isModalVisible.value);

    
    //let filter: Array<ISort> = [];
    
    //antes let filter =[];
    loadData(
      serverOptions.pageSettings.current_page,
      serverOptions.pageSettings.per_page,
      serverOptions.sort,
      state.param1,
      state.param2,
    )
	});
  //---------------------------------------------------------------
	//function loadData(current_page: number, per_page: number, sort: ISort , filter: Array<ISort>){
    function loadData(current_page: number, per_page: number, sort: ISort , param1:string, param2:string){
    
			console.log("loadData");

			isLoadingData.value = true;
      let param = { 
        current_page: current_page,
        per_page: per_page,
        sort: sort.field,
        direction: sort.dir,
        param1,
        param2,
        //filters: JSON.stringify(filter),
      }
      //console.log(param);

			axios.post( process.env.VUE_APP_API_URL + "tramitecrud_get_list2", param)
			.then(res=>{
				//console.log(res.data);
        isLoadingData.value = false;
				items.value = res.data.data;
				
        //console.log("res.data.current_page;",res.data.current_page);
				serverOptions.pageSettings.current_page= res.data.current_page;
        serverOptions.pageSettings.total= res.data.total;
        serverOptions.pageSettings.per_page= res.data.per_page;
				serverOptions.pageSettings.from= res.data.from;
        serverOptions.pageSettings.to= res.data.to;
			})
			.catch(error => {
			  //	this.errorMessage = error.message;
        isLoadingData.value = false;
        items.value= [];
				alert("Hubo un error");
				console.error("There was an error!", error);
			});
      
    }
	/*---------  methods datatable -------------------------------*/
	
  function changeServerOptions(page: number, pageSize: number, sort: ISort){
    console.log("---2---");
    
    serverOptions.pageSettings.current_page= page;
    serverOptions.pageSettings.per_page=  pageSize;

    //let filter: Array<ISort> = [];
    let param1 = state.param1;
    let param2 = state.param2;
    //let filter=[];
    loadData(page, pageSize, sort, param1, param2);
  }
  //-------------------------------------------------------------
	function editRowClick(item: ITramite){
    console.log("editRowClick");
  }
	/*function editRow(){

	}*/
  function showModalc(){
    console.log("showModal");
    
  }
	function closeModal() {
    //clienteModalRef.value.hide();
  }
  function onClicButton1 ( item :ITramite ){
    //console.log("onClicButton1 -- princi");
    //console.log(rowItem);
    //console.log("editar");
    //    console.log(item);
    let idd = item.id_tramite;
    
  }
  
  function onClicButton2 ( item:ITramite ){
    console.log("onClicButton2 -- eliminar");
    console.log( item );
    confirmRef.value?.show( item.id_tramite, item.detallet);

  }
  //--esta es la funcion que llama la confirmacion
  const handleConfirm=(id: number)=>{
      console.log("handleConfirm: " + id);

      eliminar(id);
  }
  const handleClose=()=>{

  }

  function handleOkBox(){

  }
  const formSubmit=(e: Event)=>{
    e.preventDefault();

    let page = serverOptions.pageSettings.current_page;
    let pageSize = serverOptions.pageSettings.per_page;
    let sort = serverOptions.sort;

    let param1 = state.param1;
    let param2 = state.param2;
    //let filter=[];
    loadData(page, pageSize, sort, param1, param2);
  }
  //=========================================================
  function eliminar(idd: number) {
   let param={
     idd: idd,
     ope:'x'
   }
   console.log(param);
   
   axios.post(process.env.VUE_APP_API_URL  + "tramitecrud_store", param).then(res => {
     console.log(res.data);
     let response = res.data;
       
       if(res.data.exito==1){ 
           toast.success(res.data.men);
           //---recargamos el grid
           //let filter: Array<ISort> = [];
            let param1 = state.param1;
            let param2 = state.param2;
            //antes let filter =[];
            loadData(
              serverOptions.pageSettings.current_page,
              serverOptions.pageSettings.per_page,
              serverOptions.sort,
              param1,
              param2,
            )
       }else{
         toast.error("No se pudo eliminar el registro" );
       }

   }).catch(error => {
       alert("---- Existio un error ---");
       console.log(error);
       
   });
 }
	//-------------importante
	return {
    isLoadingData,
		isloading,
		isModalVisible,
		closeModal,
		state,
		editRowClick,
		onClicButton1,
    onClicButton2,
		total,
		handleConfirm,
    handleClose,
		changeServerOptions,
		
		handleOkBox,
    showModalc,
    
    confirmRef,
    items,
    columns,
    serverOptions,
    eliminar,
    formSubmit,
    };
  }
			
}
</script>