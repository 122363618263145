//--esta con plugin de persistencia

import { createStore } from "vuex";
import createPersistedState from 'vuex-plugin-persistedstate';

import authModule from './modules/auth';
import todoModule from './modules/todo';

const store = createStore({
    modules:{
        auth:authModule,
        todo: todoModule
    },
    plugins: [createPersistedState()],
});

export default store;