<template>
  <div class="calendar">
    {{ state.date }}
    {{ state.daysWeek }}
    Calendario
    <div class="calendar__date">
      <span class="calendar__prev" @click="next(-1)">&#5176;</span>
      <h3 class="calendar__month">{{ nowMonth }}</h3>
      <span class="calendar__next" @click="next(1)">&#5171;</span>
    </div>
    <!--   -->
    <div class="calendar__btn" @click="add_event_click">+</div>
    <!--   -->
    <div class="calendar__header">
      <div v-for="day of state.daysWeek" :key="day" class="calendar__day">
        {{ day }}
      </div>
    </div>
    <!--   -->

    <div class="calendar__body">
      <Cell v-for="(day, i) of days" :key="'day_' + i" v-bind="day" 
          @select="click"
          >
          <NuBadge v-for="(badge, i) of getBadges(day)" :key="`badge_${i}`" v-bind="badge" />
      </Cell>
    </div>



  </div>
</template>

<script  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue';
import moment from 'moment'
//import esLocale from "moment/locale/es";
import 'moment/locale/es'  // without this line it didn't work

import Cell from './Cell.vue'
import NuBadge from '@/components/nubox/NuCalendar/NuBadge.vue'

import cUtilFecha from "@/helpers/cUtilFecha";

export default defineComponent({
  components: {
    Cell,
    NuBadge,
  },
  props: {
    events: {  type: Array,  default: () => [] },
    
  },
  setup(props, context ){
    
    //moment.locale('fr', [ esLocale]) // can pass in 'en', 'fr', or 'es'
    
    const state = reactive({
      select: null,
      date: new Date(),
      daysWeek: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
      daysWeek1: ['LUN.', 'MAR.', 'MIÉ.', 'JUE.', 'VIE.', 'SÁB.', 'DOM'],
    });
    
    onMounted(() => {
      
      /*let todaydate = new Date();
      state.date = todaydate;*/
      const hoy = moment();
      state.date = moment().clone();
      //console.log("state.date");
      //console.log(state.date);
      //state.date = moment().clone();
    });
    //------------------------------------------------------------------------
    const nowMonth = computed(() => {
      
      /*const year = state.date.year();
      const month = state.date.format('MMMM');
      return `${month} ${year !== moment().year() ? year : ''}`;*/
      const now= moment();  //fecha actual
      //const d = new Date('2019/06/01');
      const d = new Date(state.date);
      const mes = moment(d).format('MMMM');
      const anyo = moment(d).year();
      return `${mes} ${anyo !== moment(now).year() ? anyo : ''}`;
      //return `${mes}`;
      
      /*const year = state.date.getFullYear();
      const month = state.date.format('MMMM');
      return `${month} ${year !== moment().year() ? year : ''}`;
      */
      
    });
    //-----------------------------------------------------------  
    const days = computed(()=>{
      /*const monthStart = state.date.clone().startOf('month');
      const monthEnd = state.date.clone().endOf('month');
      const weekStart = monthStart.clone().startOf('week');
      return [...Array(monthEnd.diff(weekStart, 'days') >= 35 ? 42 : 35)].map((_, i) => {
        const day = weekStart.clone().add(i, 'day');
        const active = active(day);
        return { day, active, date: state.date };
      });*/
      const d = new Date(state.date);
      const mom = moment(d);
      const monthStart = mom.clone().startOf('month');
      const monthEnd = mom.clone().endOf('month');
      const weekStart = monthStart.clone().startOf('week');

      //-------------
      //--tomamos primer dia de la semana y le sumamos un dia a la vez
      return [...Array(monthEnd.diff(weekStart, 'days') >= 35 ? 42 : 35)].map((_, i) => {

        let color= "#333";// plomo bajito
        let bg_color= "#fff";
        let day = weekStart.clone().add(i, 'day');
        const before = day.isBefore(mom, 'month'); //isBefore = antes
        const after = day.isAfter(mom, 'month');  //isAfter=despues
        if (after || before) {
          color="#b2b2b2";
          bg_color= "#eeeeee";
        }
        
        //const day = weekStart.clone().add(i, 'day');
        const active = active1(day);
        return { day, active, date: state.date, color, bg_color };
      });
      
    });


    //-----------------metodos------------------------------------------
    //--- siguiente mes o anterior mes
    const next=(value)=>{
      console.log("state.date");
      /*const d = new Date(state.date);
      const mom = moment(d).add(value, 'month').clone();
      //console.log(res);
      state.data= mom.toDate();*/

      //state.date = state.date.add(value, 'month').clone();

      state.date = state.date.add(value, 'month').clone();
    }
    //------------------------------------
    function active1(day){
      const select = state.select || moment().clone();
      return select.isSame(day, 'day');
    }
    //------------------------------------
    const click=(day)=> {
      const before = state.date.isBefore(day, 'month');
      const after = state.date.isAfter(day, 'month');
      if (after || before) {
        next(after ? -1 : 1);
      }
      state.select = day;
    }
    //------------------------------------
    const getBadges=({ day })=> {
      //console.log("getBadges");
      //console.log(props.events);
      //return props.events.filter((item) => item.date.isSame(day, 'day'));v
      //console.log(day);
      return props.events.filter((item) => item.date.isSame(day, 'day'));
    }
    //------------------------------
    const add_event_click =(event)=>{
      context.emit('add', event);
    }
    //--------------------------

    return{
      onMounted,
      state,
      nowMonth,
      days,
      next,
      active1,
      click,
      getBadges,      
      add_event_click,
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
.calendar {
  width: 100%;
  min-height: 600px;
  border-radius: 20px;
  background-color: #ffffff;
  user-select: none;
  padding: 5px 5px 10px 5px;
  position: relative;
}
.calendar__date {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px 20px;
}
.calendar__date span {
      height: 25px;
      width: 25px;
      cursor: pointer;
      text-align: center;
}
.calendar__month {
    margin: 0 5px;
    min-width: 110px;
    text-align: center;
    text-transform: uppercase;
    color: #3c32c9;
}
.calendar__header {
    padding: 0 10px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
.calendar__day {
    text-align: center;
}
.calendar__body {
    background-color: #efefef;
    padding: 2px;
    margin: 10px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
  }
  .calendar__btn {
    height: 30px;
    width: 30px;
    background-color: indigo;
    color: ivory;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 20px;
    top: 10px;
    right: 20px;
  }
  .calendar__next{
    
  }
  .calendar__next:hover,
  .calendar__prev:hover{
    background: #ddd;
    color: #3c32c9;
  }
  
  

</style>