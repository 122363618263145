<template>
    <div rowspan="1" colspan="1" class="div_th" :class="classx" @click="sort_click" >
        <slot></slot>
        <!--{{columnName}}-->
        <!--{{ sortKey }}-->
        
        
        <span v-show="direction=='asc'"><i  class="fa fa-arrow-up"></i></span>
        <span v-show="direction=='desc'"><i class="fa fa-arrow-down"></i></span>
    </div>
</template>

<script>

import { ref,reactive } from 'vue'
import { computed } from '@vue/runtime-core';
//import { onMounted } from 'vue'
//import { computed} from 'vue'

export default {
  components: {
  },
  props:{
      columnName:{type: String, default: ''},
      sortKey:{ type: String, default: ''}, 
      
  },
  //setup(props, context) {
  setup(props,context) {
    const direction = ref('');

    //--- Metodos ------------ 
    const classx =  computed(()=>{
        //return sortKey === columnName ? ( direction.value =='DESC' ? 'sorting_asc' : 'sorting_desc') : 'sorting';
        var sol='';
      /*if(props.sortKey==""){
        return '';
      }*/
      if(props.columnName=== props.sortKey){
        /*if(direction.value=='asc'){
          direction.value='desc';
          //sol= 'sorting_asc';
        }else{
          direction.value='';
          //sol = 'sorting_desc';
        }*/
      }else{
        direction.value="";
            //  sol= 'sorting';
          }
      return sol;
    });
    //----cal@se retorna afuera
    function sort_click() {
        console.log("sort_click");
        console.log(props.columnName);
        //--de lo contrario emitimos el evento
        if(props.columnName=== props.sortKey){
          switch(direction.value){
            case "": direction.value='asc'; break;
            case "asc": direction.value='desc'; break;
            case "desc": direction.value=""; break;
            default:
              direction.value="";
          }
        }else{
          direction.value="asc";
        }
        context.emit('sort_click',props.columnName,direction.value);
    }
    return {
        
        sort_click,
        direction,
        classx,
    }
  }
  
}
</script>
<style scoped>
    .div_th {
        padding-left: 8px;
        padding-right: 30px;
        cursor: pointer;
    }

    /*.sorting::after {
        opacity: 0.2;
        content: "\f00d";
    }*/
    .sorting_asc::after{
        position: absolute;
        top: 8px;
        right: 8px;
        display: block;
        font-family: 'FontAwesome';
        opacity: 0.5;
    }
    .sorting_desc::after {
        position: absolute;
        top: 8px;
        right: 8px;
        display: block;
        font-family: 'FontAwesome';
        opacity: 0.5;
    }
</style>