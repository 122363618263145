
<!-- pie de pagina NosideBar-->
<template>
    <div class="topbar"  >
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="full">
                <button type="button" id="sidebarCollapse" class="sidebar_toggle" @click="btn_click()" >
                  <i class="fa fa-bars"></i>
                </button>
                <div class="logo_section">
                    <a href="index.html">
                      <img class="img-responsive" src="~@/assets/img/logo.png" alt="#">
                    </a>
                </div>
                <div class="right_topbar">
                    <div class="icon_info" >
                        <ul>
                            <li><a href="#"><i class="fa fa-bell"></i><span class="badge">2</span></a></li>
                            <li><a href="#"><i class="fa fa-question-circle"></i></a></li>
                            <li><a href="#"><i class="fa fa-envelope"></i><span class="badge">3</span></a></li>
                        </ul>
                        <ul class="user_profile_dd" @click="menu_click()">
                            <li>
                                <a class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                      
                                      <img  :src="imageUrl"  class="img-responsive rounded-circle"   
                                             alt="#">
                                      
                                      <span class="name_user">John David</span>
                                </a>
                                <div class="">
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <a class="dropdown-item" href="profile.html">My Profile</a>
                                    <a class="dropdown-item" href="settings.html">Settings</a>
                                    <a class="dropdown-item" href="help.html">Help</a>
                                    <a class="dropdown-item" href="#"><span>Log Out</span>&nbsp;
                                            <i class="fa fa-sign-out"></i></a>
                                </div>
                              </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, computed,  reactive} from "vue";

import { onMounted } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import imageUrl from '@/assets/layout_img/user_img.jpg' // => or relative path


export default {
  props: {
    fullWidth:{ type: Boolean, default: false},
  },
  setup(props,context) {
    //const store = useStore(); //para vuex
    //const user = ref({ name:''});
    //const ruta_file = ref('');

    const loading = ref(false);
    

    const btn_click=()=>{
      context.emit('onButtonCollapse');
    }

    const menu_click=()=>{
      console.log("show");
    }

    const bc = computed(()=>{
      return ( props.fullWidth ? 'topbar' : 'topbarFull');
    });
    
    return{
        loading,
        imageUrl,
        bc,
        btn_click,
        menu_click,
    }
  }

}
</script>
<style scoped>

</style>