<template>
    <div class="wrapper" style="height: auto; min-height: 100%;">
       <!-- te descubri -->
        <router-view></router-view>
    </div>
</template>

<script>



export default{
    components:{

       

    },
    setup(){
        return{

        }
    }
}
</script>
