<template>
  <div>
    <Modal :showModal="isModalVisible" 
           @closeModal="handleClose"
           style="width:330px" >
        <template v-slot:title>{{ title }}</template>
        <form
            autocomplete="off"
            @keydown="handleKeydown"
            @submit.prevent.stop="handleConfirm"
            class=" rounded-lg overflow-hidden"
        >
          <div class="body-content">
            <span v-if="icon!=''" class="mi-icon" style="width: 64px;">
              <i :class= "icon" class="fa-3x" style="padding-left: 10px;"></i>
            </span>
            
            <span class="text-gray-800 mb-3" style="margin-left: 1rem;">
                {{ message }}
                <br v-if="men!=''"/>
                {{ men }}
            </span>

              
          </div>

          <div class="bg-30  flex">
                <div class="dialog-footer ">
                    
                    
                    
                    <button
                        ref="runButton"
                        dusk="confirm-action-button"
                        :disabled="working"
                        type="submit"
                        class="btn btn-primary btn-sm px-3 mr-3 pl-1"
                        :class="{
                            'btn-primary': action=='primary',
                            'btn-danger': action=='danger',
                        }"
                        style="margin-right:5px"
                    >
                    {{ button.yes}}
                        <!--<loader v-if="working" width="30"></loader>
                        <span v-else>{{ __('Run Action') }}</span>-->
                    </button>
                    &nbsp;
                    <button
                        dusk="cancel-action-button"
                        type="button"
                        @click.prevent="handleClose"
                        class="btn btn-default btn-sm text-80 font-normal h-9 px-3 mr-3 pl-1 "
                        
                    >
                        {{ button.no }}
                    </button>
                    
                </div>
            </div>
        </form>
    </Modal>
  </div>
</template>

<script lang="ts"  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue';
import Modal from "@/components/Common/Modal.vue";

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    title: {  type: String,  required: true, },
    message: {type: String, default:''},
    icon: {type: String, default:''},
    button: { type: Object, default:{  no: 'Cancelar',  yes: 'Aceptar'  }},
    action: { type: String, default:'primary'}
  },
  setup(props, {emit}){
    
    const isModalVisible = ref(false);
    const working = ref(false);
    const idd= ref(0);
    const men = ref('');


    //console.log(props.modalActive);
    const handleKeydown=(e: KeyboardEvent)=>{
      if (['Escape', 'Enter'].indexOf(e.key) !== -1) {
                return
      }
      e.stopPropagation()
    }
    //----------------------------------
    const show=(id:number, mensaje:string)=> {
      //--evento hacia afuera
      console.log("show()");
      idd.value = id;
      men.value = mensaje;
      isModalVisible.value = true;
    }
    //-----------------------------
    const handleClose = () => {
      isModalVisible.value = false;
      console.log("evento close");
      emit("close");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };
    //--------------------------
    const handleConfirm=()=>{
      console.log("handle confirm");
      isModalVisible.value = false;
      emit('confirm',idd.value)
    }
    //--------------------------

    return{
      idd,
      isModalVisible,
      handleKeydown,
      handleConfirm,
      show,
      handleClose,
      working,
      men,
      
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
.body-content{
  color: #495057;
}
.mi-icon{
  
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}
.dialog-footer{
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  /*padding: 0 1.5rem 1.5rem 1.5rem;*/
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
</style>
