<template>
    <div>
        <div class="pagination-row">
            <button class="pagination-button"
                    @click="onChangePage(1)"> Primero </button>
            <!-- previus -->
            <button class="pagination-button"
                    @click="onChangePagePrevius()"><i class="fa fa-chevron-left"></i> </button>
            <!-- botones, y activo-->
            <span v-for="(item,index) in pages_espe" :key="index">
                <button class="pagination-button" :class="activeClass(page, parseInt(item) )"
                    @click="onChangePage( parseInt(item))">{{ parseInt(item) }}</button>
            </span>

            <!--next-->
            <button class="pagination-button"
                @click="onChangePageNext()"> <i class="fa fa-chevron-right"></i> </button>
            <button class="pagination-button"
                @click="onChangePage(parseInt(totalPages)+1)"> Ultimo </button>
        </div>
        <!--suma: {{ suma }}
        <pre>{{ pages_espe }}</pre>-->
        
        
        
    </div>
</template>

<script>
    import { computed, ref } from "vue";
    //import {  computed } from "vue";
    import { onMounted } from 'vue'
    export default {
        name: 'NuPagination',
        props: {
            totalCount:{ type: Number, default: 1},
            pageSize:{ type: Number, default: 1},
            siblingCount:{ type: Number, default: 1},
            currentPage:{ type: Number, default: 1},

        },
        //setup(props, context) {
        setup(props,context) {
            console.log(props);
            //const pages_espe = ref([]);
            const page = ref(parseInt(props.currentPage));
            const totalPages= ref(1); //ref(parseInt(props.last_page));

            onMounted(()=>{
                //calcular_array();
                //computado_jim
            });

            /*const totalPages = computed(() => {
              console.log("totalpages");
              if(props.totalCount >0 && props.pageSize >0){
                  return (Math.ceil(props.totalCount / props.pageSize));
              }
            });
            */

            const suma = computed(() => {
                 return props.currentPage+ totalPages; //props.last_page;
            });
            //---para que se actualizen los calculos cuando cambien los valores
            //-- de mantera reactiva
            const pages_espe = computed(() => {
                let page1 = props.currentPage;
                page.value = props.currentPage;
                //var totalPages = props.totalPages;
                if(props.totalCount >0 && props.pageSize >0){
                  totalPages.value = Math.ceil(props.totalCount / props.pageSize)-1;
                }

                let startpage=(page1 > 4) 
                            ? page1 - 4
                            : 1;
                let endpage= ((totalPages.value - 4) > page1)
                                    ? page1 + 4
                                    : page1;
                const miarr = [];
                
                console.log("currentPage:__"+props.currentPage);
                console.log("totalCount:"+props.totalCount);
                console.log("pageSize:"+props.pageSize);
                console.log("totalPages:" + totalPages.value);
                console.log("startpage:"+startpage);
                console.log("endpage:"+endpage);

                // Only display a portion of the selectable pages
                for (let i = startpage; i <= endpage; i++)
                {
                    miarr.push(i.toString());
                }
                console.log(miarr);
                return miarr;
            });
            
           
            //-- activamos la clase si es el boton (currentPage)
            function activeClass (current_p,index) {
                let sol='';
                if(current_p== index){
                    sol= 'activebtn';
                }
                return sol;
            }

            //----cal@se retorna afuera
            function onChangePage(new_page) {
                //controlamos que no se salga de, los limites
                if(new_page<1 || new_page>(totalPages.value +1)){
                    //se salio
                    console.log("fuera de los limites");
                    return;
                }
                page.value= new_page;
                //--de lo contrario emitimos el evento
                context.emit('onChangePage',new_page);
            }
            function onChangePagePrevius(){
                if((page.value-1)<=0){
                     console.log("fuera de los limites");
                    return;
                }
                //--de lo contrario emitimos el evento
                page.value--;
                context.emit('onChangePage',page.value);
            }
            function onChangePageNext(){
                if((page.value+1)>(totalPages.value +1)){
                     console.log("fuera de los limites");
                    return;
                }
                //--de lo contrario emitimos el evento
                page.value++;
                context.emit('onChangePage',page.value);
            }

            return {
                suma,
                pages_espe,
                page,
                totalPages,
                activeClass,
                onChangePage,
                onChangePagePrevius,
                onChangePageNext

            }
        } 
        

    }
</script>

<style lang="css" scoped>
/*.pagination-button{
    padding:8px;
    margin:2px;
    border-radius:3px;
    font-size:1em;
    cursor: pointer;
}*/
.pagination-button {
    cursor: pointer;
    padding: .3rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.pagination-button:hover {
    /*z-index: 2;*/
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}


.pagination-row{
  padding-top: .25em;
  display: flex;
  float: right;
  text-align: right;
}
.activebtn{
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
</style>