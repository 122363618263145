import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { className: "side-menur" }
const _hoisted_2 = { class: "side-menur" }
const _hoisted_3 = ["name", "id", "value", "checked", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: i,
            class: _normalizeClass(` ${_ctx.horizontalClass}`)
          }, [
            (item.iconColor!=null)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "fa fa-square",
                  style: _normalizeStyle(`color: ${item?.iconColor}`)
                }, null, 4))
              : _createCommentVNode("", true),
            _createTextVNode("  "),
            _createElementVNode("label", {
              class: "",
              style: _normalizeStyle(`cursor: 'pointer'; color: ${item.color!=null? item.color:'#2c3e50'}`)
            }, [
              _createElementVNode("input", {
                type: "radio",
                name: _ctx.name,
                id: item.value,
                value: item.value,
                checked:  String(_ctx.value) === String(item.value) ,
                onChange: (e)=> _ctx.onClickItem(e, item) ,
                style: {"width":"auto"}
              }, null, 40, _hoisted_3),
              _createTextVNode(" " + _toDisplayString(item.label), 1)
            ], 4)
          ], 2))
        }), 128))
      ])
    ])
  ]))
}