import { jwtDecrypt, tokenAlive } from "../../shared/jwtHelper";
import { Commit } from 'vuex';

import axios from "axios";
const state = () => ({
  authData: {
    token: "",
    refreshToken: "",
    tokenExp: "",
    userId: "",
    userName: "",
  },
  user: {
    id_u:0,
    name:"",
    username:"",
    foto_path1:"",
    id_rol:0,
    id_ca:0,
    id_alma:0,
    permisos:{},
  },
  loginStatus: "",
  time:null,
  sessionInterval: null,
  alert: {
    show: false,
    text: '',
    callback: null
  },
});
//----------------------------------------------------------------------------
const getters = { 
  getLoginStatus(state: any) {
    return state.loginStatus;
  },
  getAuthData(state: any) {
    return state.authData;
  },
  isTokenActive(state: any) {
    if (!state.authData.tokenExp) {
      return false;
    }
    return tokenAlive(state.authData.tokenExp);
  },
  getUser(state: any) {
    return state.user;
  }
};
//-----------auth/login------------------------------------------------------------------------
const actions = {
  async login({ commit }: { commit: Commit }, payload:any) {
    //var response = await axios.post(Global.url + "", payload);
    const response = await axios.post(process.env.VUE_APP_API_URL + "login" , payload)
      .catch((err) => {
        console.log(err);
      });
    if (response && response.data) {
      //--jimmy
      console.log("login-user");
      console.log(response.data.user);
      commit("saveUser", response.data.user);
      commit("saveTokenData", response.data);
      commit("setLoginStatu", "success");
      //this.timerSession();
    } else {
      commit("setLoginStatu", "failed");
    }
  },
  //--accion para hacer logout
  logout ({ commit }:  { commit: Commit }) {
    commit('logout')
  },
  //--------------------------------
  timerSession({ commit }: { commit: Commit }){
    //definiendo el tiempo en el que dura una sesion sin actividad
    commit('SET_TIME', process.env.TIME_SESSION_EXPIRED * 60);
    /*
    commit('INIT_INTERVAL', window.setInterval(() => {
      commit('TIME_DECREASE');
      if (this.$store.state.time <= 0) {
        this.$message.warning('Su sesión ha sido cerrada automáticamente después de ' + process.env.TIME_SESSION_EXPIRED + ' minutos de inactividad.', '¡Sesión cerrada!', { timeout: 30000 });
        this.logout();
      }
    }, 15000));
    */
  }
  //---------------

};

const mutations = {
  saveTokenData(state:any, data:any) {
    //console.log("saveTokenData");
    //--------------------------------------------------------
    //console.log("saveTokenData");
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);

    const jwtDecodedValue = jwtDecrypt(data.access_token);
    const newTokenData = {
      token: data.access_token,
      refreshToken: data.refresh_token,
      tokenExp: jwtDecodedValue.exp,
      userId: jwtDecodedValue.sub,
      userName: jwtDecodedValue.username,
    };

    state.authData = newTokenData;
    
  },
  saveUser(state:any,data:any){

    //---guardamos datos de usuario
    console.log("saveUser");
    state.user= data;
    console.log(state.user);
  },
  setLoginStatu(state:any, value:any) {
    state.loginStatus = value;
  },
  // Mutation
  logout(state: any) {
    console.log("logout");
    console.log(state);
    //---limpiamos todo
    
    state.loginStatus = "";
    
    state.authData.token= "";
    state.authData.refreshToken="";
    state.authData.tokenExp="";
    //------ eliminamos local Storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    //---------------
    //location.reload()
  },
  setPermissions (state: any, value: any) {
    state.user.permissions = value;
  },
  SET_TIME(state: any, value: any){
    state.time = value;
  },
  openModal (state:any, id = '') {
    state[`modal${id}`] = true;
  },
  closeModal (state: any, id = '') {
    state[`modal${id}`] = false;
  },
  INIT_INTERVAL(state:any ,interval: any){
    state.sessionInterval = interval;
  },
  TIME_DECREASE(state:any){
    state.time -= 15;
  },
  DESTROY_INTERVAL (state:any) {
    if (state.sessionInterval) {
      window.clearInterval(state.sessionInterval);
    }
    state.sessionInterval = null;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
