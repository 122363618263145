<template>
    <div>

    <!-- -->
    <div class="row justify-content-center">
      <div class="col-sm-6">
        
        <form id="form00" method="POST" action="#">
          <h5>Libro Mayor</h5>
          <div class="card">
            <div class="card-header">
              <h5 style="margin: 0"><strong>Datos para el reporte</strong></h5>

            </div>
            <div class="card-body">

            <!--
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="name">Desde cuenta:</label>

                    <div class="input-group">
                      <input type="text" 
                        class="form-control form-control" 
                        placeholder=""
                        v-model="form00.cuenta_ini"
                        > 
                      
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="name">hasta cuenta:</label>

                    <div class="input-group">
                      <input type="text" 
                        class="form-control form-control" 
                        placeholder=""
                        v-model="form00.cuenta_fin"
                        > 
                      
                    </div>
                  </div>
                </div>
              </div>
            -->
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="name">Desde:</label>

                    <div class="input-group">
                      <input type="date" 
                        class="form-control form-control" 
                        placeholder=""
                        v-model="form00.fecha_ini"
                        > 
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="ccnumber">Hasta:</label>
                    <div class="input-group">
                        <input type="date" 
                            class="form-control form-control" 
                            placeholder=""
                            v-model="form00.fecha_fin"
                        > 

                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                

               

              </div>
            </div>

            <div class="card-footer text-center">
              <!--<button class="btn btn-sm btn-success " type="submit">
                <i class="fa fa-ok"></i> Continuar 
              </button>-->
              
              <button type="button" class="btn btn-sm btn-primary" @click="showPdf()">
                <i class="fa fa-check" aria-hidden="true"></i>
                Aceptar
              </button>
              
            </div>
          </div>
          <div>
            <NuLoading :loading="isloading" text="Cargando..." />
            
          </div>
        </form>

      </div>
    </div>
    <!------------->

    </div>
</template>

<script>
import { ref,reactive, computed, onMounted } from 'vue'
import NuAlert from '@/components/nubox/NuAlert.vue';
import NuLoading from '@/components/nubox/NuLoading.vue'; //~@ referencia a src
import cUtilFecha from '@/helpers/cUtilFecha'

export default {
  components: {
    NuAlert,
    NuLoading,
  },
  props: {},
  setup(props, context){
    const nuaRef = ref(null);//puntero al compoente
    const isloading = ref(false);

    const form00 = reactive({
        cuenta_ini:'',
        cuenta_fin:'',
        fecha_ini:'',
        fecha_fin:'',
    });

    //------------------------
    onMounted(()=>{
        let fecha = cUtilFecha.fechaActualStr();
        form00.fecha_ini=fecha;
        form00.fecha_fin=fecha;
        //console.log(fecha);
    })

    function btn_alert1(){
      nuaRef.value.success('mensaje 1', { title: 'OK' });
    }
   
    const showPdf=()=>{
      //let fileURL = process.env.VUE_APP_API_URL + "plan_printPDF/"+ input;
      let fecha_ini= form00.fecha_ini;
      let fecha_fin= form00.fecha_fin;
      let fileURL = process.env.VUE_APP_API_URL + "rep_mayores_pdf/" + fecha_ini + "/" + fecha_fin;
      let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
      window.open(fileURL,'',windowFeatures);
    }

    return{
      nuaRef,
      form00,
      isloading,
      btn_alert1,
      showPdf,
     
    }
  }
}
</script>
<style >
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  
  }
</style>