<template>
   
  <div style="" v-bind:class="{'open':openSuggestion}" >
    <div class="input-group">
      <input 
          ref="inputRef" 
          class="form-control" 
          type="text" 
          v-model="keySearch"
          @keydown.enter = 'enter'
          @keydown.down = 'down'
          @keydown.up = 'up'
          @input = 'change'
      />
      <button class="input-group-text boton-toogle" @click="tooglePanel">
        <i class="fa fa-search"></i>
      </button>
    </div>

      <div class="autocomplete-panel"
          :style=  "`max-height: 200px; z-index: 1001; position: absolute; 
                  top: ${state.top}px; 
                  min-width: 220px; transform-origin: center top 0px; 
                  `"
          >
        <ul class="dropdown-menu" style="width:100%">
            <li v-for="(suggestion, index) in matches" :key="index"
                v-bind:class="{'active': isActive(index)}"
                class="autocomplete-item"
                @click="suggestionClick(suggestion)"
            >
                <a href="#">{{ suggestion.label }}</a>
            </li>
        </ul>
      </div>
  </div>
  {{ matches }}
    {{ current }}
    {{ selection }}
  </template>

<script lang="ts"  >
import { ref,reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue';
import { watchEffect} from 'vue' 


export interface ItemAuto {
  value: number,
  label: string,
}

export default defineComponent({
  components: {
    
  },
  props: {
    suggestions: {  type: Array as PropType<Array<ItemAuto>>,  required: true, },
    //selection: {type: Number, required:true, twoWay: true},
  },
  setup(props, {emit}){
    
    /*const isModalVisible = ref(false);
    const working = ref(false);
    const idd= ref(0);
    const men = ref('');*/
    const inputRef = ref<HTMLInputElement | null>(null);//puntero al componente

    const open = ref(false);
    const current=ref ( 0);  //posicion
    const selection=ref<ItemAuto>({'value':-1,'label':''});  //seleccion actual
    const keySearch =ref('');

    const state = reactive({
      top: 0,
      left: 0,
    });

    onMounted(()=>{
      /*if(inputRef.value!=null){
        const rect: DOMRect =inputRef.value?.getBoundingClientRect();
        console.log(rect);
        state.top =  rect.top;
        state.left =  rect.left;
      }*/
      
      //state.top = inputRef.current.getBoundingClientRect().top;
      //state.left = inputRef.value?.getBoundingClientRect().left;
    });
    
    watchEffect(()=>{
      if(inputRef.value!=null){
        const rect: DOMRect =inputRef.value?.getBoundingClientRect();
        console.log(rect);
        state.top =  rect.top + rect.height;
        state.left =  rect.left ;
      }
    });

    //--filtramos el array principal en el array de sugerencias
    const matches = computed(() => { 
      return props.suggestions.filter(( item:ItemAuto) => {
          //mayusculas a minusculas y luego comparamos
          return item.label.toUpperCase().indexOf( keySearch.value.toUpperCase() ) >= 0;
      });  
    });

    const openSuggestion = computed(() => { 
      return  keySearch.value !== "" &&
              matches.value.length != 0 &&
              open.value === true; 
    });

    //-------------------methods
    const tooglePanel=()=>{
      open.value = !open.value;
    }
    const enter=()=>{
        //selection.value = matches.value[current.value];
        selection.value = matches.value[current.value];
        keySearch.value = selection.value.label;
        //---get top y left 
        open.value = false;

        //actualiza el v-model="state.selected_item"  que esta afuera
        emit('update:modelValue', selection.value.value);
    };

    const up=()=>{
        if( current.value > 0)
              current.value--;
    }

    const down=()=> {
        if( current.value < matches.value.length - 1)
            current.value++;
    }

    const isActive=(index: number)=> {
        return index === current.value;
    }
    /*const isActive=(item: ItemAuto)=> {
        return item === current.value;
    }*/

    const change=(e:Event)=> {
      const target = e.target as HTMLInputElement;

        if (open.value == false) {
            open.value = true;
            current.value = 0;

            
        }
    }

    /*const suggestionClick=(index:number)=> {
            selection.value = matches.value[index];
            open.value = false;
    };*/
    const suggestionClick=(item:ItemAuto)=> {
            selection.value = item;
            open.value = false;

            //actualiza el v-model="state.selected_item"  que esta afuera
            emit('update:modelValue', selection.value.value);
    };

    
    //--------------------------
    /*const handleConfirm=()=>{
      console.log("handle confirm");
      isModalVisible.value = false;
      emit('confirm',idd.value)
    }*/
    //--------------------------

    return{
      state,
      inputRef,
      open,
      current,
      selection,
      keySearch,
      matches,
      openSuggestion,
      enter,
      up,
      down,
      isActive,
      change,
      suggestionClick,
      tooglePanel,
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
.open ul{
  display: block;
  color: #334155;
}
.open .autocomplete-panel{
  background: #ffffff;
    color: #334155;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.open ul .dropdown-menu{
  color: #1e293b;
    background: #f1f5f9;
}
.open ul .dropdown-menu li{
  color: #1e293b;
    background: #f1f5f9;
}
.open li.autocomplete-item
{
    margin: 2px 0;
    margin-top: 2px;
    padding: 0.5rem 0.75rem;
    border: 0 none;
    color: #334155;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
}
.open li.active{
  /*color: #1e293b;*/
  background: #e2ecf7;
}
.open ul.dropdown-menu > li > a{
  color: #1e293b;
}
.boton-toogle{
  color: #ffffff;
  background: #10b981 !important;
}
</style>
