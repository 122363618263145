import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import store from './store'
import store from './store/index'

import axios from 'axios'
import VueAxios from 'vue-axios'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();



import "@/assets/AdminLTE2/dist/css/AdminLTE.css"
//import "@/assets/AdminLTE2/dist/css/skins/_all-skins.min.css"


import "@/assets/Pluto/style.css"
import "@/assets/Pluto/css/responsive.css"
//import "@/assets/Pluto/css/perfect-scrollbar.css"
//import "@/assets/AdminLTE2/dist/css/skins/_all-skins.min.css"


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap";

import '@/assets/css/bootstrapnubox.css';
import '@/assets/css/tabla_basic.css';
import '@/assets/css/tabla_plomo.css';
import '@/assets/css/tabla_grey.css';
import '@/assets/css/buttons.css';

import 'vue3-toastify/dist/index.css';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type';

import { FocusTrap } from 'focus-trap-vue'

const myApp = createApp(App)

myApp.use(VueAxios, axios)
myApp.use(router)
myApp.use(store)
myApp.component("font-awesome-icon", FontAwesomeIcon)
myApp.component('FocusTrap', FocusTrap)
myApp.mount('#app')
