<template>
    <div class="wrapper" style="height: auto; min-height: 100%;">
        <nav-bar />
        <!--  Left side column. contains the logo and sidebar -->
      
        <!-- Content Wrapper. Contains page content  -->
        <slot/>

        <!--<div class="content-wrapper" style="min-height: 902.8px;">
            <div class="content">sdfsd
                <slot/>
            </div>
        </div>-->
        <!--  /.content-wrapper -->
        <footer-bar />

    </div>
</template>

<script>
import NavBar from "@/components/AdminLte/NavBar";
import FooterBar from "@/components/AdminLte/FooterBar";

export default{
    components:{
        NavBar,
        FooterBar
    }
}
</script>

