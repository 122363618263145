<template>
    <div >
        <!-- loading -->
        <div class="loading-mask1 nu-overlay " v-show="loading" v-bind:class="classes">
          <div class="loading-box">
            <div class="loading-circle"></div>
            <span class="loading-content">&nbsp;{{ text }}</span>
          </div>
        </div>
        <!-- -->
    </div>
</template>

<script>

//import { ref,reactive } from 'vue'
//import { onMounted } from 'vue'
import { computed} from 'vue' 

export default {
  components: {
  },
  props:{
      text:{ type: String, default: "Loading..."},
      dark:{ type: Boolean, default: true},
      loading:{ type: Boolean, default: false},
      classes:{ type: String, default: null},
      background:{ type: String, default: null}, //background-color
      
  },
  //setup(props, context) {
  setup(props) {

    //--- Metodos ------------ 
    const bc = computed(()=>{
      return props.background || ( props.dark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)');
    });
    

    return {
        bc,    
    }
  }
  
}
</script>
<style scoped>

.nu-overlay{
  width: 100%;
  height: 300px;
  display: flex;
  align-self: center;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);

  position: absolute;
}
.loading-box{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #267ed4;
}
.loading-circle {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 3px solid transparent;
  border-left-color: rgb(25, 118, 210);
  
  animation: circleanimation 1.4s linear infinite
}
.loading-content{
  font-size: 14px;
}

@keyframes circleanimation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

    
</style>