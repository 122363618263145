<template>
    <div className="">
        <!-- imageUrl -->
        
        <div className=" previewBlock file-img img-responsive " style=" width: withImage ">
        
          <img  v-if="fileName!==''"
                :alt="fileName"
                :src="imageURL2"
                style="width: 100% "
          />
          <div v-else className="box1" style=" height: 90px " >Sin archivo</div>
        
        </div>
              
        <br/>
        <!--  botones  -->
        <div>
          <input
            className="form-control form-control-lg"
            type="file"
            ref="inputFileRef"
            accept="image/png, image/jpeg, image/gif, image/svg+xml"
            @change="uploadImage_Change"
            style= "display: none"
          />

          <div className="form-group">
            <div className="bootstrap-filestyle input-group">

                <input 
                  v-model="fileName2"
                  type="text" 
                  className="form-control " 
                  placeholder="" 
                   
                  readOnly/>

                <span className="input-group-btn" tabIndex="0">
                  <button 
                    className="btn btn-default btn-flat"
                    @click="chooseFile ">...</button>
                </span>

                <div className="btn-group">
                  <button  @click="cancelar_click"
                    className="btn btn-danger" >
                    <i className="fa fa-trash"></i>
                  </button>      
                </div>
                
            </div>
          </div>
         
         <!-- -->
         <NuLoading text="Cargando...." :loading= "isloading"/>
          <!-- -->
          <span>{{ mensaje }}</span>

        </div>
      </div>
</template>

<script lang="ts" >
import { watchEffect} from 'vue' 
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import NuLoading from '@/components/nubox/NuLoading.vue';

export default defineComponent({
  components: {
    NuLoading,
  },
  props: {
    withImage:{ type: String, default: "auto"},
    fileName: { type: String, default: ""},
    image_API_URL:{ type: String, default: ''},
    image_PATH_FILES:{ type: String, default: ''},
  },
  setup(props: any, context: any){

    
    const inputFileRef = ref<HTMLInputElement | null>(null);//puntero al componente
    
    //const file = ref<File | null>();
    const isloading = ref(false);
    const mensaje = ref('');
    const selectedFile= ref('');
    const imageURL2 =ref('');
    const fileName2 =ref('');
    //------------------------
    // cada que haya cambios en serverOptions, se actualizan los datos
    watchEffect(()=>{
      imageURL2.value =props.image_PATH_FILES + props.fileName;
      fileName2.value = props.fileName;

    });
    //--- Metodos ------------ 
    const cla_error = computed(()=>{
      return (props.estado == 2)? true:false;
    });

    const chooseFile = (e : Event) => {
      e.preventDefault();

      console.log("chooseFile");
      //uploadImage_Click();
      inputFileRef.value!.click();
      //---------------
    }
    //------------------------------------------------
    function cancelar_click(e: Event) {
      e.preventDefault();
      context.emit('cancelImage');
    }

    //------------------------------------------------
    async function uploadImage_Change(event: Event) {
    //---------------------
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (!files || !files[0])
    return;

    //const URL = props.image_API_URL + "image_uploadPhoto";
    const URL = props.image_API_URL;
    const data = new FormData();
    data.append("name", "my-picture");
    //data.append("imageFile", event.target.files[0]);
    data.append("imageFile", files[0]);
    
    const config = {
        headers: {
            "Content-Type": "image/png",
        },
    };

    isloading.value = true;

    axios.post(URL, data, config)
    .then(function (response) {
        console.log(response.data);
        //this.availabilityMessage = response.data.message;
        isloading.value = false;

        if(response.data.exito===0){
          mensaje.value = response.data.mensaje;
        }else{
          //------ correcto ---
          //props.okImage(response.data.filename);
          context.emit("okImage", response.data.filename);
          
        }
        //let aux = process.env.REACT_APP_AFILES + response.data.filename;
        //setImageUrl(aux);
        //setFileName( response.data.filename);
        

    }).catch(function (error) {
        // this.availabilityMessage = false;
        console.log(error);
    });
  }
    //----------
    return{
      cla_error,
      inputFileRef,
      uploadImage_Change,
      chooseFile,
      isloading,
      cancelar_click,
      mensaje,
      selectedFile,
      imageURL2,
      fileName2,
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.file-img {
  margin: 0px auto; /*centrar un img en un div*/
  width: 80%;
  padding: 3px;
  border: 3px solid #d2d6de;
}
.box1 {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*border: 3px dotted #a3a3a3;
  border-radius: 5px;*/
}
</style>
