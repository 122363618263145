

<template>
    <div>
        <!-- -->
        <fielset>
            <legend style="text-align:center;"><strong>Registro de asiento contable</strong></legend>
            <div class="form-group" id="picture" align="center">

            </div>
        </fielset>
        

        <div class="row col-12" style="background-color:#f0e68c; padding:8px">
        <!-- datos maestro-->
        <table>
          <tr>
            <td>Cod. Asiento</td>
            <td><span>{{ form00.id_asiento }}</span> </td>
          </tr>

          <tr>
            <td>Fecha:</td>
            <td><input v-model="form00.fecha" type="date" ></td>
          </tr>

          <tr>
            <td>glosa:</td>
            <td >
              <textarea v-model="form00.glosa" class="form-control1 w-100" >
              </textarea>
            </td>
            <td></td>
            <td></td>
          </tr>

          
          
        </table>

        <!-- tabla -->
        <div style="height:10px" ></div>
        <div style="width:100%;height:400px;background:#ababab;overflow: auto;">
          <table class="tablax tabla_border" style="background:white">
            <thead>
                <tr>
                    <th style="min-width:80px">Cuenta</th>
                    <th style="min-width:80px">Nombre_cuenta</th>
                    <!--<th style="min-width:80px">Descripcion</th>-->
                    <th style="min-width:80px">Debe</th>
                    <th style="min-width:80px">Haber</th>
                    <th style=""></th>
                    
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in form00.miTabla" :key="index">
                  <td>
                    
                    <div style=" display: flex;" >
                        <a href="#" @click.stop.prevent="editRow(index, item)" >
                            <i  class="fa fa-pencil badge "
                                style="font-size:15px;color:orange;cursor:pointer"></i>
                        </a>
                        <span class="topic-name">{{ item.id_cuenta}}</span>
                    </div>
                    
                    <!-- dropdown para los data -->
                    <!--<select v-model="item.id_elem" @change="changeDate($event)">
                      <option v-for="sItem in SELECT_ITEMS" :value="sItem.val" :key="sItem.val" >
                        {{sItem.lbl}}
                      </option>
                    </select>-->
                  </td>
                  <td>
                    {{ item.cuenta }}
                  </td>
                  <!--<td>
                    <input v-model="item.descrip" class="form-control" type="text" />
                  </td>-->
                  <td style="padding: 0px !important;width: 90px;">
                    <input v-model="item.debe" class="form-control text-end" type="text" style="width: 100%;height: 41px;border-radius: 0;" />
                  </td>
                  <td style="padding: 0px !important;width: 90px;">
                    <input v-model="item.haber" class="form-control text-end" type="text" style="width: 100%;height: 41px;border-radius: 0;" />
                  </td>
                  <td>
                    <a href="#" @click.stop.prevent="deleteRow(index)" >
                      <i   class="fas fa-trash-alt" style="font-size:25px;color:#dc3545;cursor:pointer"></i>
                    </a>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
        <!-- -->
        <div>
          Total Debito: <b>{{ totalDebe }}</b> &nbsp;
          Total Credito: <b> {{ totalHaber }}</b>
        </div>
        <!-- botones -->
        <div class="container" style="padding:10px">
            <button type="button" class="btn btn-default"  @click="addRow()"  >
              <i class="fa fa-plus " style="color:#337ab7;cursor:pointer"></i> Agregar
            </button>
          
            <!--<button type="button" class="btn btn-warning"  @click="testBox()"  >
              <i class="fa fa-plus " style="cursor:pointer"></i> testBox
            </button>-->
  
          &nbsp;
          <button type="button" class="btn btn-default float-end " @click="cancel_click()">
            <i class="fas fa-times text-danger"></i>
            Cancelar
          </button>
          &nbsp;
          <button type="button" class="btn btn-default float-end" @click="storeData">
            <i class="fas fa-check text-success "></i>
            Guardar
          </button>
        
        </div>
        <!-- -->
        <!-- mensaje de cargando -->
        <div v-if="loading">
          <img v-show="loading" src="~@/assets/img/anim.gif" />Cargango...
        </div>
        <!-- -->
        <NuAlert  ref="nuaRef"/>
        <BoxBuscarCuenta ref="boxCuentaRef"
            @okModal="handleOkBox"
        />
      </div>
        <div class="row col-12">
            <NuLoading :loading="isloading" text="Cargando..." />
            
        </div>
    </div>
</template>

<script>
import { ref,reactive, computed } from 'vue'
import { onMounted } from 'vue';
//import { computed } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado
import NuLoading from '@/components/nubox/NuLoading.vue'; //~@ referencia a src
import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 
import BoxBuscarCuenta from '@/pages/_box/BoxBuscarCuenta.vue'; //~@ 
import Modal from "@/components/Common/Modal.vue";
import NuAlert from '@/components/nubox/NuAlert.vue';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';
import router from "@/router";
import cUtilFecha from '@/helpers/cUtilFecha'

export default {
  components: {
    NuAlert,
    NuLoading,
    BoxBuscarCuenta,
  },
  props: {},
  setup(props, context){
    const route = useRoute()
    const isloading = ref(false);
    const nuaRef = ref(null);//puntero al compoente
    const boxCuentaRef = ref(null);//puntero al compoente

    const form00 = reactive({
      idd: 0,
      ope: 'n',
      id_asiento:0,
      fecha:'',
      glosa:'',     
      
      miTabla: [
          ///{ id_cuenta: '100', cuenta: 'tae', descrip: '1000', debe:'1', haber:'09'},
          { id_cuenta: '0', cuenta: '', descrip: '', debe:'0', haber:'0'},
          { id_cuenta: '0', cuenta: '', descrip: '', debe:'0', haber:'0'},
          
      ]
    });
    //---esto es para el combo
    // Select tag items
    const SELECT_ITEMS = ref([
      {val:"Ag", lbl:"Ag"},
      {val:"Cu", lbl:"Cu"},
      {val:"H2O", lbl:"H2O"},
      {val:"Pb", lbl:"Pb"},
      {val:"Sn", lbl:"Sn"},
      {val:"Zn", lbl:"Zn"},
    ]);
    
    onMounted(()=>{
      let fecha = cUtilFecha.fechaActualStr();
      form00.fecha = fecha;
        console.log( route.params );
        if(route.params.ope=='n'){
            limpiarTabla();
            form00.idd= 0;
            form00.ope= 'n';
        }else{
            buscarData(route.params.idd);
        }
    })
    const testBox=()=>{
        console.log("testBox");
        boxCuentaRef.value.show();
    }
    //--------------------------------------
    var totalDebe = computed(() => getTotalDebe());
    var totalHaber = computed(() => getTotalHaber());

    //=========================================================================
    //-- calculamos el total de la tabla
    function  getTotalDebe(){ 
      const suma = form00.miTabla.reduce((sum, cur) => sum += parseFloat(cur.debe), 0)   
      return suma;
    }
    function  getTotalHaber(){ 
      const suma = form00.miTabla.reduce((sum, cur) => sum += parseFloat(cur.haber), 0)   
      return suma;
    }
    //--limpia los datos
    function limpiarTabla(){
      form00.miTabla.value = [];
    }
    function addRow(){
      //this.users.push({name:'',email:'',mobno:''})
      console.log("addRow");
      form00.miTabla.push({debe:0, haber:0});
    }

    function deleteRow(index){
      console.log(index);
      form00.miTabla.splice(index,1);             
    }  
    function editRow(index, item){
        console.log("editRow");
        //pedimos otra cuenta
        boxCuentaRef.value.show(index);
    }
    /* id_cuenta, nombre_cuenta */
    function handleOkBox(index, item){
        console.log(item.id_cuenta);
        //---buscamos el row al cual tenemos que cambiar
        let row = form00.miTabla[index];
        row.id_cuenta = item.id_cuenta;
        row.cuenta = item.nombre_cuenta;

        form00.miTabla[index] = row;
        /*let result = form00.miTabla.filter((row) => {
           row.id_cuenta === item.id_cuenta;
        });*/

        //console.log(result);

    }
    //----------------------------------------
    function btn_alert1(){
      nuaRef.value.success('mensaje 1', { title: 'OK' });
    }
   
    function cancel_click() {
        //--evento hacia afuera
        router.push("/asientos");
    }

    //----------------------------------------------
    function buscarData(id){
        console.log("loadData");
        isloading.value =true; //muestra cargando
        
        const param={id_asiento: id}
        console.log(param);
        axios.post( process.env.VUE_APP_API_URL + "asiento_buscar", param)
        .then(res=>{
            isloading.value =false; //quita cargando
            console.log(res.data.data);
            if(res.data.exito==1){
                Object.assign(form00, res.data.form00);
                form00.idd= id;
                form00.ope= 'e';
            }else{
                toast(res.data.men, { autoClose: 1000, }); // ToastOptions
            }
            
        })
        .catch(error => {
            isloading.value = false;//quita cargando
        //	this.errorMessage = error.message;
            alert("Hubo un error");
            console.error("There was an error!", error);
        });
    }

    function storeData(){
      //----------validamos-----------
      console.log("tot debe");
      console.log(totalDebe.value);
      console.log("tot haber");
      console.log(totalHaber.value);

      //if( parseFloat(totalDebe.value)!= parseFloat(totalHaber.value)){
      if(  totalDebe.value!= totalHaber.value){
        toast.error("Asiento no esta cuadrando");
        return;
      }
      this.loading= true;//---ok

      
      
      let tabla_param = JSON.stringify(form00.miTabla); //convierte a json
      console.log(tabla_param);

      let param={
        idd: form00.idd,
        ope: form00.ope,
        id_asiento:form00.idd,
        fecha:form00.fecha,
        glosa:form00.glosa,
        
        miTabla: tabla_param
      }
      console.log(param);

      axios.post(process.env.VUE_APP_API_URL + "asiento_store", param)
      .then(response => {
        this.loading = false;
        //this.datos = response.data

        if(response.data.exito=="1"){
          toast.success(response.data.men);
          router.push("/asientos");
        }else{
          nuaRef.value.error(response.data.men, { title: '' });

        }
      })
      .catch(error => {
        this.loading = false;
        this.errorMessage = error.message;
        console.error("There was an error!", error);
        alert("Error 100: ajax");
      });
      
    }

    return{
      form00,
      totalDebe,
      totalHaber,
      getTotalDebe,
      getTotalHaber,
      nuaRef,
      boxCuentaRef,
      limpiarTabla,
      addRow,
      deleteRow,
      editRow,
      handleOkBox,
      isloading,
      testBox,
      storeData,
     cancel_click,
    }
  }
}
</script>

<style >
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  
  }

</style>