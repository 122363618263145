<template>
    <div class="full_container">
      <div class="inner_container">
        <!-- Sidebar  -->
        <!-- <side-bar :show="isShowSideBar" /> -->
        <!--  right content -->
        <div id="contentFull">
          <!-- topbar -->
          <top-bar @onButtonCollapse="onButtonCollapse" fullWidth:false />
          <div class="middle_cont">
            
            <div class="container-fluid">
              <!-- Content Wrapper. Contains page content  -->
              
              <!-- te descubri -->
              <router-view></router-view>
            </div>

            <!-- footer -->
            <div class="container-fluid">
                <div class="footer">
                  <p>Copyright © 2018 Designed by html.design. All rights reserved.</p>
                </div>
            </div>

          </div>
        </div>

      </div>
    </div>
   
</template>

<script>
import TopBar from "@/components/Pluto/TopBar";
import SideBar from '@/components/Pluto/SideBar.vue';
import { useStore } from 'vuex';
import { ref, reactive} from "vue";

export default{
  components:{
    TopBar,
    SideBar
  },

  setup(props) {
    const isShowSideBar = ref(false);

    const onButtonCollapse =()=>{
      console.log("onButtonCollapse");
      isShowSideBar.value = !isShowSideBar.value;
    }
    return {
      isShowSideBar,    
      onButtonCollapse,
    }
  }
}
</script>