<template>
  <div>
      
        <div class="content5">
          <h1> Agenda </h1>
          <p>..</p>
          <NuCalendar :events="events" 
                    @add="add" />
        
        </div>
  </div>
</template>

<script lang="ts">
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import moment from 'moment'
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import Modal from "@/components/Common/Modal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import NuCalendar from '@/components/nubox/NuCalendar/index.vue';
import { toast } from 'vue3-toastify'; 


const initialForm={
  idd:0,
  ope:'n',
  cn_inmu:0,
  cn_cli:'',
  cn_ventas:'',
  cn_gastos:'',
  
  obs:'',
}
const someDate = moment();
// @ is an alias to /src
export default {
  components: {
    NuCalendar,
  },
  props: {
    msg: String
  },
  setup(props:any, context:any){
    
    
    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
      
    })
    //-----------para el calendar-------------
    //const data= ref(moment.clone());
    const data= ref(someDate.clone() );
    const events = ref( [
      { id: 1, name: 'Event 1', type: 'info', date: data },
      { id: 2, name: 'Event 2', type: 'warning', date: data },
      { id: 3, name: 'Event 3', type: 'error', date: data },
    ]);
    //const tipo=ref(['info', 'warning', 'error']);
    const tipo = ref<Array<string>>(['info', 'warning', 'error']);
    //--------------------------------------------
    onMounted(() => {
      //cargar_globalx();
    });
    //--------------------------------------------
    function limpiar(){
      state.form0 = initialForm; 
    }
    //--------------------------------
    function random(){
      //return tipo.value[Math.floor(Math.random() * tipo.value.length)];
      return tipo.value[1];
    }
    //--------------------------------------------
    const add=(event: Event)=>{
      console.log("add date event");
      //const data = moment().clone();
      var todaydate = new Date();
      const data = todaydate;
      const maxId = Math.max(...events.value.map((item:any) => item.id)) + 1;
      events.value.push({ id: maxId, 
                          name: 'Event ' + maxId, 
                          type: random(), 
                          date: someDate.clone().add(maxId - 10, 'day') } );
    }
    const cargar_globalx = () => {
      let param = { 
        
      }
      
      axios.post(process.env.VUE_APP_API_URL  + "dashboard_get_tot", param)
      .then(res => {
        console.log(res.data);

          if(res.data !== null ){ 
              //NotificationManager.success("Correcto");
              //console.log(res.data);
              Object.assign(state.form0, res.data);
              state.form0.idd= 1;
              state.form0.ope= 'e';
              //console.log(this.state.medicos);
          }else{
              limpiar();
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    };
    return{
      state,
      data,
      events,
      tipo,
      cargar_globalx,
      random,
      add,
      
    }
  }
}


</script>
