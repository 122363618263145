<template>
    <div>
        <!-- -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/main">
                        <i class="fas fa-home"></i>Inicio
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/inmuebles">Tabla inmuebles</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">usuario</li>
            </ol>
        </nav>
        <!-- -->
        {{ JSON.stringify(state.form0) }}
        <!-- -->
        <div>
            <legend style="text-align:center;"><strong>Formulario de Cliente</strong></legend>
            <div class="form-group" id="picture" style="text-align: center;">

            </div>
        </div>
        <br>
        <div class="row ">
            
            <div class="col-md-12" style="display: flex;align-items: center;justify-content: center;">
                <div class="card mb-3">
                    <div class="card-header bg-info">
                        Datos 
                    </div>
                    <div class="card-body">
                        <!---------------------------------------------------->
                        <form id="form0" class="form-horizontal" >
                            <!-- ---------------------------------------------- -->

                            <!--------------------------->
                            <div class="row">
                              <label for="idi" class="col-sm-3 col-form-label">Cod.</label>
                              <div class="col-sm-3 ">
                                
                                      <input  name="id_cliente"  
                                              type="text" 
                                              class="form-control" 
                                              v-model="state.form0.id_cliente"   readonly disabled>
                                
                              </div>

                              <label for="nro_doc" class="col-sm-3 col-form-label">Fecha registro<span
                                            class="text-danger">*</span></label>
                              <div class="col-md-3 " >
                                  
                                        <input 
                                            name='fecha_reg' 
                                            type='date' 
                                            class='form-control' 
                                            v-model="state.form0.fecha_reg" 
                                        />
                                    
                              </div>
                            </div>
                            <!-----------2---------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label">Nombre:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-6">
                                  <input 
                                      name='razon_social' 
                                      type='text' 
                                      class='form-control' 
                                      v-model="state.form0.razon_social" 
                                  />
                                </div>
                            </div>
                            <!-----------2---------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label">Dirección:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-6">
                                  <input 
                                      name='dir_fiscal' 
                                      type='text' 
                                      class='form-control' 
                                      v-model="state.form0.dir_fiscal" 
                                  />
                                </div>
                                
                            </div>
                            <!-----------2---------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label">Telf.:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-3">
                                  <input 
                                      name='tel' 
                                      type='text' 
                                      class='form-control' 
                                      v-model="state.form0.tel" 
                                  />
                                  </div>
                                
                            </div>
                            <!-----------2---------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label">Email.:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-3">
                                  <input 
                                      name='email' 
                                      type='text' 
                                      class='form-control' 
                                      v-model="state.form0.email" 
                                  />
                                  </div>
                                
                            </div>
                            <!-----------2---------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label">Nro de identificación:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-3">
                                  <input 
                                      name='nro_doc' 
                                      type='text' 
                                      class='form-control' 
                                      v-model="state.form0.nro_doc" 
                                  />
                                  </div>
                                
                            </div>
                            <!-------------8-------------->
                            
                            <!-------------7-------------->
                            
                            <!-------------8-------------->
                            <!-------------7-------------->
                            <div class="row  pt-2">
                              <div class="col-md-3">
                                <label class="control-label">Estado:</label>
                              </div>

                              <div class="col-md-5">
                                  <NuListRadio
                                    :options=" state.estados"
                                    name="id_estadoc"
                                    :value= "state.form0.estado"
                                    @change=" handleChangeRadio"
                                    :isHorizontal = "true"
                                  />
                              </div>
                            </div>
                            <!-------------8-------------->
                            
                           
                            <!--------------------------->
                           
                            <!---->
                            <!-------------10--------------->
                            <div class="top_title_azul">
                                <h5 class=""><span>Observaciones</span></h5>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label"> Obs:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                  <textarea
                                    name="obs"
                                    type="text"
                                    class="form-control"
                                    rows="2"
                                    v-model = "state.form0.obs"
                                  ></textarea>
                                </div>
                            </div>
                            <!-------------10--------------->
                            <!-------------10--------------->
                           
                            <!-------------10--------------->   
                            <!---->
                           
                            <!------------6--------------->
                            <!--<div class="form-group row">
                                    <label for="fecha_naci" class="col-sm-3 col-form-label">Fecha de nacimiento:</label>
                                    <div class="col-sm-9">
                                        <input name="fecha_naci" id="fecha_naci"  class="form-control"
                                        type="text" value="" placeholder="dd/mm/yy">
                                    </div>
                                </div>-->

                            <!-------------9--------------->
                            <!--<div class="form-group row">
                                    <label for="email" class="col-sm-3 col-form-label">Email:</label>
                                    <div class="col-sm-9">
                                        <input name="email" id="email" type="text" value="" class="form-control"
                                        >
                                    </div>
                                </div>-->
                            <!-------------10--------------->
                            
                            

                            <!------------------------------------------------------------->
                            <div class="form-group">
                                <div class="text-center">
                                    <button id="btn_save" type="button" class="btn btn-info" @click="aceptar_click">Guardar Datos</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
            <!-- -->
            <div class="row">
                <div class="col-md-12">
                    <NuAlert ref="nuaRef" />
                </div>
            </div>
        </div>
    </div>



    <NuConfirm
      ref="confirmRef"
      title="Confirmación"
      message="¿Esta ud seguro de guardar?"
      icon="fa fa-question-circle"
      @confirm="handleConfirm">
    </NuConfirm>

</template>

<script lang="ts">
import { ref, reactive, computed, onMounted } from "vue";
import { defineComponent, PropType } from "vue";
import axios from "@/shared/jwtInterceptor"; //axios interceptado

import NuDropDown from "@/components/nubox/NuDropDown.vue";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";
import NuConfirm from "@/components/nubox/NuConfirm.vue"; //~@
import NuAlert from "@/components/nubox/NuAlert.vue";
import ImageUploadSingle from "@/components/nubox/ImageUploadSingle.vue";
import NuListRadio from "@/components/nubox/NuListRadio.vue";

const initialForm = {
  idd:0,
  ope:'n',
  id_cliente:0,
  razon_social:'',
  dir_fiscal:'',
  contacto:'',
  nro_doc : "",
  tel:"",
  email:"",
  id_tdepto:0,
  ciudad: 0,
  obs:'',
  fecha_reg:'',
  estado:0,
};

export default {
  components: {
    NuConfirm,
    NuAlert,
    ImageUploadSingle,
    NuDropDown,
    NuListRadio,
  },
  props: {},
  setup(props: any, context: any) {
    const nuaRef = ref(null); //puntero al compoente
    const route = useRoute(); //variables de ruta
    const confirmRef = ref<InstanceType<typeof NuConfirm>>(); //puntero al componente

    const IMAGE_API_URL = ref(
      process.env.VUE_APP_API_URL + "image_uploadPhoto"
    );
    const IMAGE_API_AFILES = ref(process.env.VUE_APP_AFILES + "/afiles/");

    const state = reactive({
      modalInsertar: false,
      form0: initialForm,
      roles: [],
      cajas: [],
      //selected_estado:0,
      estados:[ { value:0, label: "Inactivo", iconColor:"#5b7282"  },
                { value:1, label: "Activo",iconColor:"#ff9800" },
              ],
    });

    onMounted(() => {
      //console.log(route.params.idd );
      //---cargamos el combo
      cargar_cbo_rol();

      //--fecha de inicio
      const date = new Date();
      const fecha_now = date.toLocaleDateString("en-CA");
      state.form0.fecha_reg = fecha_now;

      let idd: number = Number(route.params.idd);
      let ope = route.params.ope;

      //state.form0.idd = idd;
      //state.form0.ope = ope;

      if (ope == "n") {
        limpiar();
      } else {
        editar(idd);
      }
    });
    //----------------------------------
    const limpiar = () => {
      state.form0 = initialForm;
    };
    const handleChangeRadio=()=>{

    }
    //-----------------
    const cargar_cbo_rol = async () => {
      let param = {};

      await axios
        .post(process.env.VUE_APP_API_URL + "cbo_rol", param)
        .then((res) => {
          //if(res.data.length>0){
          state.roles = res.data;
          //}
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    };
    //--------------------------
    const handleCheckboxChange = (e: Event) => {
      let ret = e.target as HTMLInputElement;
      let sol = ret.checked ? 1 : 0;
      state.form0.estado = sol;
    };
    //--------------------------

    //----------------------------------------------
    function editar(idd: number) {
      console.log("editar:" + idd);
      /*let param = { 
        idd: idd,
        ope:'f'
      }*/
      let param = {
        idd: idd,
        ope:'f',
      };
      console.log(param);

      axios
        .post(process.env.VUE_APP_API_URL + "clientecrud_store", param)
        .then((res) => {
          console.log(res.data);
          let response = res.data;

          if (res.data !== "") {
            //NotificationManager.success("Correcto");
            Object.assign(state.form0, res.data);
            state.form0.idd = idd;
            state.form0.ope = "e";

            //----
          } else {
            toast.error("No existe el registro", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    }
    //---------------------------------------------
    const aceptar_click = () => {
      console.log("aceptar_click");
      confirmRef.value?.show(0, ".");
    };

    //--esta es la funcion que llama la confirmacion
    const handleConfirm = (id: number) => {
      console.log("handleConfirm: " + id);

      save();
    };
    //-------------------------------------------
    //--- item.value, item.label
    const handleChangeDrop = (item: any) => {
      //requerimos la prop name="?" en cada control
      console.log(item.value + " - " + item.label);
      state.form0.estado = item.value;
    };
    /*---------  ----------------*/
    //==========================================================================
    const save = () => {
      let param = state.form0;
      console.log(param);

      axios
        .post(process.env.VUE_APP_API_URL + "clientecrud_store", param)
        .then((res) => {
          if (res.data.exito === 1) {
            toast.success(res.data.men);
            console.log("res.data");
            console.log(res.data.data);
            //---ahora actualizamos el context API
          } else {
            toast.error(res.data.men);
          }
        })
        .catch((error) => {
          alert("---- Existio un error ---");
          console.log(error);
        });
    };

    return {
      nuaRef,
      state,
      editar,
      close,
      aceptar_click,
      save,
      handleConfirm,
      confirmRef,

      IMAGE_API_URL,
      IMAGE_API_AFILES,
      cargar_cbo_rol,
      handleChangeDrop,
      handleCheckboxChange,
      handleChangeRadio,
    };
  },
};
</script>
<style >
</style>