<template>
	<div class="container-fluid">
		
		<div class="row">
			<div class="col-md-12">
				<h3> Lista de clientes</h3>
			</div>
		</div>
		<!-- form busqueda -->
		<div class="row d-flex justify-content-center">
			<div class=" card_ex" style="padding: 10px; width: 380px; ">
				<form id="form_buscar" method="POST" action="#">
					<table  style="width:100%">
						<tbody>

							<tr>
								<td>Nro de CI:</td>
								<td colspan="2">
									<input id="param1" name="param1" type="text" class="form-control value=" value=""></td>
								<td></td>
							</tr>
							<tr>
								<td>Razon social</td>
								<td colspan="2">
									<input id="param2" name="param2" type="text" class="form-control value=" value=""></td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td>
									<button id="btn_buscar" name="btn_buscar" type="submit" class="btn btn-default">
										<i class="fa fa-binoculars" aria-hidden="true"></i> Buscar
									</button>
								</td>
							</tr>
							<tr>
								<td colspan="3">
									<div id="divres1"></div>
								</td>
							</tr>
						</tbody>
					</table>

				</form>
			</div>
		</div>
		<!-- -->
		<button class="btn btn-success"  @click="showModalc()" >
			<i class="fa fa-plus"></i> Nuevo 
		</button>

		<NuDataTable
      :serverOptions="serverOptions"
      :columns="columns"
      :items="items"
      
      classes="table table-hover"
      :pagination="true"
      :pageLengthOptions="[5, 10, 20, 50]"
      
      @onChangeServerOptions = "changeServerOptions"

      

      showPrint="false"
      showExportCsv="false"
      showExportExcel="false"
      :loading="isLoadingData"
      >
      <!-- -->
      <template #accion="miProps">
        <strong>
            <button class='btn btn-warning btn-sm' @click="() => onClicButton1(miProps.item)">
                <i class='fa fa-edit'></i>
            </button>
            &nbsp; 
            <button class='btn btn-danger btn-sm' @click="onClicButton2(miProps.item)">
                <i class='fa fa-trash'></i>
            </button>
        </strong>
      </template>
      <!-- -->
    </NuDataTable>
    
	
		<!--<pre>{{tabla_param}}</pre>-->
		<!--<pre>-{.{ isModalVisible }.}</pre>-->

	<ClienteModal2 
    ref="clienteModalRef"
    @okModal="handleOkBox"
	>
	</ClienteModal2>

  <NuConfirm
      ref="confirmRef"
      title="Confirmación"
      message="¿Esta ud seguro de eliminar?"
      @close="handleClose"
      @confirm="handleConfirm">
  </NuConfirm>
  
	</div>
</template>

<script lang="ts">
//import auth from '../config/auth.js';

import { ref,reactive, computed, onMounted } from 'vue'

import axios from '@/shared/jwtInterceptor';  //axios interceptado
import NuDataTable from '@/components/nubox/grid/NuDataTable.vue';
import NuLoading from '@/components/nubox/NuLoading.vue'; //~@ referencia a src
import ClienteModal2 from './ClienteModal2.vue';
import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 

interface ISort {
  field: string
  dir: string
}
interface ICliente{
  id_cliente: number,
  razon_social : string ,
  dir_fiscal : string,
  contacto : string,
  tel: string,
}

export default {
  components: {
    NuDataTable,
    ClienteModal2,
    NuLoading,
    NuConfirm,    
  },
  setup() {
		const isLoadingData = ref(false); //para el grid

		const isloading = ref(false);
		const isModalVisible = ref(false);
    const clienteModalRef = ref<InstanceType<typeof ClienteModal2>>()
    const confirmRef = ref<InstanceType<typeof NuConfirm>>();//puntero al componente

    //=================== DATATABLE =========================
    const items = ref([  {"id_cliente": 103122, "razon_social": "werwer", "dir_fiscal": ""  }, ]);
    const columns=ref([
      { field: "id_cliente", text: "id_cliente.",  dataType: 'text', align: "left",sorting: true,},
      { field: "cod_extra", text: "cod_extra",  dataType: 'text', align: "left",sorting: true,},
      { field: "nit", text: 'nit', dataType: 'text', align: "left", sorting: true, },
      { field: "razon_social", text: 'razon_social', dataType: 'text', align: "left", sorting: true, },
      { field: "dir_fiscal", text: 'dir_fiscal', dataType: 'text', align: "left", sorting: true, },
      { field: "accion", text: 'Accion', dataType: 'custom', align: "center",sorting: false,   }, 
    ]);
    const serverOptions =reactive({
      pageSettings: {current_page: 1, total: 1, per_page: 10, from: 1,to: 1,},  /*per_page= items_per_page */
      sort: {field: "", dir: "desc",  },
      filter: [{column: '',ope:'=', value: ''}],
    });
    //===========================================================

    //----objeto reactivo para la parte superior, grupo de variables
    const form00 = reactive({
      idd:0,
      ope:'',
      id_cliente: 'Albert',
      razon_social : 30,
      dir_fiscal : true,
      contacto :'',
      tel:'',
    });
    // make users variable reactive with the ref() function, una sola variable reactiva
	
  
	//---para pagination
	//const pageOfItems= ref([]);

	var total = computed(() => { return 9;});
	
  //---------------------------------------------------------------
	onMounted(()=>{
		console.log("onMounted");
		//console.log("isModalVisible:"+ isModalVisible.value);

    
    let filter: Array<ISort> = [];
    //antes let filter =[];
    loadData(
      serverOptions.pageSettings.current_page,
      serverOptions.pageSettings.per_page,
      serverOptions.sort,
      filter,
    )
	});
  //---------------------------------------------------------------
	function loadData(current_page: number, per_page: number, sort: ISort , filter: Array<ISort>){
    
			console.log("loadData");

			isLoadingData.value = true;
      let param = { 
        current_page: current_page,
        per_page: per_page,
        sort: sort.field,
        direction: sort.dir,
        filters: JSON.stringify(filter),
      }
      console.log(param);

			axios.post( process.env.VUE_APP_API_URL + "clientecrud_get_list2", param)
			.then(res=>{
				//console.log(res.data);
        isLoadingData.value = false;
				items.value = res.data.data;
				
        //console.log("res.data.current_page;",res.data.current_page);
				serverOptions.pageSettings.current_page= res.data.current_page;
        serverOptions.pageSettings.total= res.data.total;
        serverOptions.pageSettings.per_page= res.data.per_page;
				serverOptions.pageSettings.from= res.data.from;
        serverOptions.pageSettings.to= res.data.to;
			})
			.catch(error => {
			  //	this.errorMessage = error.message;
        isLoadingData.value = false;
        items.value= [];
				alert("Hubo un error");
				console.error("There was an error!", error);
			});
      
    }
	/*---------  methods datatable -------------------------------*/
	
  function changeServerOptions(page: number, pageSize: number, sort: ISort){
    console.log("---2---");
    
    serverOptions.pageSettings.current_page= page;
    serverOptions.pageSettings.per_page=  pageSize;

    let filter: Array<ISort> = [];
    //let filter=[];
    loadData(page, pageSize, sort, filter);
  }
  //-------------------------------------------------------------
	function editRowClick(item: ICliente){
    console.log("editRowClick");
  }
	/*function editRow(){

	}*/
  function showModalc(){
    console.log("showModal");
    clienteModalRef.value?.show();
  }
	function closeModal() {
    //clienteModalRef.value.hide();
  }
  function onClicButton1 ( item :ICliente ){
    //console.log("onClicButton1 -- princi");
    //console.log(rowItem);
    //console.log("editar");
    //    console.log(item);
    let idd = item.id_cliente;
    clienteModalRef.value?.editar(idd);
  }
  
  function onClicButton2 ( item:ICliente ){
    console.log("onClicButton2 -- eliminar");
    console.log( item );
    confirmRef.value?.show( item.id_cliente, item.razon_social);

  }
  //--esta es la funcion que llama la confirmacion
  const handleConfirm=(id: number)=>{
      console.log("handleConfirm: " + id);

      clienteModalRef.value?.eliminar(id);
  }
  const handleClose=()=>{

  }

  function handleOkBox(){

  }
	//-------------importante
	return {
    isLoadingData,
		isloading,
		isModalVisible,
		closeModal,
		form00,
		editRowClick,
		onClicButton1,
    onClicButton2,
		total,
		handleConfirm,
    handleClose,
		changeServerOptions,
		
		handleOkBox,
    showModalc,
    clienteModalRef,
    confirmRef,
    items,
    columns,
    serverOptions,
    };
  }
			
}
</script>