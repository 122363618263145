<template>
    <div>
        <!-- -->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/main">
                        <i class="fas fa-home"></i>Inicio
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/usuarios">Tabla usuario</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">usuario</li>
            </ol>
        </nav>
        <!-- -->
        {{ JSON.stringify(state.form0) }}
        <!-- -->
        <div>
            <legend style="text-align:center;"><strong>Formulario de registro</strong></legend>
            <div class="form-group" id="picture" style="text-align: center;">

            </div>
        </div>
        <br>
        <div class="row col-9">
            <div class="col-md-4" style="float: left;">
                <div card="" class="card portlet light profile-sidebar-portlet bordered">
                    <div class="card-body">
                      <ImageUploadSingle  
                            @okImage= "okImage"
                            @cancelImage= "cancelImage"
                            :image_API_URL="IMAGE_API_URL"
                            :image_PATH_FILES = "IMAGE_API_AFILES"
                            :fileName="state.form0.foto_path1"
                          />
                    </div>
                </div>
            </div>
            <div class="col-md-8" style="float: left;">
                <div class="card mb-3">
                    <div class="card-header bg-info">
                        Datos de usuario
                    </div>
                    <div class="card-body">
                        <!---------------------------------------------------->
                        <form id="form0" >
                            <!-- ---------------------------------------------- -->

                            <div id="divres"></div>

                            <!--------------------------->
                            <div class="form-group row">
                                <label for="id_u" class="col-sm-3 col-form-label">Cod.</label>
                                <div class="col-sm-3">
                                    <input  name="id_u" 
                                            id="id_u" 
                                            type="text" 
                                            class="form-control" 
                                            v-model="state.form0.id_u"   readonly>
                                </div>
                            </div>
                            <!-----------2---------------->
                            <!-------------7-------------->
                            <div class="form-group row">
                                <label for="nro_doc" class="col-sm-3 col-form-label">C.I.<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input 
                                        name='nro_doc' 
                                        type='text' 
                                        class='form-control' 
                                        v-model="state.form0.nro_doc" 
                                    />
                                </div>
                            </div>
                            <!-------------8-------------->
                            <!--------------------------->
                            <div class="form-group row">
                                <label for="nombres" class="col-sm-3 col-form-label"> Nombre:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input  name="nomape" 
                                            type="text" 
                                            class="form-control" 
                                            v-model="state.form0.nomape" 
                                    />
                                </div>
                            </div>

                            <!------------6--------------->
                            <!--<div class="form-group row">
                                    <label for="fecha_naci" class="col-sm-3 col-form-label">Fecha de nacimiento:</label>
                                    <div class="col-sm-9">
                                        <input name="fecha_naci" id="fecha_naci"  class="form-control"
                                        type="text" value="" placeholder="dd/mm/yy">
                                    </div>
                                </div>-->

                            <!-------------9--------------->
                            <!--<div class="form-group row">
                                    <label for="email" class="col-sm-3 col-form-label">Email:</label>
                                    <div class="col-sm-9">
                                        <input name="email" id="email" type="text" value="" class="form-control"
                                        >
                                    </div>
                                </div>-->
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="tel" class="col-sm-3 col-form-label">Telf.:</label>
                                <div class="col-sm-9">
                                    
                                    <input 
                                      name='nro_doc' 
                                      type='text' 
                                      class='form-control' 
                                      v-model="state.form0.tel" 
                                    />
                                </div>
                            </div>

                            <div class="top_title2">
                                <h4 class=""><span>Rol</span></h4>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="username" class="col-sm-3 col-form-label">Rol:<span
                                        class="text-danger"></span></label>
                                <div class="col-sm-9">
                                  <NuDropDown
                                      name="id_rol" 
                                      :options = "state.roles"
                                      :selectValue = "state.form0.id_rol"
                                      @onChange ="handleChangeDrop"
                                  />

                                </div>
                            </div>
                            <!-------------10--------------->
                           
                            <!--------------------------->

                            <div class="top_title2">
                                <h4 class=""><span>Acceso</span></h4>
                            </div>
                            <!-------------10--------------->
                            <div class="form-group row">
                                <label for="Correo" class="col-sm-3 col-form-label">Correo:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input 
                                      name='email' 
                                      type='text' 
                                      class='form-control' 
                                      v-model="state.form0.email" 
                                    />
                                </div>
                            </div>
                            <!-------------10--------------->
                            <div v-if="state.form0.ope=='n'" class="form-group row">
                                <label for="pass" class="col-sm-3 col-form-label">Password:<span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <!---->
                                    <div class="form-label-group input-group">
                                        <input name="pass" id="pass" type="password" value="" class="form-control"
                                            data-valtipo="LN" data-valreq="true">
                                        <div class="input-group-append">
                                            <span class="input-group-text">
                                                <i id="eye" class="fa fa-eye-slash" onclick="showHidePwd();"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <!---->
                                </div>
                            </div>
                            <!--------------------------->
                            <div class="form-group row">
                                <label for="estado_sn" class="col-sm-3 col-form-label">Habilitado:</label>
                                <div class="col-sm-9">
                                    <input 
                                      name="estado"
                                      type="checkbox" 
                                      class="" 
                                      :checked=" state.form0.estado===1 "
                                      @change="handleCheckboxChange"
                                    />
                                </div>

                            </div>

                            <!------------------------------------------------------------->
                            <div class="form-group">
                                <div class="text-center">
                                    <button id="btn_save" type="button" class="btn btn-info" @click="aceptar_click">Guardar Datos</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
            <!-- -->
            <div class="row">
                <div class="col-md-12">
                    <NuAlert ref="nuaRef" />
                </div>
            </div>
        </div>
    </div>

    <NuConfirm
      ref="confirmRef"
      title="Confirmación"
      message="¿Esta ud seguro de guardar?"
      icon="fa fa-question-circle"
      @confirm="handleConfirm">
  </NuConfirm>

</template>

<script lang="ts">
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import Modal from "@/components/Common/Modal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 
import { useRoute } from 'vue-router';
import NuConfirm from '@/components/nubox/NuConfirm.vue'; //~@ 
import NuAlert from '@/components/nubox/NuAlert.vue';
import ImageUploadSingle from "@/components/nubox/ImageUploadSingle.vue";


const initialForm={
  idd:0,
  ope:'n',
  id_u:0,
  nro_doc:'',
  username:'',
  nomape:'',
  email:'',
  dir : "",
  tel:"",
  foto_path1:'',
  id_rol: 0,
  id_ca:'',
  estado:0,
}

export default {
  components: {
    NuConfirm,
    NuAlert,
    ImageUploadSingle,
    NuDropDown,
  },
  props: {},
  setup(props:any, context:any){
    const nuaRef = ref(null); //puntero al compoente
    const route = useRoute(); //variables de ruta
    const confirmRef = ref<InstanceType<typeof NuConfirm>>();//puntero al componente

    const IMAGE_API_URL = ref(process.env.VUE_APP_API_URL + "image_uploadPhoto");
    const IMAGE_API_AFILES = ref(process.env.VUE_APP_AFILES + "/afiles/");

    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
      roles:[],
      cajas:[],
      //selected_estado:0,
      estados:[ { value:0, label: "Deshabilitado",color:"#008ffb"  },
                { value:1, label: "Habilitado", color:"#ce9320" },
              ],
    });

    onMounted(()=>{
      
      //console.log(route.params.idd );
      //---cargamos el combo
      cargar_cbo_rol();
       
      let idd: number = Number(route.params.idd) ;
      let ope = route.params.ope;

      //state.form0.idd = idd;
      //state.form0.ope = ope;
      if(ope==='n'){
        //this.limpiar();
      }else{
        editar(idd);
      }

    })
    //-----------------
    const cargar_cbo_rol = async() => {
      let param = { }
      
      await axios.post(process.env.VUE_APP_API_URL  + "cbo_rol", param).then(res => {
          //if(res.data.length>0){ 
            state.roles = res.data;            
          //}
      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
      });
    };
    //--------------------------
    const handleCheckboxChange=(e: Event)=>{
      let ret = e.target as HTMLInputElement
      let sol = ret.checked? 1:0;
      state.form0.estado= sol;
    }
    //--------------------------
     /************************************************ */
     const okImage = (filename:string ) =>{    
      console.log(filename);
      state.form0.foto_path1 = filename; 
    }
    const cancelImage = () =>{
      state.form0.foto_path1 = ''; 
    }

    //----------------------------------------------
    function editar(idd: number) {
   
      console.log("editar:" + idd);
      let param = { 
        idd: idd,
        ope:'f'
      }
      console.log(param);
      
      axios.post(process.env.VUE_APP_API_URL  + "usuariocrud_store", param).then(res => {
        console.log(res.data);
        let response = res.data;
          
          if(res.data!==""){ 
              //NotificationManager.success("Correcto");
              Object.assign(state.form0, res.data);
              state.form0.idd= idd;
              state.form0.ope= 'e';
              
              //----
              
              
          }else{
            toast.error("No existe el registro", {
              position: toast.POSITION.TOP_RIGHT
            });
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    }
    const aceptar_click = () => {
      console.log("aceptar_click");
      confirmRef.value?.show( 0, ".");
    }

    //--esta es la funcion que llama la confirmacion
    const handleConfirm=(id: number)=>{
        console.log("handleConfirm: " + id);

        save();
    }
    //-------------------------------------------
    //--- item.value, item.label
    const handleChangeDrop=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.id_rol = item.value;
    }
    /*---------  ----------------*/
    
    //----------------------------------------------------------------------
    const save =() =>{

        let param = state.form0;
        console.log(param);
        
        axios.post(process.env.VUE_APP_API_URL  + "usuariocrud_store", param)
        .then(res => {
            if(res.data.exito === 1){ 
                toast.success(res.data.mensaje);
                console.log("res.data")
                console.log(res.data.data);
                //---ahora actualizamos el context API
                
            }else{
                toast.error(res.data.mensaje);
            }

        }).catch(error => {
            alert("---- Existio un error ---");
            console.log(error);
            
        });
      
    }


    return{
      nuaRef,
      state,
      editar,
      close,
      aceptar_click,
      save,
      handleConfirm,
      confirmRef,
      okImage,
      cancelImage,
      IMAGE_API_URL,
      IMAGE_API_AFILES,
      cargar_cbo_rol,
      handleChangeDrop,
      handleCheckboxChange,
    }
  }
}
</script>
<style >
 

</style>