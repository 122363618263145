<template>
  <div class="clearfix">
    <footer class="main-footer">
        <div class="pull-right hidden-xs">
        <b>Version</b> 1.0
        </div>
        Copyright © 2023<strong> Katerine Isabel Romero Choque </strong> Derechos reservados
    </footer>
  </div>
</template>

<script>
    export default {}
</script>
<style scoped>
.main-footer {
    background: #fff;
    padding: 15px;
    color: #444;
    border-top: 1px solid #d2d6de;
}
.main-footer {
    -webkit-transition: -webkit-transform .3s ease-in-out,margin .3s ease-in-out;
    -moz-transition: -moz-transform .3s ease-in-out,margin .3s ease-in-out;
    -o-transition: -o-transform .3s ease-in-out,margin .3s ease-in-out;
    transition: transform .3s ease-in-out,margin .3s ease-in-out;
    margin-left: 230px;
    z-index: 820;
}
</style>