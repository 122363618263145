class cApi {
  
  /*console.log(zfill(324, 2)); // 324
  console.log(zfill(324, 3)); // 324
  console.log(zfill(324, 4)); // 0324
  console.log(zfill(324, 5)); // 00324
  console.log(zfill(324, 10)); // 0000000324
  console.log(zfill(-324, 5)); // -0324
  */
  static zeroFill( number, width )
  {
    width -= number.toString().length;
    if ( width > 0 )
    {
      return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
    }
    return number + ""; // siempre devuelve tipo cadena
  }
 
}

export default cApi;