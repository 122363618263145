<template>
    <div class="wrapper" style="height: auto; min-height: 100%; ">
        <nav-bar />
        <!--  Left side column. contains the logo and sidebar -->
        <side-bar />
        <!-- Content Wrapper. Contains page content  -->
       

        <div class="content-wrapper" style="min-height: 902.8px;">
            <div class="content">
                <!-- te descubri -->
                <router-view></router-view>
            </div>
        </div>
        <!--  /.content-wrapper -->
        <footer-bar />

    </div>
</template>

<script>
import NavBar from "@/components/AdminLte/NavBar";
import SideBar from "@/components/AdminLte/Sidebar";
import FooterBar from "@/components/AdminLte/FooterBar";

export default{
    components:{
        NavBar,
        SideBar,
        FooterBar
    }
}
</script>

