<template>
  <li>
    <a  href="#"  class="dropdown-toggle collapsed" v-bind:class="classes" aria-expanded="true"
        @click="onClick()">
      <i :class="icon"></i> 
      <span>&nbsp;{{ text }}</span>
    </a>
    <!--{{  isShow }}-->

    <div v-show= "isShow==true">
      <slot/>
    </div>
    
  </li>
</template>

<script>
  import { ref,reactive, computed } from 'vue'
  export default {
    components: {
    },
    props:{
        text:{ type: String, default: "..."},
        collapsed:{type:Boolean, default: false},
        classes:{ type: String, default: null},
        background:{ type: String, default: null}, //background-color
        icon:{type:String, default:'fa fa-object-group blue2_color'}
    },
    //setup(props, context) {
    setup(props) {
      
      const isShow = ref(false);
      //--- Metodos ------------ 
      const bc = computed(()=>{
        return props.background || ( props.dark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)');
      });
      
      //----si hace click tenemos que cambiar las clases
      function onClick() {
          console.log("onClick");
          isShow.value  = !isShow.value;
          //--de lo contrario emitimos el evento
          //context.emit('onChangePage',new_page);
      }

      return {
          bc,    
          onClick,
          isShow,
      }
    }
    
  }
</script>

<style scoped>

</style>