import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import store from '../store/index';

//process.env.VUE_APP_API_URL
//import { useRouter } from 'vue-router';

const jwtInterceptor = axios.create({});


jwtInterceptor.interceptors.request.use((config ) => {
    //config.headers.common = config.headers.common || {}
    config.headers.common = "'Content-Type': 'application/json;charset=UTF-8'";//para acentos

    const authData = store.getters["auth/getAuthData"];
    //console.log("authData:", authData.token);
    if (authData == null) {
      return config;
    }

    config.headers['Authorization'] = `bearer ${authData.token}`;
    return config;
  });
  
  jwtInterceptor.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error : AxiosError) => {
      const originalRequest = error.config;
      console.log(error.response);
      //------ Error 401 (no autorizado)
      if (error.response?.status === 401) {
        
        const authData = store.getters["auth/getAuthData"]; 
        const payload = {
          access_token: authData.token,
          refresh_token: authData.refreshToken,
        };
        return Promise.reject("Error 401 (no autorizado)");
        //"http://localhost:3000/auth/refreshtoken",
        /*var response = await axios.post(process.env.VUE_APP_API_URL + "refresh", payload);

        await store.dispatch("auth/saveTokensToStorage", response.data);
        error.config.headers[
          "Authorization"
        ] = `bearer ${response.data.access_token}`;
        return axios(error.config);*/
      } else {
        return Promise.reject(error);
      }
    }
  );
  
  export default jwtInterceptor;
