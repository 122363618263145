<!-- options:[ { value:0, label: "No Confirmado",color:"#008ffb"  },-->
<template>
  <div>
    {{ JSON.stringify(state.checkedArr)    }}


    <div id="menu-container" >
      <div class="chip-panel">
        
        <div  v-for="(item, i) of options" :key="i" 
              class="mi-chip"
              :style="`background-color: ${item.color}`"              
            >
            <div v-if="estaEnArray(item.value.toString())">
              <i  class="fa fa-check"></i>&nbsp;
            </div>

            <span class="mi-chip-text"
            @click="(e) => onClickItem(e, item)"      
            >{{ item.label }}</span>
        
        </div>

        
      </div>
    </div>


  </div>
</template>

<script lang="ts"  >
import { ref, reactive, computed } from 'vue'
import { defineComponent, PropType } from 'vue';
import { onMounted } from 'vue';

//--- lo exportamos para que desde afuera lo puedan declarar
export interface ItemChip {
  value: number,
  label: string,
  color: string,
}

export default defineComponent({
  components: {

  },
  props: {
    name: { type: String, default: 'a' },
    options: { type: Array as PropType<ItemChip[]>, default: [] },
    selection: { type: String, default:'Simple' }, /*Multiple */
    isHorizontal: { type: Boolean, default: false },
  },
  setup(props, { emit }) {

    /*const isModalVisible = ref(false);
    const working = ref(false);
    const idd= ref(0);
    const men = ref('');*/
    
    const pos = ref(0);
    const scrollDiv = ref<HTMLElement | null>(null);//puntero al componente

    const state = reactive({
      checkedArr: <string[]>([]), //los seleccionados
      selectedOption: "",/* lista de seleccioados */
    })

    // Return classes based on whether item is checked ----------------------
    /*const isChecked = (item: ItemChip) => {
      //return (this.state.selectedOption===String(item.value)) ? "checked-item" : "not-checked-item";
      return (props.value === item.value) ? "checked-item" : "not-checked-item";
    }*/

    const horizontalClass = computed(() => {
      //return 'background-color: ' + this.hovering ? this.color: 'red';
      //return (props.isHorizontal == true ) ? "d-inline-block" : "";
      return (props.isHorizontal == true) ? "display_inline" : "";
    });
    /*const setHorizontal =(value: Boolean)=>{
      return (value == true ) ? "d-inline-block" : "";
    }*/


    //------------------------------------------------------------------------
    // Add/Remove checked item from list ------------------------------------
    const onClickItem = (e: Event, item: ItemChip) => {
      const target = e.target as HTMLInputElement;

      target.name = props.name;
      target.value = item.value.toString();

      
      ///----------aqui es la magia
      let updatedList = [...state.checkedArr];

      if(props.selection=='Simple'){
        //-------solo uno seleccionado a la vez
        updatedList= [target.value];

      }else{
        //---------------para multiple
        if (estaEnArray(target.value)==true) {
          console.log("--true--");
          //--lo quitamos de la lista
          //updatedList.splice(state.checkedArr.indexOf( target.value ), 1);
          updatedList.splice(state.checkedArr.indexOf( target.value ), 1);
        } else {
          
          //lo agregamos
          updatedList = [...state.checkedArr, target.value];
        }
      }
      //---actualizamos 
      //setChecked(updatedList);
      state.checkedArr =  updatedList;  
      //this.props.onChange(e);
      emit('change', item);
    };

    const estaEnArray=(cad: string)=>{
      const sol=false;
      //El primer índice del elemento en la matriz; -1 si no se encuentra.
      let ret = state.checkedArr.indexOf( cad );
      if(ret==-1){
        return false;
      }
      //lo encontramos
      return true;
    }
    //--------------------------
    /*const handleConfirm=()=>{
      console.log("handle confirm");
      isModalVisible.value = false;
      emit('confirm',idd.value)
    }*/
    //--------------------------

    return {
      state,
      pos,
      
      onClickItem,
      horizontalClass,
      estaEnArray,

    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
.chip-panel{
  display: flex;
  padding: 4px;
}
.mi-chip{
  align-items: center;
  border: 0 solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
  border-radius: 14px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 13px;
  font-weight: 400;
  height: 28px;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.5em;
  margin: 4px;
  outline: none;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mi-chip.e-primary {
  background: #e3165b;
  border-color: rgba(0,0,0,0);
  color: #fff;
}

.mi-chip .mi-chip-text {
  /*color: #fff;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



</style>
