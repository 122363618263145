<template>
    <div >
        <!-- loading -->
        <div v-if="loading" v-bind:class="classes">
          <img v-show="loading" src="~@/assets/img/anim.gif" />{{ text }}
        </div>
    </div>
</template>

<script>

//import { ref,reactive } from 'vue'
//import { onMounted } from 'vue'
import { computed} from 'vue' 

export default {
  components: {
  },
  props:{
      text:{ type: String, default: "Loading..."},
      loading:{ type: Boolean, default: false},
      classes:{ type: String, default: null},
    
      
  },
  //setup(props, context) {
  setup(props) {

    //--- Metodos ------------ 
    const bc = computed(()=>{
      return props.background || ( props.dark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)');
    });
    

    return {
        bc,    
    }
  }
  
}
</script>
<style scoped>
    
</style>