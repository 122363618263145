<template>
    <div class="hello">
        <section class="content">
            <div class="row">
                <!-- left column -->
                <div class="col-md-4">
                    <!-- general form elements -->
                    <div class="box box-primary">
                        <div class="box-header with-border">
                            <h3 class="box-title">Datos del Empleado</h3>
                        </div><!-- /.box-header -->
                        <!-- form start -->
                        <form role="form" id="frmAcceder" name="frmAcceder">
                            <div class="box-body">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <div class="box box-widget widget-user-2">
                                            <!-- Add the bg color to the header using any of the bg-* classes -->
                                            <div class="widget-user-header bg-yellow">
                                                <div class="widget-user-image">
                                                    <img class="img-circle" :src="ruta_file"
                                                        alt="Usuario">
                                                </div><!-- /.widget-user-image -->
                                                <h3 class="widget-user-username">{{ form00.name}}</h3>
                                                <h5 class="widget-user-desc"> {{ form00.name}}</h5>
                                            </div>
                                            <div class="box-footer no-padding">
                                                <!-- display block es para que de bien-->
                                                <ul class="nav nav-stacked" style="display: block;">
                                                    <li><a href="#"><strong>Documento:</strong>&nbsp; {{form00.nro_doc}}<span
                                                                class="pull-right badge bg-red"><i
                                                                    class="fa fa-fw fa-book"></i></span></a></li>
                                                    <li><a href="#"><strong>Telefono:</strong>&nbsp; {{form00.tel}} <span
                                                                class="pull-right badge bg-blue"><i
                                                                    class="fa fa-fw fa-mobile-phone"></i></span></a>
                                                    </li>
                                                    <li><a href="#"><strong>Direccion:</strong>&nbsp; {{form00.dir}}
                                                            <span class="pull-right badge bg-aqua"><i
                                                                    class="fa fa-fw fa-taxi"></i></span></a></li>
                                                    <li><a href="#"><strong>Email:</strong>&nbsp; {{form00.email}}
                                                            <span class="pull-right badge bg-green"><i
                                                                    class="fa fa-fw fa-envelope"></i></span></a></li>
                                                    <li><a href="#"><strong>Usuario:</strong>&nbsp; {{form00.nom_ape}} <span
                                                                class="pull-right badge bg-aqua"><i
                                                                    class="fa fa-fw fa-user"></i></span></a></li>
                                                </ul>
                                            </div>
                                        </div><!-- /.widget-user -->

                                    </div>
                                </div>
                            </div><!-- /.box-body -->

                            <div class="box-footer">
                                <a type="button" href="ajax/UsuarioAjax.php?op=Salir" class="btn btn-danger">Cerrar
                                    Sesión</a>
                            </div>
                        </form>
                    </div><!-- /.box -->

                </div>
                <!--/.col (left) -->
                <!-- right column -->
                <div class="col-md-8">
                    <!-- Horizontal Form -->
                    <div class="box box-info">
                        <div class="box-header with-border">
                            <h3 class="box-title">Acceso a las Sucursales</h3>
                        </div><!-- /.box-header -->
                        <!-- form start -->

                        <div class="box-body">
                            <div class="box">

                                <div class="box-body no-padding">
                                    <table class="table table-hover" id="tblSucursal">
                                        <tbody>
                                            <tr>
                                                <th>Opcion</th>
                                                <th>Sucursal</th>
                                                <th>Logo</th>

                                            </tr>

                                        </tbody>
                                        <tbody id="Sucursal">
                                            <tr>
                                                <td><button type="button"
                                                        onclick="Acceder(22,'1','1','Carlos Arcila','DNI','Administrador','47715777','Chiclayo 1215','979026684','Files/Empleado/carlos.jpg','Files/Sucursal/suc-mexicodf.png','jcarlos.ad7@gmail.com','admin','Incanato Central S.A.C.','1','1','1','1','0','0','0','0')"
                                                        class="btn btn-info pull-left">Acceder</button></td>
                                                <td>Incanato Central S.A.C.</td>
                                                <td>...</td>
                                            </tr>
                                            <tr>
                                                <td><button type="button"
                                                        onclick="Acceder(24,'1','1','Carlos Arcila','DNI','Administrador','47715777','Chiclayo 1215','979026684','Files/Empleado/carlos.jpg','Files/Sucursal/suc-mexicodf.png','jcarlos.ad7@gmail.com','admin','Incanato Central S.A.C.','1','1','1','1','1','1','1','1')"
                                                        class="btn btn-info pull-left">Acceder</button></td>
                                                <td>Incanato Central S.A.C.</td>
                                                <td>...</td>
                                            </tr>
                                            <tr>
                                                <td><button type="button"
                                                        onclick="Acceder(25,'1','1','Carlos Arcila','DNI','Administrador','47715777','Chiclayo 1215','979026684','Files/Empleado/carlos.jpg','Files/Sucursal/suc-mexicodf.png','jcarlos.ad7@gmail.com','admin','Incanato Central S.A.C.','1','1','1','1','1','1','1','1')"
                                                        class="btn btn-info pull-left">Acceder</button></td>
                                                <td>Incanato Central S.A.C.</td>
                                                <td>...</td>
                                            </tr>
                                            <tr>
                                                <td><button type="button"
                                                        onclick="AccederSuperAdmin(1,'25','1','S','Carlos Arcila','DNI','Chiclayo 1215','979026684','Files/Empleado/carlos.jpg','jcarlos.ad7@gmail.com','admin','1','1','1','1','1','1','1','1','Files/Global/fg.PNG')"
                                                        class="btn btn-success pull-left">Acceder</button></td>
                                                <td>Acceso Administrador</td>
                                                <td></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div><!-- /.box-body -->
                            </div><!-- /.box -->

                        </div><!-- /.box -->
                        <!-- general form elements disabled -->
                    </div>
                    <!--/.col (right) -->
                </div> <!-- /.row -->
            </div>
        </section>

        <!-- mensaje de cargando -->
        <div v-if="loading">
        <img v-show="loading" src="~@/assets/img/anim.gif" />Cargango...
        </div>
        <!-- -->

    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue'

import { onMounted } from 'vue'
import axios from '@/shared/jwtInterceptor';  //axios interceptado

export default {
  props: {
    
  },
  setup(){
    const store = useStore(); //para vuex
    const user = ref({ name:''});
    const ruta_file = ref('');

    const loading = ref(false);
    //----objeto reactivo para la parte superior, grupo de variables
    const form00 = ref({
        nro_doc: '',
        tel: '',
        dir: '',
        email:'',
        nom_ape:'',
    });

    onMounted(()=>{
        user.value = store.getters['auth/getUser'];
        ruta_file.value = process.env.VUE_APP_AFILES + user.value.foto_path1; //ruta de la foto
        var id = user.value.id_u;
		loadData(id);
	});

    //-------------------------------------------------------------------------
    function loadData(id){
        console.log("loadData");
        loading.value =true; //muestra cargando
        //----------------
        
        const param={id_u:id}
        axios.post( process.env.VUE_APP_API_URL + "usuario_perfil", param)
        .then(res=>{
            loading.value =false; //quita cargando
            
            console.log(res.data);

            form00.value = res.data;
            
        })
        .catch(error => {
            loading.value = false;//quita cargando
            alert("Hubo un error");
            console.error("There was an error!", error);
        });
    }
    return{
        loading,
        form00,
        ruta_file,
        loadData
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
