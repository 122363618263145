<template>
  <div>
      
        <div class="content5">
          <h1> Bienvenido al sistema</h1>
          <p>Nidera</p>
          
          <div class="row column1">
                        <div class="col-md-6 col-lg-3">
                           <div class="full counter_section margin_bottom_30">
                              <div class="couter_icon">
                                 <div> 
                                    <i class="fa fa-user yellow_color fa-3x" ></i>
                                 </div>
                              </div>
                              <div class="counter_no">
                                <router-link to="clientes">
                                 <div>
                                    <p class="total_no">{{ state.form0.cn_cli }}</p>
                                    <p class="head_couter">Clientes</p>
                                 </div>
                                </router-link>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="full counter_section margin_bottom_30">
                              <div class="couter_icon">
                                 <div> 
                                    <i class="fa fa-home blue1_color fa-3x"></i>
                                 </div>
                              </div>
                              <div class="counter_no">
                                <router-link to="inmuebles">
                                 <div>
                                    <p class="total_no">{{ state.form0.cn_inmu }}</p>
                                    <p class="head_couter">Inmuebles</p>
                                 </div>
                                </router-link>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="full counter_section margin_bottom_30">
                              <div class="couter_icon">
                                 <div> 
                                    <i class="fa fa-cloud-download green_color fa-3x"></i>
                                 </div>
                              </div>
                              <div class="counter_no">
                                <router-link to="ventas">
                                 <div>
                                    <p class="total_no">{{ state.form0.cn_ventas }}</p>
                                    <p class="head_couter">Ventas</p>
                                 </div>
                                </router-link>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="full counter_section margin_bottom_30">
                              <div class="couter_icon">
                                 <div> 
                                    <i class="fa fa-list-alt red_color fa-3x"></i>
                                 </div>
                              </div>
                              <div class="counter_no">
                                 <div>
                                    <p class="total_no">{{ state.form0.cn_gastos }}</p>
                                    <p class="head_couter">Gastos</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
        </div>
      
  </div>
</template>

<script lang="ts">
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';

import axios from '@/shared/jwtInterceptor';  //axios interceptado
import ImageUploadSingle from "@/components/nubox/ImageUploadSingle.vue";
import Modal from "@/components/Common/Modal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import { toast } from 'vue3-toastify'; 

const initialForm={
  idd:0,
  ope:'n',
  cn_inmu:0,
  cn_cli:'',
  cn_ventas:'',
  cn_gastos:'',
  
  obs:'',
}
// @ is an alias to /src
export default {
  props: {
    msg: String
  },
  setup(){
    
    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
    })
    //--------------------------------------------
    onMounted(() => {
      cargar_globalx();
    });
    //--------------------------------------------
    function limpiar(){
      state.form0 = initialForm; 
    }
    //--------------------------------------------

    const cargar_globalx = () => {
      let param = { 
        
      }
      
      axios.post(process.env.VUE_APP_API_URL  + "dashboard_get_tot", param)
      .then(res => {
        console.log(res.data);

          if(res.data !== null ){ 
              //NotificationManager.success("Correcto");
              //console.log(res.data);
              Object.assign(state.form0, res.data);
              state.form0.idd= 1;
              state.form0.ope= 'e';
              //console.log(this.state.medicos);
          }else{
              limpiar();
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    };
    return{
      state,
      cargar_globalx,
    }
  }
}


</script>
