<template>
  <div>
    <Modal :showModal="isModalVisible" @closeModal="closeModal">
      <template v-slot:title>Cliente</template>
      <!-- body-->
     
      <form  method='POST'>

        <div class="row">
            <label htmlFor='id_cita' class='col-md-3 control-label'><b>Cod. Cli</b></label>
            <div class='col-md-9'>
                  <input 
                    name='id_cliente' 
                    type='text' 
                    class='form-control' 
                    v-model=" state.form0.id_cliente" 
                    readonly disabled  />
            </div>
        </div>

        <div class="form-group row">
            <label for="username" class="col-sm-3 col-form-label">Estado:<span
                    class="text-danger"></span></label>
            <div class="col-sm-4">
              <NuDropDown
                  name="total" 
                  :options = "state.ctipos"
                  :selectValue = "state.form0.id_ctipo"
                  @onChange ="handleChangeDrop"
              />

            </div>
        </div>

        <div class="row  pt-2">
              <label class="col-sm-3 col-form-label">Nombre:</label>
              <div class="col-md-9">

                <input 
                    name='razon_social' 
                    type='text' 
                    class='form-control' 
                    v-model="state.form0.razon_social" 
                     />

                
              </div>
        </div>


        <div class="row  pt-2">
          <label class="col-sm-3 col-form-label">Dirección: </label>
          <div class="col-md-9">
              
                <input 
                  name='dir_fiscal' 
                  type='text' 
                  class="form-control" 
                  v-model = "state.form0.dir_fiscal"
                />
          </div>
        </div>

        

        <div class="row  pt-2">
          <label class="col-sm-3 col-form-label">Telefonos: </label>
          <div class="col-md-9">
            <input 
                name='tel' 
                type='text' 
                class='form-control' 
                v-model="state.form0.tel" 
            />
          </div>
        </div>
          

        <div class="row  pt-2">
          <div class="col-md-12">
            
              <label class="control-label">Obs.:</label>
              <textarea 
                  name="obs" 
                  class="form-control" 
                  rows="2"
                  v-model="state.form0.obs" 
              ></textarea>
            
          </div>
          
        </div>

        <div class="row  pt-2">
          
          
              <label class="col-sm-3 col-form-label">Estado:</label>
              <div class="col-md-9" style="display: flex;align-items: center;">
                <NuListRadio
                  :options=" state.estados"
                  name="estado"
                  :value= "state.form0.estado"
                  @change=" handleChangeRadio"
                  :isHorizontal = "true"
                />
              </div>
            
        </div>

        <div>
        
        </div>
        </form>
      <!-- -->
      
      <template v-slot:footer>
        <button  class="btn btn-primary" @click="formSave">Guardar</button>
        <button  class="btn btn-default" @click="closeModal">Cancel</button>
      </template>

      
    </Modal>
  </div>
</template>

<script lang="ts"  >
import { ref,reactive, computed, onMounted } from 'vue'
import { defineComponent, PropType } from 'vue';
import axios from '@/shared/jwtInterceptor';  //axios interceptado

import Modal from "@/components/Common/Modal.vue";
import NuDropDown from '@/components/nubox/NuDropDown.vue';
import NuListRadio from '@/components/nubox/NuListRadio.vue'; 
import { toast } from 'vue3-toastify'; 


const initialForm={
  idd:0,
  ope:'n',
  id_ctipo:0,
  id_cliente:0,
  razon_social:'',
  dir_fiscal:'',
  contacto:'',
  nro_doc : "",
  tel:"",
  id_tdepto:0,
  ciudad: 0,
  obs:'',
  estado:0,
}

export default defineComponent({
  components: {
    Modal,
    NuListRadio,
    NuDropDown,
  },
  props: {
    title: {  type: String,  required: false, },
  },
  setup(_,context:any) {
    
    const isModalVisible = ref(false);
    

    const state = reactive({
      modalInsertar:false,
      form0: initialForm,
      ctipos:[],
      horas:[],
      estados:[ { value:0, label: "No activo",iconColor:"#5b7282"  },
                { value:1, label: "Activo",iconColor:"#ff9800" },
              ],
      //selected_estado:0,
      /*estados:[ { value:0, label: "No Confirmado",iconColor:"#008ffb"  },
                { value:1, label: "Confirmado", color:"#2c3e50",iconColor:"#ce9320" },
                { value:2, label: "Hora Cancelada", color:"#2c3e50",iconColor:"#ff4560" },
                { value:3, label: "Atendido", color:"#2c3e50",iconColor:"#00e396" },
              ],*/
    });


    /*const state = reactive({
      modalInsertar:false,
     
    });*/

    onMounted(() => {
      cargar_cbo_ctipo();
    });

    /*const closeModal=()=>{

    }*/

    function limpiar(){
      state.form0 = initialForm; 
    }
    //-----------------
    const cargar_cbo_ctipo = async() => {
      let param = { }
      
      await axios.post(process.env.VUE_APP_API_URL  + "cbo_ctipos", param).then(res => {
          //if(res.data.length>0){ 
            state.ctipos = res.data;            
          //}
      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
      });
    };
    //--------------------------
    const closeModal = () => {
      console.log("evento close");
      isModalVisible.value = false;
      //context.emit("closeModal");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };
    const handleChangeRadio=(e:Event)=>{
      let ret = e.target as HTMLInputElement;
      //let sol = ret.checked ? 1 : 0;
      state.form0.estado = parseInt( ret.value);
    }
    //--- item.value, item.label
    const handleChangeDrop=(item:any)=>{
        //requerimos la prop name="?" en cada control
        console.log(item.value + " - " +item.label);
        state.form0.id_ctipo = item.value;
    }
    //---------------------------------------------------------------------
    function show() {
      //--evento hacia afuera
      console.log("show in planModal___");
      isModalVisible.value = true;
    }

    //----------------------------------------------
    function editar(idd: number) {
      console.log("editar:" + idd);
      let param = { 
        idd: idd,
        ope:'f'
      }
      console.log(param);
      
      axios.post(process.env.VUE_APP_API_URL  + "clientecrud_store", param).then(res => {
        console.log(res.data);
        let response = res.data;
          
          if(res.data!==null){ 
              //NotificationManager.success("Correcto");
              Object.assign(state.form0, res.data);
              state.form0.idd= idd;
              state.form0.ope= 'e';
              
              //----
              show();
              
          }else{
            toast.error("No existe el registro", {
              position: toast.POSITION.TOP_RIGHT
            });
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    }

    function eliminar(idd: number){
      console.log("eliminar");
      let param = { 
        idd: idd,
        ope:'x'
      }
      console.log(param);
      axios.post(process.env.VUE_APP_API_URL +"clientecrud_store", param).then(res => {
        console.log(res.data);

          if(res.data.exito===1){ 
              //NotificationManager.success("Correcto");
              limpiar();
              closeModal();
              toast.success("Eliminado con exito");
              context.emit("okModal"); //callback
              
          }else{
              toast.error("Error al eliminar registro", {
                position: toast.POSITION.TOP_RIGHT
              });
          }

      }).catch(error => {
          alert("---- Existio un error ---");
          console.log(error);
          
      });
    }
    //-------------------------------------------
    const formSave=()=>{
        
        //context.emit("okModal",indexTable.value, item);
        let param = state.form0;
        console.log(param);

        axios.post( process.env.VUE_APP_API_URL +"clientecrud_store", param).then(res => {
            let response = res.data;
            console.log("clientecrud_store");
            console.log(res.data);

            if(response.exito===1){     
              toast.success( response.men);
              //---llamamos a la funcion externa
              closeModal();
              //this.props.okModal(); //callback

              //isModalVisible.value = false;
              context.emit("okModal",state.form0);
            }else{
                
              toast.error( response.men);
            }

        }).catch(error => {
            //if(error.response.status && error.response.status===400)
            alert("Bad Request - ");
            console.log(error);
            
        });
    }
    //-------------------------------------------
    
    return {
      isModalVisible,
      state,
      limpiar,
      closeModal,
      show,
      editar,
      eliminar,
      formSave,
      handleChangeRadio,
      handleChangeDrop,
      cargar_cbo_ctipo,
      
    };
  },
})
</script>
<style >

</style>