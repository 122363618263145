<template>
    <div class="fondo1" style="height: auto; min-height: 100%;">
        <nav-bar />
               
        <!-- Content Wrapper. Contains page content  -->
        <!-- te descubri -->
        <router-view></router-view>

        <!--<div class="content-wrapper" style="min-height: 902.8px;">
            <div class="content">sdfsd
                <slot/>
            </div>
        </div>-->
        <!--  /.content-wrapper -->
        <footer-bar2 />

    </div>
</template>

<script>
import NavBar from "@/components/AdminLte/NavBar";

import FooterBar2 from "@/components/AdminLte/FooterBar2";

export default{
    components:{
        NavBar,
        FooterBar2
    }
}
</script>

<style scope>
    .fondo1{
        background-color: #ecf0f5;
    }
</style>