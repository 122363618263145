import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"`text-align":"${column.align}`"}
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle($setup.newStyle)
  }, [
    ($props.column.dataType=='text')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.itemRow[$props.column.field]), 1))
      : _createCommentVNode("", true),
    ($props.column.dataType=='number')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.itemRow[$props.column.field]), 1))
      : _createCommentVNode("", true),
    ($props.column.dataType=='custom')
      ? (_openBlock(), _createElementBlock("span", _hoisted_3))
      : _createCommentVNode("", true)
  ], 4))
}