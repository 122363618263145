<template>
  <div class="fondo1" style="height:800px">

    <div class="container ">
     <br>
     <br>
     <br>
     <div class="row justify-content-center">
       <div class="col-sm-5">
         <!-- Authentication card start -->
         <div class="card ">
           <div class="card-body ">
             <div class="col-md-12">
               <h3 class="text-left txt-primary">Bienvenido</h3>
             </div>
             <div class="text-center"> 
               <img :src="logo1" alt="logo" height="100">
             </div>

             <!-- <p class="login-box-msg">Inicie su sesion</p>-->
             <!-- formulario --->
             <form name="form" id="form" v-on:submit.prevent="procesar();" action="">
               

               <div class="input-group mb-3">
                 <input v-model="form00.email" type="text" class="form-control" placeholder="">

                 <div class="input-group-append">
                   <div class="input-group-text">
                     <span class="fa fa-envelope"></span>
                   </div>
                 </div>
               </div>

               <div class="input-group mb-3">

                 <input v-model="form00.password" type="password" class="form-control" placeholder="">
                 <div class="input-group-append">
                   <div class="input-group-text">
                     <span class="fa fa-lock"></span>
                   </div>
                 </div>
               </div>
               <!--<small class="form-text text-muted">Forgot your password? <a href="/en/User/ForgotPassword">Click
                   here</a></small>-->

               <!--- recuerdame -->
               <!--<div class="form-group b-mar-5">
                 <div class="custom-control custom-checkbox"> <input class="custom-control-input" data-val="true"
                     data-val-required="The Remember Me field is required." id="RememberMe" name="RememberMe"
                     type="checkbox" value="true"><input name="RememberMe" type="hidden" value="false"> <label
                     class="custom-control-label" for="RememberMe">Remember Me</label> </div>
               </div>-->
               <!--- --------------------- -->
               <div class="text-center">
                 <button type="submit"
                   class="btn btn-success d-block m-auto t-mar-20 b-mar-10">
                   Ingresar
                 </button>
               </div>
               <!---------------------------->
               <!-- /.col -->

             </form>
           </div>

           <!-- end of form -->
           <!-- -->
           <!-- mensaje de cargando -->
              <div v-if="loading">
                
                <img v-show="loading" src="~@/assets/img/anim.gif" />Cargango...
              </div>
              <!-- -->
              <NuAlert  ref="nuaRef"/>

          
         </div>
         <!-- formulario --->

       </div>
       <!-- Authentication card end -->

     </div>
     <!-- end of col-sm-12 -->
     <br>
     <br>
   </div>


   
   
  </div>
</template>

<script>
import { ref,reactive,onMounted  } from 'vue'
import { computed }  from 'vue';
import { useStore } from 'vuex'; 
import router from "@/router";
import NuAlert from '@/components/nubox/NuAlert.vue';

import logo1 from "@/assets/img/m_inmuebles.png"

//import {Global} from "../Global"
//import axios from "axios";
//import DivRes from '../components/DivRes.vue';
export default {
  name: "Login",
  components: {
    NuAlert
  },
  setup() {
    const store = useStore(); //para vuex
    const nuaRef = ref(null);//puntero al compoente

    const isModalVisible = ref(false);
    const submited= ref(false);
    const form00 = reactive({
       email:'', 
       password:'' 
    });

    const loading= ref (false);//----ok
    
    //------------------------------------------------------------------------
    onMounted(() => {
        // the DOM element will be assigned to the ref after initial render
        console.log(nuaRef.value) // <div>This is a root element</div>
        //this.form00.email = process.env.VUE_APP_API_URL;
        console.log("VUE_APP_API_URL");
        console.log(process.env.VUE_APP_API_URL);
        //-- cargar datos de la empresa
        
    })
    //------------------------------------------------------------------------
    const loginStatus = computed(() => {
      return store.getters.getLoginStatus;
    });
      
    //------------------------------------------------------------------------
    async function procesar(){
            this.loading= true;//---ok
            //store.dispatch("setPlot", { geometry: newFeature });
            //llamamos a las acciones
           await store.dispatch("auth/login",{email:this.form00.email, 
                                              password:this.form00.password});
            //console.log("-------");
            this.loading = false;
           if(store.getters['auth/getLoginStatus'] === 'success'){
             //alert('login sucess');
             this.showDivRes = false;

             //this.$refs.DivRes.setValue("hola como estas");
             // redireccionamos
             router.push("home");

           }else{
             //alert('failed to login')
             nuaRef.value.error("Falla al hacer login", { title: '' });
           }
    }

    return{
      nuaRef,
      store,
      
      isModalVisible,
      submited,
      form00,
      loading,
      loginStatus,
      procesar,
      logo1,

    }

  
  }
}
</script>
<style scoped>
.fondo1{
  background-image: url("../../assets/img/fondo1.jpg");
}
</style>