import jwtInterceptor  from '../../shared/jwtInterceptor';
import { Commit } from 'vuex';

const state = () => ({
    todos :[]
});

const getters = {
    getAlltodos(state:any){
        return state.todos;
    }
};

const actions = {
    async getAllTodos({commit}: { commit: Commit }){
        var response = await jwtInterceptor.get('http://localhost:3000/todos');
        if(response && response.data){
            commit('setTodos', response.data);
        }
    }
};

const mutations = {
    setTodos(state:any, payload:any){
        state.todos = payload
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}