<template>
    <div>
        <div class="" :class="msg.theme" v-if="visiblex">
            <p v-if="msg.title"><B>{{ msg.title }}</B></p>
            <p class="">{{ msg.message }}</p>
            <a class="noti_close" href="#" @click="close()">x</a>
            <!-- puede tener un boton mas -->
            <div class="d-flex justify-content-between">
                <button class="btn btn-default float-right" v-if="msg.callback!=null"
                        @click="onNextButton()">{{msg.buton_text}}</button>
            </div>
        </div>

        <!--<transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOut" v-if="msg">-->
            
        <!--</transition>-->
    </div>
</template>

<script>
    import { ref, reactive} from "vue";
    //import {  computed } from "vue";
    //import { onMounted } from 'vue'
    export default {
        name: 'NuAlert',
        props: {
        },
        //setup(props, context) {
        setup(context) {
            
            const visiblex = ref(false);
        
            const msg = reactive({
                theme: '',
                title: 'Message',
                message:'',
                position: 'top-right',
                timeout: 8000,
                btn: {
                    ok: ' OK ',
                    cancel: ' Cancel'
                },
                buton_text:'',
                callback: null,
            })
        

            /*const pages_espe = ref([]);
            const page = ref(parseInt(props.current_page));
            const page_total= ref(parseInt(props.last_page));*/
            /*const open = computed(() => {
                return !closed.value;
            });*/

            /*onMounted(()=>{
               
            });*/

            function success(message, option = {}) {
                console.log("success");
                return add(message, Object.assign(option, { type: 'SUCCESS' }));
            }
            function info(message, option = {}) {
                return add(message, Object.assign(option, { type: 'INFO' }));
            }
            function warning(message, option = {}) {
                return add(message, Object.assign(option, { type: 'WARNING' }));
            }
            function error(message, option = {}) {
                return add(message, Object.assign(option, { type: 'ERROR' }));
            }
            function alert(message, option = {}) {
                return add(message, Object.assign(option, { type: 'alert' }));
            }
            function confirm(message, option = {}) {
                return add(message, Object.assign(option, { type: 'confirm' }));
            }
            function add(message, option) {
                
                //console.log("add"+ option.type);
                msg.title =  option.title;
                msg.message =  message;
                msg.buton_text = option.buton_text;
                msg.callback =  option.callback;
                
                console.log(option.type);

                var class_name='';
                switch (option.type)
                {
                    case "ERROR": 
                        class_name = "noti_box noti_error";
                        break;
                    case "SUCCESS": 
                        class_name = "noti_box noti_success";
                        break;
                    case "INFO": 
                        class_name = "noti_box noti_info";
                        break;
                    case "WARNING": 
                        class_name = "noti_box noti_warning";
                        break;
                }
                console.log(option.callback);
                msg.theme = class_name;
                visiblex.value = true;
            }

             //----callback, retorna afuera
            function onNextButton() {
                
                console.log("onNextButton:");
                if (msg.callback) {
                    msg.callback({ data: 'val' });
                }    
                //--de lo contrario emitimos el evento
                context.emit('onNextButton');
            }

            function close() {
                visiblex.value = false;
                msg.message = null;
            }
            

            return {
                visiblex,
                success,
                info,
                warning,
                error,
                alert,
                confirm,
                add,
                msg,
                open,
                close,
                onNextButton,

            }
        } 
    }
</script>

<style scoped>
    /***********************************************************************
    ************************************************************************/
    /********** alerts de validacion ****************/
    .noti_box {
        background-position: 10px 11px;
        background-repeat: no-repeat;
        border: 1px solid;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px rgba(255, 255, 255, 0.4) inset;
        color: #444;
        margin-bottom: 20px;
        padding: 10px 10px 10px 32px;
        position: relative;
    }
    .noti_box > p:first-child {
        padding: 0;
        margin: 0;
    }
    .noti_box p {
        margin:7px;;
    /*    margin-bottom: 0;*/
    /*    margin-top: 20px;*/
    }

    a.noti_close {
        /*background: url("img/tclose.gif") repeat scroll 0 0 rgba(0, 0, 0, 0);*/
        height: 22px;
        position: absolute;
        right: 7px;
        top: 7px;
        width: 22px;
    }
    .noti_info, 
    .noti_success, 
    .noti_warning, 
    .noti_error, 
    .noti_validation {
        border: 1px solid;
        margin: 10px 0px;
        padding:15px 10px 15px 50px;
        background-repeat: no-repeat;
        background-position: 10px center;
    }
    .noti_info {
        color: #00529B;
        background-color: #BDE5F8;
        background-image: url('~@/assets/icon/tinfo.png');
    }
    .noti_success {
        color: #4F8A10;
        background-color: #DFF2BF;
        background-image:url('~@/assets/icon/tok.png');
    }
    .noti_warning {
        color: #9F6000;
        background-color: #FEEFB3;
        background-image: url('~@/assets/icon/tcuidado.png');
    }
    .noti_error {
        color: #D8000C;
        background-color: #FFBABA;
        background-image: url('~@/assets/icon/terror.png');
    }
    .noti_validation {
        color: #D63301;
        background-color: #FFCCBA;
        background-image: url('~@/assets/icon/terror.png');
    }
    /**********************************************************************/
</style>